const actions = {
  GET_ALL_SETTLEMENTS: 'GET_ALL_SETTLEMENTS',
  GET_ALL_SETTLEMENTS_SUCCESS: 'GET_ALL_SETTLEMENTS_SUCCESS',
  GET_ALL_SETTLEMENTS_ERROR: 'GET_ALL_SETTLEMENTS_ERROR',
  GET_SETTLEMENTS_BY_CLAIM_ID: 'GET_SETTLEMENTS_BY_CLAIM_ID',
  GET_SETTLEMENTS_BY_CLAIM_ID_SUCCESS: 'GET_SETTLEMENTS_BY_CLAIM_ID_SUCCESS',
  GET_SETTLEMENTS_BY_CLAIM_ID_ERROR: 'GET_SETTLEMENTS_BY_CLAIM_ID_ERROR',
  UNSELECT_SETTLEMENT: 'UNSELECT_SETTLEMENT',
  CREATE_SETTLEMENT: 'CREATE_SETTLEMENT',
  CREATE_SETTLEMENT_RESULT: 'CREATE_SETTLEMENT_RESULT',
  CREATE_SETTLEMENT_ERROR_RESULT: 'CREATE_SETTLEMENT_ERROR_RESULT',
  DOWNLOAD_SETTLEMENT_PDF_SUMMARY: 'DOWNLOAD_SETTLEMENT_PDF_SUMMARY',
  DOWNLOAD_SETTLEMENT_PDF_SUMMARY_SUCCESS: 'DOWNLOAD_SETTLEMENT_PDF_SUMMARY_SUCCESS',
  DOWNLOAD_SETTLEMENT_PDF_SUMMARY_ERROR: 'DOWNLOAD_SETTLEMENT_PDF_SUMMARY_ERROR',
  UPDATE_SETTLEMENT: 'UPDATE_SETTLEMENT',
  UPDATE_SETTLEMENT_RESULT: 'UPDATE_SETTLEMENT_RESULT',
  UPDATE_SETTLEMENT_ERROR_RESULT: 'UPDATE_SETTLEMENT_ERROR_RESULT',
  UPDATE_SETTLEMENT_STATUS: 'UPDATE_SETTLEMENT_STATUS',
  UPDATE_SETTLEMENT_STATUS_RESULT: 'UPDATE_SETTLEMENT_STATUS_RESULT',
  UPDATE_SETTLEMENT_STATUS_ERROR: 'UPDATE_SETTLEMENT_STATUS_ERROR',
  CLEAR_SETTLEMENT_ERRORS: 'CLEAR_SETTLEMENT_ERRORS',
  LOAD_CUTBACK_REASONS: 'LOAD_CUTBACK_REASONS',
  LOAD_CUTBACK_REASONS_SUCCESS: 'LOAD_CUTBACK_REASONS_SUCCESS',
  LOAD_CUTBACK_REASONS_ERROR: 'LOAD_CUTBACK_REASONS_ERROR',
  CREATE_CUTBACK_REASON: 'CREATE_CUTBACK_REASON',
  CREATE_CUTBACK_REASON_SUCCESS: 'CREATE_CUTBACK_REASON_SUCCESS',
  CREATE_CUTBACK_REASON_ERROR: 'CREATE_CUTBACK_REASON_ERROR',
  RESET_PDF_SETTLEMENT_SUMMARY: 'RESET_PDF_SETTLEMENT_SUMMARY',
  EXPORT_SETTLEMENTS: 'EXPORT_SETTLEMENTS',
  EXPORT_SETTLEMENTS_SUCCESS: 'EXPORT_SETTLEMENTS_SUCCESS',
  EXPORT_SETTLEMENTS_ERROR: 'EXPORT_SETTLEMENTS_ERROR',
  CLEAR_SETTLEMENTS: 'CLEAR_SETTLEMENTS',
  getAllSettlements: (params) => ({
    type: actions.GET_ALL_SETTLEMENTS,
    params
  }),
  getAllSettlementsSuccess: (settlements) => ({
    type: actions.GET_ALL_SETTLEMENTS_SUCCESS,
    settlements
  }),
  getAllSettlementsError: () => ({
    type: actions.GET_ALL_SETTLEMENTS_ERROR
  }),
  getSettlementsByClaimId: (claimId) => ({
    type: actions.GET_SETTLEMENTS_BY_CLAIM_ID,
    claimId
  }),
  getSettlementsByClaimIdSuccess: (settlements) => ({
    type: actions.GET_SETTLEMENTS_BY_CLAIM_ID_SUCCESS,
    settlements
  }),
  getSettlementsByClaimIdError: () => ({
    type: actions.GET_SETTLEMENTS_BY_CLAIM_ID_ERROR
  }),
  updateSettlement: (settlement, callback) => ({
    type: actions.UPDATE_SETTLEMENT,
    settlement,
    callback
  }),
  updateSettlementSuccess: (settlement) => ({
    type: actions.UPDATE_SETTLEMENT_RESULT,
    settlement
  }),
  updateSettlementError: () => ({
    type: actions.UPDATE_SETTLEMENT_ERROR_RESULT
  }),
  createSettlement: (settlement, reloadHistory, callback) => ({
    type: actions.CREATE_SETTLEMENT,
    payload: {
      settlement,
      reloadHistory
    },
    callback
  }),
  createSettlementSuccess: (settlement) => ({
    type: actions.CREATE_SETTLEMENT_RESULT,
    settlement
  }),
  createSettlementError: () => ({
    type: actions.CREATE_SETTLEMENT_ERROR_RESULT
  }),
  clearSettlementErrors: () => ({
    type: actions.CLEAR_SETTLEMENT_ERRORS
  }),
  loadCutbackReasons: () => ({
    type: actions.LOAD_CUTBACK_REASONS
  }),
  loadCutbackReasonsSuccess: (cutbackReasons) => ({
    type: actions.LOAD_CUTBACK_REASONS_SUCCESS,
    cutbackReasons
  }),
  loadCutbackReasonsError: () => ({
    type: actions.LOAD_CUTBACK_REASONS_ERROR
  }),
  loadSettlementPdfSummary: (settlementId, isMessage) => ({
    type: actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY,
    settlementId,
    isMessage
  }),
  loadSettlementPdfSummarySuccess: (pdfSettlementSummary) => ({
    type: actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY_SUCCESS,
    pdfSettlementSummary
  }),
  loadSettlementPdfSummaryError: () => ({
    type: actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY_ERROR
  }),
  createCutbackReason: (cutbackReason) => ({
    type: actions.CREATE_CUTBACK_REASON,
    cutbackReason
  }),
  createCutbackReasonSuccess: () => ({
    type: actions.CREATE_CUTBACK_REASON_SUCCESS
  }),
  createCutbackReasonError: () => ({
    type: actions.CREATE_CUTBACK_REASON_ERROR
  }),
  resetPDFSettlementSummary: () => ({
    type: actions.RESET_PDF_SETTLEMENT_SUMMARY
  }),
  exportSettlements: (params) => ({
    type: actions.EXPORT_SETTLEMENTS,
    params
  }),
  exportSettlementsSuccess: () => ({
    type: actions.EXPORT_SETTLEMENTS_SUCCESS
  }),
  exportSettlementsError: () => ({
    type: actions.EXPORT_SETTLEMENTS_ERROR
  }),
  clearSettlements: () => ({
    type: actions.CLEAR_SETTLEMENTS
  })
};

export default actions;
