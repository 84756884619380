import React from 'react';

const IconReminder = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 17.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14z" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M10 5.5v5H6M13 4.328l1.06-1.06a2.5 2.5 0 1 1 3.536 3.535l-1.06 1.061M3.828 7.864l-1.06-1.06a2.5 2.5 0 1 1 3.535-3.536l1.061 1.06"
        stroke="#111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default IconReminder;
