import { Map } from 'immutable';
import actions from './actions';

const INITIAL_STATE = new Map({
  userCreateLoading: false,
  userCreateError: false,
  users: [],
  usersLoading: false,
  usersError: false,
  userIntercomHmac: '',
  userIntercomHmacLoading: false,
  userIntercomHmacError: false,
  updateUserLoading: false,
  updateUserError: false,
  changeUserPasswordLoading: false,
  changeUserPasswordError: false,
  showChangePasswordModal: false,
  deleteUserLoading: false,
  deleteUserError: false,
  userRoles: [],
  userRolesLoading: false,
  userRolesError: false,
  confirmOptInLoading: false,
  confirmOptInError: false
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CREATE_USER:
      return state.set('userCreateLoading', true).set('userCreateError', false);
    case actions.CREATE_USER_SUCCESS:
      return state.set('userCreateLoading', false).set('userCreateError', false);
    case actions.CREATE_USER_ERROR:
      return state.set('userCreateLoading', false).set('userCreateError', true);
    case actions.LOAD_USERS:
      return state.set('usersLoading', true);
    case actions.LOAD_USERS_SUCCESS:
      return state.set('users', action.users).set('usersLoading', false);
    case actions.LOAD_USERS_ERROR:
      return state.set('usersLoading', false).set('usersError', true);
    case actions.LOAD_USERS_BY_ORGANIZATION:
      return state.set('usersLoading', true);
    case actions.LOAD_USERS_BY_ORGANIZATION_SUCCESS:
      return state.set('users', action.users).set('usersLoading', false);
    case actions.LOAD_USERS_BY_ORGANIZATION_ERROR:
      return state.set('usersLoading', false).set('usersError', true);
    case actions.LOAD_USER_INTERCOM_HMAC:
      return state.set('userIntercomHmacLoading', true);
    case actions.LOAD_USER_INTERCOM_HMAC_SUCCESS:
      return state.set('userIntercomHmac', action.userIntercomHmac).set('userIntercomHmacLoading', false);
    case actions.LOAD_USER_INTERCOM_HMAC_ERROR:
      return state.set('userIntercomHmacLoading', false).set('userIntercomHmacError', true);
    case actions.UPDATE_USER:
      return state.set('updateUserLoading', true).set('updateUserError', false);
    case actions.UPDATE_USER_SUCCESS:
      return state.set('updateUserLoading', false).set('updateUserError', false);
    case actions.UPDATE_USER_ERROR:
      return state.set('updateUserLoading', false).set('updateUserError', true);
    case actions.CHANGE_USER_PASSWORD:
      return state.set('changeUserPasswordLoading', true).set('changeUserPasswordError', false);
    case actions.CHANGE_USER_PASSWORD_SUCCESS:
      return state
        .set('changeUserPasswordLoading', false)
        .set('changeUserPasswordError', false)
        .set('showChangePasswordModal', false);
    case actions.CHANGE_USER_PASSWORD_ERROR:
      return state.set('changeUserPasswordLoading', false).set('changeUserPasswordError', true);
    case actions.DELETE_USER:
      return state.set('deleteUserLoading', true).set('deleteUserError', false);
    case actions.DELETE_USER_SUCCESS:
      return state.set('deleteUserLoading', false).set('deleteUserError', false);
    case actions.DELETE_USER_ERROR:
      return state.set('deleteUserLoading', false).set('deleteUserError', true);
    case actions.GET_USER_ROLES:
      return state.set('userRolesLoading', true).set('userRolesError', false);
    case actions.GET_USER_ROLES_SUCCESS:
      return state.set('userRolesLoading', false).set('userRolesError', false).set('userRoles', action.userRoles);
    case actions.GET_USER_ROLES_ERROR:
      return state.set('userRolesLoading', false).set('userRolesError', true);
    case actions.CLEAR_USER_ERRORS:
      return state.set('changeUserPasswordError', false).set('userCreateError', false).set('updateUserError', false);
    case actions.CONFIRM_OPT_IN:
      return state.set('confirmOptInLoading', true).set('confirmOptInError', false);
    case actions.CONFIRM_OPT_IN_SUCCESS:
      return state.set('confirmOptInLoading', false).set('confirmOptInError', false);
    case actions.CONFIRM_OPT_IN_ERROR:
      return state.set('confirmOptInLoading', false).set('confirmOptInError', true);
    case actions.TOGGLE_USER_CHANGE_PASSWORD_MODAL:
      return state.set('showChangePasswordModal', !state.get('showChangePasswordModal'));
    default:
      return state;
  }
};

export default reducer;
