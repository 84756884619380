import React from 'react';

const EyecandyTechnicalProblem = () => {
  return (
    <svg width="71" height="60" viewBox="0 0 71 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.03 26.47a.75.75 0 1 0-1.06 1.06l.97.97-.97.97a.75.75 0 1 0 1.06 1.06l.97-.97.97.97a.75.75 0 1 0 1.06-1.06l-.97-.97.97-.97a.75.75 0 1 0-1.06-1.06l-.97.97-.97-.97zM39.03 26.47a.75.75 0 1 0-1.06 1.06l.97.97-.97.97a.75.75 0 1 0 1.06 1.06l.97-.97.97.97a.75.75 0 1 0 1.06-1.06l-.97-.97.97-.97a.75.75 0 1 0-1.06-1.06l-.97.97-.97-.97zM31.047 35.513c1.08-1.153 2.741-1.763 4.453-1.763 1.712 0 3.372.61 4.453 1.763a.75.75 0 0 0 1.094-1.026c-1.42-1.514-3.509-2.237-5.547-2.237s-4.128.723-5.547 2.237a.75.75 0 0 0 1.094 1.026z"
        fill="#B3B3B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.75 29c0-6.49 5.26-11.75 11.75-11.75S47.25 22.51 47.25 29 41.99 40.75 35.5 40.75 23.75 35.49 23.75 29zM35.5 18.75c-5.66 0-10.25 4.59-10.25 10.25s4.59 10.25 10.25 10.25S45.75 34.66 45.75 29 41.16 18.75 35.5 18.75z"
        fill="#B3B3B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.95 44.25c.192-.375.3-.8.3-1.25V13a2.75 2.75 0 0 0-2.75-2.75h-46A2.75 2.75 0 0 0 9.75 13v30c0 .45.108.875.3 1.25h-6.3V46a3.75 3.75 0 0 0 3.75 3.75h56A3.75 3.75 0 0 0 67.25 46v-1.75h-6.3zm-28.175-32.5H12.5c-.69 0-1.25.56-1.25 1.25v30c0 .69.56 1.25 1.25 1.25h46c.69 0 1.25-.56 1.25-1.25V13c0-.69-.56-1.25-1.25-1.25H38.225c-.116.57-.62 1-1.225 1h-3a1.25 1.25 0 0 1-1.225-1zm-27.525 34h60.5V46a2.25 2.25 0 0 1-2.25 2.25h-56A2.25 2.25 0 0 1 5.25 46v-.25z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default EyecandyTechnicalProblem;
