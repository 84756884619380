import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadAuthorizedOrganisations = async (params) =>
  axios
    .get(`${apiUrls.policyAuthorizationsBaseUrl}`, { params: { ...params, paging: false } })
    .then((res) => res)
    .catch((error) => error);

const onAddAuthorizedOrganisation = async (authorization) =>
  axios
    .post(apiUrls.policyAuthorizationsBaseUrl, authorization)
    .then((res) => res)
    .catch((error) => error);

const onDeleteAuthorizedOrganisation = async (organizationsToDelete) =>
  axios
    .post(`${apiUrls.policyAuthorizationsBaseUrl}/delete-many`, organizationsToDelete)
    .then((res) => res)
    .catch((error) => error);

export { onLoadAuthorizedOrganisations, onAddAuthorizedOrganisation, onDeleteAuthorizedOrganisation };
