import styled from 'styled-components';
import { windowMaxWidth } from '../../Layout/breakpoints';

const UploaderWrapper = styled.div`
  margin-bottom: 10px;
`;

const UploaderHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-3);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-medium);
  margin-right: 8px;
`;

const UploaderTitle = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-4);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-regular);
  margin-right: 8px;
`;

const Description = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 10px;
`;

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;

  @media (${windowMaxWidth.mobile}) {
    flex: auto;
    justify-content: space-between;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;

  @media (${windowMaxWidth.mobile}) {
    display: none;
  }
`;

const ButtonWrapperMobile = styled.div`
  display: none;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;

  @media (${windowMaxWidth.mobile}) {
    display: flex;
  }
`;

export const UploaderLayout = {
  UploaderWrapper,
  UploaderHeader,
  Title,
  UploaderTitle,
  Description,
  FlexWrapper,
  ButtonWrapper,
  ButtonWrapperMobile
};
