import { all, takeEvery, takeLatest, put, fork, call, select } from 'redux-saga/effects';
import userActions from '../users/actions';
import organizationsActions from '../organizations/actions';
import insuredRisksActions from '../insuredRisks/actions';
import fileContainersActions from '../fileContainers/actions';
import stripeActions from '../stripe/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import actions from './actions';
import {
  onLoginReqeust,
  onLogoutRequest,
  onValidateUser,
  onResetPassword,
  onForgotPassword,
  onLoadUserRights
} from './apiCalls';

export function* validateUser() {
  try {
    const validationResult = yield call(onValidateUser);
    if (validationResult.status === 200) {
      const userData = validationResult?.data;
      yield all([put(actions.validateUserSuccess(validationResult.data)), put(actions.getUserRights(userData.id))]);
    } else {
      yield put(actions.validateUserError());
    }
  } catch (error) {
    yield put(actions.validateUserError());
  }
}

export function* loginRequest({ payload, callback }) {
  const { username, password } = payload;
  try {
    const loginResult = yield call(onLoginReqeust, username, password);
    if (loginResult.status === 200) {
      const userData = loginResult?.data;

      yield all([put(actions.loginSuccess(loginResult.data)), put(actions.getUserRights(userData.id))]);
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.loginError());
    }
  } catch (error) {
    yield put(actions.loginError());
  }
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* wrapper() {
    const logoutResult = yield call(onLogoutRequest);
    if (logoutResult) {
      yield put({ type: actions.LOGOUT_SUCCESS });
    }
  });
}

export function* userRightsRequest({ userId }) {
  try {
    const userRightsResult = yield call(onLoadUserRights, userId);
    if (userRightsResult) {
      yield put(actions.getUserRightsSuccess(userRightsResult.data));
    } else {
      yield put(actions.getUserRightsError());
    }
  } catch (error) {
    yield put(actions.getUserRightsError());
  }
}

export function* resetPassword({ token, newPassword, navigate }) {
  try {
    const resetPasswordResult = yield call(onResetPassword, token, newPassword);
    if (resetPasswordResult) {
      const { password_reset_success } = NOTIFICATION_MESSAGES;
      yield put(actions.resetPasswordSuccess());
      yield call(navigate, '/');
      renderNotification({ type: 'success', message: password_reset_success });
    } else {
      yield put(actions.resetPasswordError(resetPasswordResult));
    }
  } catch (error) {
    yield put(actions.resetPasswordError(error));
  }
}

export function* forgotPassword({ email, callback }) {
  try {
    const forgotPasswordResult = yield call(onForgotPassword, email);
    if (forgotPasswordResult.status === 200) {
      const { password_forgot_success } = NOTIFICATION_MESSAGES;
      yield put(actions.forgotPasswordSuccess());
      callback();
      renderNotification({ type: 'success', message: password_forgot_success, duration: null });
    } else {
      yield put(actions.forgotPasswordError());
    }
  } catch (error) {
    yield put(actions.forgotPasswordError());
  }
}

export function* signIn({ payload, navigate }) {
  const { token, username, password } = payload;

  try {
    const resetPasswordResult = yield call(onResetPassword, token, password);
    if (resetPasswordResult.status === 200) {
      const loginResult = yield call(onLoginReqeust, username, password);

      if (loginResult.status === 200) {
        const userData = loginResult?.data;

        yield all([
          put(actions.signInSuccess()),
          put(userActions.confirmOptIn(userData.id)),
          put(actions.getUserRights(userData.id)),
          call(navigate, '/')
        ]);
      } else {
        yield put(actions.signInError());
      }
    } else {
      yield put(actions.signInError());
    }
  } catch (error) {
    yield put(actions.signInError(error));
  }
}

const getUserRights = (state) => state.Auth.get('userRights');

/* 
This saga function should load all environment specific data (like organizations, insured risks or housing stocks) 
that is used in different tabs and that never or seldom changes within an instance.
*/
export function* loadEnvironmentSpecificData() {
  const userRights = yield select(getUserRights);
  const { stripe_customer_portal_ui } = userRights;

  /* 
  The getAllOrganizations call returns an array of all existing organizations.
  If the user does't have the rights to see all organizations then the call returns only his organization. 
  */
  yield all([
    put(organizationsActions.getAllOrganizations()),
    put(insuredRisksActions.getInsuredRisks()),
    put(fileContainersActions.getAllFileContainers())
  ]);

  // If the user has the right to see the customer portal then we check the customer portal availability
  if (stripe_customer_portal_ui) {
    yield put(stripeActions.getCustomerPortalAvailability());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, loginRequest),
    takeLatest(actions.VALIDATE_USER, validateUser),
    takeLatest(actions.RESET_PASSWORD, resetPassword),
    takeLatest(actions.FORGOT_PASSWORD, forgotPassword),
    takeLatest(actions.LOAD_USER_UI_RIGHTS, userRightsRequest),
    takeEvery(actions.LOAD_USER_UI_RIGHTS_SUCCESS, loadEnvironmentSpecificData),
    takeLatest(actions.SIGNIN, signIn),
    fork(logout)
  ]);
}
