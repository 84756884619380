import React from 'react';

const IconSearch = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="6.5" stroke="#111" strokeWidth="1.5" />
    <path d="m13 13 6 6" stroke="#111" strokeWidth="1.5" />
  </svg>
);

export default IconSearch;
