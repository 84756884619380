import { Map } from 'immutable';
import deepEqual from 'fast-deep-equal';
import actions from './actions';

const INITIAL_STATE = new Map({
  isLoggedIn: false,
  user: null,
  loginLoading: false,
  loginError: false,
  validateUserError: false,
  validateUserLoading: false,
  userRights: {},
  userRightsLoading: false,
  userRightsError: false,
  resetPasswordLoading: false,
  resetPasswordError: false,
  forgotPasswordLoading: false,
  forgotPasswordError: false,
  signInLoading: false,
  signInError: false
});

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.VALIDATE_USER:
      return state.set('validateUserLoading', true).set('validateUserError', false);
    case actions.VALIDATE_USER_SUCCESS:
      return state.set('user', action.payload).set('isLoggedIn', true).set('validateUserLoading', false);
    case actions.VALIDATE_USER_ERROR:
      return state
        .set('isLoggedIn', false)
        .set('user', null)
        .set('validateUserError', true)
        .set('validateUserLoading', false);
    case actions.LOGIN:
      return state.set('loginLoading', true).set('loginError', false);
    case actions.LOGIN_SUCCESS:
      if (!deepEqual(action.payload, state.get('user'))) {
        return state.set('user', action.payload).set('isLoggedIn', true).set('loginLoading', false);
      }
      return state.set('isLoggedIn', true).set('loginLoading', false);
    case actions.LOGIN_ERROR:
      return state.set('user', null).set('isLoggedIn', false).set('loginLoading', false).set('loginError', true);
    case actions.LOGOUT_SUCCESS:
      return state.set('user', null).set('isLoggedIn', false).set('userRights', {});
    case actions.RESET_PASSWORD:
      return state.set('resetPasswordLoading', true).set('resetPasswordError', false);
    case actions.RESET_PASSWORD_SUCCESS:
      return state.set('resetPasswordLoading', false).set('resetPasswordError', false);
    case actions.RESET_PASSWORD_ERROR:
      return state.set('resetPasswordLoading', false).set('resetPasswordError', true);
    case actions.FORGOT_PASSWORD:
      return state.set('forgotPasswordLoading', true).set('forgotPasswordError', false);
    case actions.FORGOT_PASSWORD_SUCCESS:
      return state.set('forgotPasswordLoading', false).set('forgotPasswordError', false);
    case actions.FORGOT_PASSWORD_ERROR:
      return state.set('forgotPasswordLoading', false).set('forgotPasswordError', true);
    case actions.LOAD_USER_UI_RIGHTS:
      return state.set('userRightsLoading', true).set('userRightsError', false);
    case actions.LOAD_USER_UI_RIGHTS_SUCCESS:
      return state.set('userRightsLoading', false).set('userRights', action.payload);
    case actions.LOAD_USER_UI_RIGHTS_ERROR:
      return state.set('userRightsLoading', false).set('userRightsError', true);
    case actions.SIGNIN:
      return state.set('signInLoading', true).set('signInError', false);
    case actions.SIGNIN_SUCCESS:
      return state.set('signInLoading', false).set('signInError', false);
    case actions.SIGNIN_ERROR:
      return state.set('signInLoading', false).set('signInError', true);
    default:
      return state;
  }
};

export default authReducer;
