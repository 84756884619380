const CLAIM_STATUSES_DICTIONARY = {
  open: 'Offen',
  closed: 'Geschlossen',
  rejected: 'Abgelehnt / Storniert',
  denied: 'Abgelehnt'
};

const CLAIM_VALIDATION_TOOLTIP = {
  risk: 'Dieser Schadenort entspricht einem Risiko im System.',
  insuredRisk: 'Diese Gefahr ist versichert.',
  insurancePolicyNumber: 'Diese Versicherungsnummer entspricht einem Vertrag im System.'
};

const INSURED_RISK_TYPES_DICTIONARY = {
  building: 'Gebäude',
  glass: 'Schaufenster',
  liability: 'Haftpflicht',
  construction: 'Bauleistung'
};

const CHARTS_DICTIONARY = {
  claims_occured: {
    label: 'Schadenaufkommen',
    color: 'blue'
  },
  claims_completed: {
    label: 'Schäden geschlossen',
    color: 'green'
  },
  claims_open: {
    label: 'Offen',
    color: 'blue'
  },
  claims_closed: {
    label: 'Geschlossen',
    color: 'green'
  },
  claims_rejected: {
    label: 'Abgelehnt',
    color: 'red'
  },
  claims_open_volume: {
    label: 'Offen',
    color: 'blue'
  },
  claims_closed_volume: {
    label: 'Geschlossen',
    color: 'green'
  },
  claims_rejected_volume: {
    label: 'Abgelehnt',
    color: 'red'
  },
  billed_amount: {
    label: 'Rechnungen'
  },
  billed_amounts: {
    label: 'Rechnungen',
    color: 'blue'
  },
  settled_amount: {
    label: 'Regulierungen'
  },
  settled_amounts: {
    label: 'Regulierungen',
    color: 'green'
  },
  estimated_costs: {
    label: 'Schätzkosten',
    color: 'pink'
  },
  occured: {
    label: 'Rechnungen erstellt',
    color: 'blue'
  },
  completed: {
    label: 'Rechnungen geschlossen',
    color: 'green'
  },
  settlements_occured: {
    label: 'Rechnungen erstellt'
  },
  settlements_completed: {
    label: 'Rechnungen geschlossen'
  },
  settlements_open: {
    label: 'Offen',
    color: 'blue'
  },
  settlements_closed: {
    label: 'Geschlossen',
    color: 'green'
  },
  settlements_rejected: {
    label: 'Abgelehnt',
    color: 'red'
  },
  settlements_open_volume: {
    label: 'Offen',
    color: 'blue'
  },
  settlements_closed_volume: {
    label: 'Geschlossen',
    color: 'green'
  },
  settlements_rejected_volume: {
    label: 'Abgelehnt',
    color: 'red'
  },
  premiumValue: {
    label: 'Prämie',
    color: 'red'
  },
  premium: {
    label: 'Prämie',
    color: 'red'
  },
  claims_forecast_volume: {
    label: 'Prognose',
    color: 'gray'
  },
  profitability: {
    label: 'Rentabilität',
    color: 'blue'
  },
  profitability_forecast: {
    label: 'Prognose',
    color: 'gray'
  },
  forecast: {
    label: 'Prognose'
  },
  claims_average_volume: {
    label: 'Durchschnittliche Kosten',
    color: 'blue'
  },
  closed_claims: {
    label: 'Geschlossen'
  },
  closed_settlements: {
    label: 'Geschlossen'
  },
  open_claims: {
    label: 'Offen'
  },
  open_settlements: {
    label: 'Offen'
  },
  rejected_claims: {
    label: 'Abgelehnt / Storniert'
  },
  rejected_settlements: {
    label: 'Abgelehnt / Storniert'
  },
  claimsVolume: {
    label: 'Schadenvolumen'
  },
  open: {
    label: 'Offen'
  },
  closed: {
    label: 'Geschlossen'
  },
  rejected: {
    label: 'Abgelehnt / Storniert'
  },
  denied: {
    label: 'Abgelehnt'
  }
};

const ENTITY_TYPES_DICTIONARY = {
  risk: 'Risiko',
  risks: 'Risiken',
  policy: 'Vertrag',
  policies: 'Verträge',
  claim: 'Schaden',
  claims: 'Schäden',
  settlement: 'Rechnung',
  settlements: 'Rechnungen'
};

const USER_ROLES_DICTIONARY = {
  admin: 'Admin',
  clerk: 'Sachbearbeiter',
  manager: 'Manager'
};
const SETTLEMENTS_DICTIONARY = {
  quotation: {
    type: 'quotation',
    totalAmount: 'Kostenvoranschlag',
    approvalAmount: 'Freigabe'
  },
  invoice: {
    type: 'invoice',
    totalAmount: 'Rechnung',
    approvalAmount: 'Regulierung'
  }
};

export {
  CLAIM_STATUSES_DICTIONARY,
  CLAIM_VALIDATION_TOOLTIP,
  INSURED_RISK_TYPES_DICTIONARY,
  CHARTS_DICTIONARY,
  ENTITY_TYPES_DICTIONARY,
  USER_ROLES_DICTIONARY,
  SETTLEMENTS_DICTIONARY
};
