import React from 'react';

const IconChevronLeft = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m13 4-6 6 6 6" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconChevronLeft;
