import React from 'react';
import styled from 'styled-components';

const StyledHeading1 = styled.h1`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-1);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letter-spacing-small);
  line-height: var(--font-line-height-extra-large);
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 940px;
  word-break: break-word;
`;

const StyledHeading2 = styled.h2`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-2);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letter-spacing-small);
  line-height: var(--font-line-height-large);
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 860px;
  word-break: break-word;
`;

const StyledHeading3 = styled.h3`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-3);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letter-spacing-regular);
  line-height: var(--font-line-height-medium);
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 600px;
  word-break: break-word;
`;

const StyledHeading4 = styled.h4`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-4);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letter-spacing-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 600px;
  word-break: break-word;
`;

const StyledHeading5 = styled.h5`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-heading-5);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--font-letter-spacing-regular);
  line-height: var(--font-line-height-small);
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 600px;
  word-break: break-word;
`;

export const Heading1 = (props) => <StyledHeading1 {...props} />;
export const Heading2 = (props) => <StyledHeading2 {...props} />;
export const Heading3 = (props) => <StyledHeading3 {...props} />;
export const Heading4 = (props) => <StyledHeading4 {...props} />;
export const Heading5 = (props) => <StyledHeading5 {...props} />;
