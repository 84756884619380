import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import queryString from 'query-string';
import policiesActions from '../policies/actions';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import renderNotification from '../../helpers/notifications/renderNotification';
import { onAddAuthorizedOrganisation, onDeleteAuthorizedOrganisation, onLoadAuthorizedOrganisations } from './apiCalls';
import actions from './actions';

export function* addAuthorisedOrganisation({ authorizedOrganisation }) {
  try {
    const response = yield call(onAddAuthorizedOrganisation, authorizedOrganisation);

    if (response.status === 200) {
      yield put(actions.addAuthorizedOrganisationSuccess(response.data));
    } else {
      yield put(actions.addAuthorizedOrganisationError(response.data));
    }
  } catch (error) {
    yield put(actions.addAuthorizedOrganisationError(error));
  }
}

export function* bulkAddAuthorizedOrganisations({ organisations, policies }) {
  try {
    if (!organisations.length || !policies.length) {
      yield put(actions.bulkAddAuthorizedOrganisationsSuccess([]));
      return;
    }
    const params = { organization_id: organisations };
    const response = yield call(onLoadAuthorizedOrganisations, params);

    if (response.data) {
      const existingAuthorizations = response.data;
      const generatedPairs = organisations.map((organization_id) =>
        policies.map((policy) => ({
          organization_id,
          policy_id: policy.id
        }))
      );
      const selectedPoliciesIds = policies.map((policy) => policy.id);
      const filteredAuthorizations = existingAuthorizations.filter((authorizationItem) =>
        selectedPoliciesIds.includes(authorizationItem.policy_id)
      );
      const authorizationsToCreate = generatedPairs.flat(Infinity).filter((generatedPair) => {
        const foundAuhtorization = filteredAuthorizations.find(
          (dataItem) =>
            dataItem.policy_id === generatedPair.policy_id && dataItem.organization_id === generatedPair.organization_id
        );

        return !foundAuhtorization;
      });

      try {
        const response = yield call(onAddAuthorizedOrganisation, authorizationsToCreate);

        if (response.status === 200) {
          yield put(actions.bulkAddAuthorizedOrganisationsSuccess(response.data));
          const { policies_bulk_actions_add_organizations_success } = NOTIFICATION_MESSAGES;
          renderNotification({ type: 'success', message: policies_bulk_actions_add_organizations_success });
        } else {
          yield put(actions.bulkAddAuthorizedOrganisationsError());
        }
      } catch {
        yield put(actions.bulkAddAuthorizedOrganisationsError());
      }
    } else {
      yield put(actions.bulkAddAuthorizedOrganisationsError());
    }
  } catch {
    yield put(actions.bulkAddAuthorizedOrganisationsError());
  }
}

export function* bulkRemoveAuthorizedOrganisations({ organisations, policies }) {
  try {
    if (!organisations.length || !policies.length) {
      yield put(actions.bulkRemoveAuthorizedOrganisationsSuccess());
      return;
    }
    const params = { organization_id: organisations };
    const response = yield call(onLoadAuthorizedOrganisations, params);
    if (response.data) {
      const existingAuthorizations = response.data;
      const selectedPoliciesIds = policies.map((policy) => policy.id);
      const filteredAuthorizations = existingAuthorizations.filter((authorizationItem) =>
        selectedPoliciesIds.includes(authorizationItem.policy_id)
      );
      const filteredAuthorizationsIds = filteredAuthorizations.map((auth) => auth.id);

      try {
        const response = yield call(onDeleteAuthorizedOrganisation, filteredAuthorizationsIds);
        if (response.status === 200) {
          yield put(actions.bulkRemoveAuthorizedOrganisationsSuccess());
          const { policies_bulk_actions_remove_organizations_success } = NOTIFICATION_MESSAGES;
          renderNotification({ type: 'success', message: policies_bulk_actions_remove_organizations_success });
        } else {
          yield put(actions.bulkRemoveAuthorizedOrganisationsError());
        }
      } catch {
        yield put(actions.bulkRemoveAuthorizedOrganisationsError());
      }
    } else {
      yield put(actions.bulkRemoveAuthorizedOrganisationsError());
    }
  } catch {
    yield put(actions.bulkRemoveAuthorizedOrganisationsError());
  }
}

export function* loadAuthorizedOrganisations({ params }) {
  try {
    const response = yield call(onLoadAuthorizedOrganisations, params);
    if (response.data) {
      yield put(actions.loadAuthorizedOrganisationsSuccess(response.data));
    } else {
      yield put(actions.loadAuthorizedOrganisationsError());
    }
  } catch (error) {
    yield put(actions.loadAuthorizedOrganisationsError(error));
  }
}

export function* addMultipleAuthorisedOrganisations({ authorizedOrganisations, policyId, params }) {
  try {
    const preparedEffects = authorizedOrganisations.map((organization_id) => {
      const payload = {
        organization_id,
        policy_id: policyId
      };

      return onAddAuthorizedOrganisation(payload, params);
    });
    const [...responses] = yield all([...preparedEffects]);
    const filteredParsedResponses = responses && responses.filter((response) => response.status !== '200');

    if (filteredParsedResponses.length > 0) {
      yield put(actions.addAuthorizedOrganisationSuccess());
      yield put(policiesActions.getPolicyAuth(policyId));
      yield put(policiesActions.loadPolicies(params));
    } else {
      yield put(actions.addAuthorizedOrganisationError());
    }
  } catch (error) {
    yield put(actions.addAuthorizedOrganisationError());
  }
}

export function* deleteAuthorizedOrganisation({ authorizationIds, policyId, params }) {
  try {
    const response = yield call(onDeleteAuthorizedOrganisation, authorizationIds);
    if (response.status === 200) {
      yield put(actions.deleteAuthorizedOrganisationSuccess());
      yield put(policiesActions.getPolicyAuth(policyId));
      yield put(policiesActions.loadPolicies(params));
    } else {
      yield put(actions.deleteAuthorizedOrganisationError());
    }
  } catch (error) {
    yield put(actions.deleteAuthorizedOrganisationError(error));
  }
}

export function* deleteMultipleAuthorisedOrganisations({ authorizationIds, policyId, params }) {
  try {
    const preparedEffects = authorizationIds.map((authorizationId) =>
      onDeleteAuthorizedOrganisation(authorizationId, params)
    );
    const [...responses] = yield all([...preparedEffects]);
    const filteredParsedResponses = responses && responses.filter((response) => response.status !== '200');

    if (filteredParsedResponses.length > 0) {
      yield put(actions.deleteAuthorizedOrganisationSuccess());
      yield put(policiesActions.getPolicyAuth(policyId));
      yield put(policiesActions.getAllPolicies(params));
    } else {
      yield put(actions.deleteAuthorizedOrganisationError());
    }
  } catch (error) {
    yield put(actions.deleteAuthorizedOrganisationError());
  }
}

export function* updateAuthorizedOrganisation({ params }) {
  try {
    const { authorizationsToAdd, authorizationsToDelete } = params;
    const responseDelete = yield call(onDeleteAuthorizedOrganisation, authorizationsToDelete);
    const responseAdd = yield call(onAddAuthorizedOrganisation, authorizationsToAdd);
    const isSuccess = responseDelete.status === 200 && responseAdd.status === 200;
    if (isSuccess) {
      const policy = yield select(getSinglePolicy);
      yield put(actions.updateAuthorizedOrganisationSuccess());
      yield put(policiesActions.getPolicy(policy.id));
    } else {
      yield put(actions.updateAuthorizedOrganisationError());
    }
  } catch (error) {
    yield put(actions.updateAuthorizedOrganisationError(error));
  }
}

const getPoliciesActiveFilters = (state) => state.Filters.get('policiesActiveFilters');
const getSinglePolicy = (state) => state.Policies.get('policy');

export function* reloadPolicies() {
  const activeFilters = yield select(getPoliciesActiveFilters);
  const parsedActiveFilters = queryString.parse(activeFilters);
  yield all([
    put(policiesActions.loadPolicies(parsedActiveFilters)),
    // The filter options should be fetched on every policies reload,
    // as creating or editing policies might affect the filter options.
    put(policiesActions.getPoliciesInsuredRisks()),
    put(policiesActions.getPoliciesFiltersOrganizationsWithAccess()),
    put(policiesActions.getPoliciesInsuredRiskTypes()),
    put(policiesActions.getCustomValues())
  ]);
}

export function* reloadAuthorizedOrganizations() {
  const policy = yield select(getSinglePolicy);
  const { id } = policy;
  yield put(policiesActions.getPolicyOrganizationsWithAccess(id));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.BULK_ADD_AUTHORIZED_ORGANISATIONS, bulkAddAuthorizedOrganisations),
    takeLatest(actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS, bulkRemoveAuthorizedOrganisations),
    takeLatest(actions.LOAD_AUTHORIZED_ORGANISATIONS, loadAuthorizedOrganisations),
    takeLatest(actions.ADD_AUTHORIZED_ORGANISATION, addAuthorisedOrganisation),
    takeLatest(actions.DELETE_AUTHORIZED_ORGANISATION, deleteAuthorizedOrganisation),
    takeLatest(actions.ADD_AUTHORIZED_ORGANISATIONS, addMultipleAuthorisedOrganisations),
    takeLatest(actions.DELETE_AUTHORIZED_ORGANISATIONS, deleteMultipleAuthorisedOrganisations),
    takeLatest(actions.UPDATE_AUTHORIZED_ORGANISATION, updateAuthorizedOrganisation),
    takeLatest(actions.UPDATE_AUTHORIZED_ORGANISATION_SUCCESS, reloadAuthorizedOrganizations),
    takeLatest(actions.BULK_ADD_AUTHORIZED_ORGANISATIONS_SUCCESS, reloadPolicies),
    takeLatest(actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS_SUCCESS, reloadPolicies)
  ]);
}
