import styled from 'styled-components';
import { Heading2, Heading4 } from '../../Heading/Heading';
import { windowMaxWidth } from '../../Layout/breakpoints';

// Styles for the WorklistClaims component

const WorklistClaimsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const WorklistClaimsReminderTitle = styled(Heading4)`
  margin-left: 10px;
  margin-top: 0;
`;

const WorklistClaimReminderMessageWrapper = styled.div`
  position: relative;
`;

const WorklistClaimsReminderMessageCounter = styled.div`
  background-color: var(--greyscale-concrete);
  border-radius: var(--border-radius-medium);
  color: var(--greyscale-white);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-small);
  padding: 2px 10px;
  position: absolute;
  right: 10px;
  top: 100px;
`;

const WorklistClaimsReminderFooterWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

// Styles for the WorklistClaimsHeader component

const WorklistClaimsHeaderWrapper = styled.div`
  background-color: var(--greyscale-white);
  border-bottom: 1px solid var(--greyscale-dark-white);
  display: flex;
  flex-direction: column;
  height: 101px;
  padding: 20px;

  &.no-border {
    border-bottom: none;
    height: 99px;
  }
`;

const WorklistClaimsHeaderButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const WorklistClaimsHeaderLeftButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const WorklistClaimsHeaderRightButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const WorklistClaimsHeaderTitleSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  svg {
    margin-right: 5px;
  }
`;

const WorklistClaimsHeaderTitle = styled(Heading2)`
  flex: 0 1 auto;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const WorklistClaimsHeaderUnreadCounterBubble = styled.span`
  align-items: center;
  background-color: var(--primary-oqio-blue);
  border-radius: var(--border-radius-medium);
  color: var(--greyscale-white);
  display: flex;
  flex: 0 1 20px;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  height: 20px;
  justify-content: center;
  line-height: var(--font-line-height-small);
  margin-left: 5px;
  padding: 2px 6px;
`;

const WorklistClaimsHeaderWarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WorklistClaimsHeaderWarningContentWrapper = styled.div`
  align-items: center;
  background-color: var(--primary-oqio-red);
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: flex-start;
  padding: 10px 30px;
  transition: background-color 0.1s;

  &:hover {
    background-color: var(--primary-oqio-red-hover);
    cursor: pointer;
  }

  @media (${windowMaxWidth.mobile}) {
    height: 52px;
  }
`;

const WorklistClaimsHeaderWarningMessage = styled.span`
  color: var(--greyscale-white);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-small);
  padding-left: 10px;
`;

const WorklistClaimsHeaderWarningIcon = styled.span`
  align-items: center;
  display: flex;

  svg {
    path:not(.dot) {
      stroke: var(--greyscale-white);
    }

    path.dot {
      fill: var(--greyscale-white);
    }
  }
`;

// Styles for the WorklistClaimMessageReminder component

const WorklistClaimMessageReminderModalContentWrapper = styled.div`
  padding: 20px;
`;

// Styles for the WorklistClaimsList component

const WorklistListWrapper = styled.div`
  height: calc(100% - 100px);
  margin: 0;
  overflow: auto;
  position: relative;
  scrollbar-color: rgb(136 136 136 / 30%) transparent;
  scrollbar-width: thin;
`;

const WorklistListOverlay = styled.div`
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, var(--greyscale-white) 100%);
  border-radius: var(--border-radius-regular);
  bottom: 0;
  display: flex;
  height: 40px;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

const WorklistListList = styled.ul`
  margin: 0;
`;

const WorklistClaimsListEmptyStateWrapper = styled.div`
  align-items: center;
  background-color: var(--secondary-whisper);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const WorklistClaimsListEmptyStateContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

const WorklistClaimsListEmptyStateHeader = styled(Heading4)``;

const WorklistClaimsListEmptyStateMessage = styled.span`
  font-size: var(--font-size-regular);
`;

const WorklistClaimsLayout = {
  WorklistClaimsWrapper,
  WorklistClaimsReminderTitle,
  WorklistClaimReminderMessageWrapper,
  WorklistClaimsReminderMessageCounter,
  WorklistClaimsReminderFooterWrapper,
  WorklistClaimsHeaderWrapper,
  WorklistClaimsHeaderButtonsSection,
  WorklistClaimsHeaderLeftButtonWrapper,
  WorklistClaimsHeaderRightButtonWrapper,
  WorklistClaimsHeaderTitleSection,
  WorklistClaimsHeaderTitle,
  WorklistClaimsHeaderUnreadCounterBubble,
  WorklistClaimMessageReminderModalContentWrapper,
  WorklistListWrapper,
  WorklistListOverlay,
  WorklistListList,
  WorklistClaimsListEmptyStateWrapper,
  WorklistClaimsListEmptyStateContentWrapper,
  WorklistClaimsListEmptyStateHeader,
  WorklistClaimsListEmptyStateMessage,
  WorklistClaimsHeaderWarningWrapper,
  WorklistClaimsHeaderWarningContentWrapper,
  WorklistClaimsHeaderWarningMessage,
  WorklistClaimsHeaderWarningIcon
};

export default WorklistClaimsLayout;
