import * as React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  color: var(--primary-oqio-blue);
  cursor: pointer;
`;

const ShowMore = ({ text, truncatedText }) => {
  const [expanded, toggleText] = React.useState(false);
  const toggleActionLabel = expanded ? ' weniger' : 'mehr';

  return (
    <div>
      {!expanded && <span>{`${truncatedText}... `}</span>}
      {expanded && <span>{text}</span>}
      <Label onClick={() => toggleText(!expanded)}>{toggleActionLabel}</Label>
    </div>
  );
};

export default React.memo(ShowMore);
