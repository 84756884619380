import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { onGetCustomerPortalAvailability, postCustomerPortalSession } from './apiCalls';

export function* getCustomerPortalAvailabilitySaga() {
  try {
    const response = yield call(onGetCustomerPortalAvailability);
    if (response.data) {
      yield put(actions.getCustomerPortalAvailabilitySuccess(response.data));
    } else {
      yield put(actions.getCustomerPortalAvailabilityError());
    }
  } catch {
    yield put(actions.getCustomerPortalAvailabilityError());
  }
}

export function* postCustomerPortalSessionSaga() {
  try {
    const response = yield call(postCustomerPortalSession);
    if (response.data) {
      yield put(actions.postCustomerPortalSessionSuccess(response.data));
    } else {
      yield put(actions.postCustomerPortalSessionError());
    }
  } catch {
    yield put(actions.postCustomerPortalSessionError());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CUSTOMER_PORTAL_AVAILIBILITY, getCustomerPortalAvailabilitySaga),
    takeEvery(actions.POST_CUSTOMER_PORTAL_SESSION, postCustomerPortalSessionSaga)
  ]);
}
