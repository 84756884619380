import React from 'react';
import { IMPORT_SUMMARY_ROWS } from '../../consts/consts';
import { LoadingIndicator } from '../ui';
import {
  ImportLoadingStateWrapper,
  ImportSummaryWrapper,
  ImportSummaryRow,
  ImportSummaryIcon,
  ImportSummaryHeader,
  ImportSummaryText,
  ImportSummaryHighlightedText
} from '../ui/Layout/ImportLayout';

import { IconCheck } from '../ui/Icons';

/**
 * This component is used for displaing the loading states of the import process.
 * @param {boolean} isLoading - shows if the loading or the success state should be displayed
 * @param {boolean} isFileCheckLoadingState - flag to show if the first step of the import is loading or the second one.
 * @param {array} importSummary - this array contains the import summary entries, they have the structure of metadata objects.
 */
export const ImportLoadingState = ({ isLoading, isFileCheckLoadingState = false, importSummary }) => {
  const loadingMessage = isFileCheckLoadingState ? 'Die Datei wird eingelesen.' : 'Die Daten werden importiert.';

  const renderImportSummaryRows = () => {
    return IMPORT_SUMMARY_ROWS.map((summaryRow) => {
      const count = importSummary.find((entry) => entry.rel === summaryRow.key)?.count || 0;
      const entityName = count === 1 ? summaryRow.entityNameSingular : summaryRow.entityNamePlural;

      return (
        <ImportSummaryRow key={summaryRow.key}>
          <ImportSummaryIcon>
            <IconCheck />
          </ImportSummaryIcon>
          <ImportSummaryHighlightedText>{count}</ImportSummaryHighlightedText>
          <ImportSummaryText>
            {entityName} {summaryRow.operation}
          </ImportSummaryText>
        </ImportSummaryRow>
      );
    });
  };

  const getSuccessMessage = () => {
    if (!isFileCheckLoadingState) {
      return (
        <ImportSummaryWrapper>
          <ImportSummaryHeader>Import abgeschlossen</ImportSummaryHeader>
          {renderImportSummaryRows()}
        </ImportSummaryWrapper>
      );
    }
    return React.Fragment;
  };

  return (
    <ImportLoadingStateWrapper>
      <LoadingIndicator
        isLoading={isLoading}
        loadingStateMessage={loadingMessage}
        successMessage={getSuccessMessage()}
      />
    </ImportLoadingStateWrapper>
  );
};
