import styled from 'styled-components';
import { windowMaxWidth } from '../../components/ui/Layout/breakpoints';

const LoginContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-regular);
  max-width: 600px;
  position: absolute;
  top: 15vh;
  width: 90vw;
  z-index: 6;

  @media (height <= 600px) {
    max-width: 500px;
    top: 12vh;
  }
`;

const LoginWelcomMessage = styled.div`
  color: var(--greyscale-white);
  font-size: var(--font-size-heading-1);
  font-stretch: normal;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--font-letter-spacing-small);
  line-height: 1.13;
  margin-bottom: 40px;
  text-align: center;

  @media ${windowMaxWidth.mobile} {
    font-size: var(--font-size-heading-2);
    margin-bottom: 20px;
  }

  @media (height <= 600px) {
    font-size: var(--font-size-heading-2);
    margin-bottom: 20px;
  }
`;

const LoginFormBackground = styled.div`
  background-color: var(--greyscale-white);
  border-radius: var(--border-radius-small);
  width: 100%;
`;

const LoginFormWrapper = styled.div`
  padding: 40px;

  .ant-form {
    label,
    .ant-form-item-label {
      color: var(--greyscale-shade);
      margin-left: 13px;
      padding: 0;
      font-size: var(--font-size-small);
      line-height: var(--font-line-height-medium);
    }

    .ant-form-item-required {
      margin-left: 0;
    }

    .item-no-margin {
      margin-bottom: 0;
    }
  }

  @media ${windowMaxWidth.mobile} {
    padding: 30px;

    .ant-form {
      .ant-form-item {
        margin-bottom: 20px;
      }
    }
  }

  @media (height <= 600px) {
    padding: 30px;

    .ant-form {
      .ant-form-item {
        margin-bottom: 18px;
      }
    }
  }
`;

const LoginForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  position: relative;
  top: -16px;

  @media ${windowMaxWidth.mobile} {
    margin-right: 0;
    top: -20px;
  }

  @media (height <= 600px) {
    margin-right: 0;
    top: -20px;
  }
`;

const LoginButtonWrapper = styled.div`
  margin-top: 10px;
`;

export {
  LoginContentWrapper,
  LoginWelcomMessage,
  LoginFormBackground,
  LoginFormWrapper,
  LoginForgotPasswordWrapper,
  LoginButtonWrapper
};
