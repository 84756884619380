import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadConfig = async () =>
  axios
    .get(`${apiUrls.config}`)
    .then((res) => res)
    .catch((error) => error);

const onUpdateConfig = async (jsonConfig) =>
  axios
    .put(apiUrls.config, jsonConfig)
    .then((res) => res)
    .catch((error) => error);

export { onLoadConfig, onUpdateConfig };
