import React from 'react';

const IconUserDeleted = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 8.5V17c-3.23 0-4-3-4-3s-.846 3-3 3-3-3-3-3-1.308 3-4 3V8.5C3 4.91 6.134 2 10 2s7 2.91 7 6.5z"
        stroke="#111"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <ellipse cx="7.5" cy="8.5" rx="1.5" ry="2" fill="#111" />
      <ellipse cx="12.5" cy="8.5" rx="1.5" ry="2" fill="#111" />
    </svg>
  );
};
export default IconUserDeleted;
