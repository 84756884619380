import React from 'react';

const IconFiles = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 15V2h11" stroke="#111" strokeWidth="1.5" />
      <path d="M6 18V5h10v10a3 3 0 0 1-3 3H6z" stroke="#111" strokeWidth="1.5" />
      <path d="M8 8.5h6M8 11h6m-6 2.5h5" stroke="#111" />
    </svg>
  );
};

export default IconFiles;
