import { Map } from 'immutable';
import actions from './actions';

const INITIAL_STATE = new Map({
  bulkRemoveAuthorizedOrganizationsLoading: false,
  bulkRemoveAuthorizedOrganizationsError: false,
  addAuthorizedOrganisationLoading: false,
  deleteAuthorizedOrganisationLoading: false,
  deleteAuthorizationError: false,
  authorizedOrganisations: [],
  loadAuthorizedOrganisationsLoading: false,
  loadAuthorizedOrganisationsError: false,
  bulkAddAuthorizedOrganizationsLoading: false,
  bulkAddAuthorizedOrganizationsError: false,
  updateAuthorizedOrganisationLoading: false,
  updateAuthorizedOrganisationError: false
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CLEAR_SMART_ACTIONS_ERRORS:
      return state
        .set('bulkRemoveAuthorizedOrganizationsError', false)
        .set('bulkAddAuthorizedOrganizationsError', false);
    case actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS:
      return state
        .set('bulkRemoveAuthorizedOrganizationsLoading', true)
        .set('bulkRemoveAuthorizedOrganizationsError', false);
    case actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS_SUCCESS:
      return state
        .set('bulkRemoveAuthorizedOrganizationsLoading', false)
        .set('bulkRemoveAuthorizedOrganizationsError', false);
    case actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS_ERROR:
      return state
        .set('bulkRemoveAuthorizedOrganizationsLoading', false)
        .set('bulkRemoveAuthorizedOrganizationsError', true);
    case actions.BULK_ADD_AUTHORIZED_ORGANISATIONS:
      return state.set('bulkAddAuthorizedOrganizationsLoading', true).set('bulkAddAuthorizedOrganizationsError', false);
    case actions.BULK_ADD_AUTHORIZED_ORGANISATIONS_SUCCESS:
      return state
        .set('bulkAddAuthorizedOrganizationsLoading', false)
        .set('bulkAddAuthorizedOrganizationsError', false);
    case actions.BULK_ADD_AUTHORIZED_ORGANISATIONS_ERROR:
      return state.set('bulkAddAuthorizedOrganizationsLoading', false).set('bulkAddAuthorizedOrganizationsError', true);
    case actions.LOAD_AUTHORIZED_ORGANISATIONS:
      return state.set('loadAuthorizedOrganisationsLoading', true).set('loadAuthorizedOrganisationsError', false);
    case actions.LOAD_AUTHORIZED_ORGANISATIONS_SUCCESS:
      return state
        .set('authorizedOrganisations', action.payload)
        .set('loadAuthorizedOrganisationsLoading', false)
        .set('loadAuthorizedOrganisationsError', false);
    case actions.LOAD_AUTHORIZED_ORGANISATIONS_ERROR:
      return state.set('loadAuthorizedOrganisationsLoading', false).set('loadAuthorizedOrganisationsError', true);
    case actions.ADD_AUTHORIZED_ORGANISATION:
      return state.set('addAuthorizedOrganisationLoading', true).set('addAuthorizedOrganisationError', false);
    case actions.ADD_AUTHORIZED_ORGANISATION_SUCCESS:
      return state.set('addAuthorizedOrganisationLoading', false).set('addAuthorizedOrganisationError', false);
    case actions.ADD_AUTHORIZED_ORGANISATION_ERROR:
      return state.set('addAuthorizedOrganisationLoading', false).set('addAuthorizedOrganisationError', true);
    case actions.DELETE_AUTHORIZED_ORGANISATION:
      return state.set('deleteAuthorizedOrganisationLoading', true).set('deleteAuthorizationError', false);
    case actions.DELETE_AUTHORIZED_ORGANISATION_SUCCESS:
      return state.set('deleteAuthorizedOrganisationLoading', false).set('deleteAuthorizationError', false);
    case actions.DELETE_AUTHORIZED_ORGANISATION_ERROR:
      return state.set('deleteAuthorizedOrganisationLoading', false).set('deleteAuthorizationError', true);
    case actions.UPDATE_AUTHORIZED_ORGANISATION:
      return state.set('updateAuthorizedOrganisationLoading', true).set('updateAuthorizedOrganisationError', false);
    case actions.UPDATE_AUTHORIZED_ORGANISATION_SUCCESS:
      return state.set('updateAuthorizedOrganisationLoading', false).set('updateAuthorizedOrganisationError', false);
    case actions.UPDATE_AUTHORIZED_ORGANISATION_ERROR:
      return state.set('updateAuthorizedOrganisationLoading', false).set('updateAuthorizedOrganisationError', true);
    default:
      return state;
  }
};

export default reducer;
