const actions = {
  CLEAR_CLAIM_AUTHORIZATION_ERRORS: 'CLEAR_CLAIM_AUTHORIZATION_ERRORS',
  UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS: 'UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS',
  UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS: 'UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS',
  UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR: 'UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR',
  clearClaimAuthorizationErrors: () => ({
    type: actions.CLEAR_CLAIM_AUTHORIZATION_ERRORS
  }),
  updateClaimAuthorizedOrganizations: (organizationsToAdd, organizationsToRemove, claimId) => ({
    type: actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS,
    organizationsToAdd,
    organizationsToRemove,
    claimId
  }),
  updateClaimAuthorizedOrganizationsSuccess: (claimId) => ({
    type: actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS,
    claimId
  }),
  updateClaimAuthorizedOrganizationsError: () => ({
    type: actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR
  })
};
export default actions;
