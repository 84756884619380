import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadHousingStocks = async () =>
  axios
    .get(apiUrls.housingStocksBaseUrl)
    .then((res) => res)
    .catch((error) => error);

const onLoadClaimsHousingStocks = async () =>
  axios
    .get(`${apiUrls.claimBaseUrl}/housing-stocks`)
    .then((res) => res)
    .catch((error) => error);

const onCreateHousingStock = async (housingStocks) =>
  axios
    .post(apiUrls.housingStocksBaseUrl, housingStocks)
    .then((res) => res)
    .catch((error) => error);

const onUpdateHousingStock = async (housing_stock_id, payload) =>
  axios
    .put(`${apiUrls.housingStocksBaseUrl}/${housing_stock_id}`, payload)
    .then((res) => res)
    .catch((error) => error);

const onDeleteHousingStock = async (housing_stock_id) =>
  axios
    .delete(`${apiUrls.housingStocksBaseUrl}/${housing_stock_id}`)
    .then((res) => res)
    .catch((error) => error);

export {
  onLoadHousingStocks,
  onLoadClaimsHousingStocks,
  onCreateHousingStock,
  onUpdateHousingStock,
  onDeleteHousingStock
};
