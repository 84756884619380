import React from 'react';
import styled from 'styled-components';
import { windowMaxWidth } from '../../components/ui/Layout/breakpoints';

const StyledPasswordRules = styled.div`
  background-color: var(--greyscale-porcelain);
  box-shadow:
    inset 0 1px 0 0 var(--greyscale-dark-white),
    inset 0 -1px 0 0 var(--greyscale-dark-white);
  color: var(--greyscale-blackish);
  margin-bottom: ${(props) => (props.withBottomMargin ? '30px' : '0px')};
  padding: 10px 40px;

  .password-rules {
    &-title {
      display: block;
      font-size: var(--font-size-regular);
      font-weight: var(--font-weight-medium);
      line-height: var(--font-line-height-regular);
      margin-bottom: 5px;
    }

    &-list {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      line-height: var(--font-line-height-small);
      list-style-type: disc;
      margin-bottom: 0;
      margin-left: 16px;
    }
  }

  @media (${windowMaxWidth.mobile}) {
    padding: 10px 20px;
  }
`;

/**
 * Functional component that renders password rules.
 * Displays a list of password requirements to guide users in creating a secure password.
 * @property {Boolean} withBottomMargin - boolean to add a bottom margin to the component.
 */
const PasswordRules = ({ withBottomMargin }) => (
  <StyledPasswordRules withBottomMargin={withBottomMargin}>
    <span className="password-rules-title">Passwort Anforderungen</span>
    <ul className="password-rules-list">
      <li>mindestens 8 Zeichen</li>
      <li>mindestens ein Kleinbuchstabe</li>
      <li>mindestens ein Großbuchstabe</li>
      <li>mindestens eine Zahl</li>
    </ul>
  </StyledPasswordRules>
);

export default PasswordRules;
