import React from 'react';
import AntInput from 'antd/es/input';
import styled from 'styled-components';

const ExtendedInput = styled(AntInput)`
  &.ant-input {
    &:focus {
      border-color: var(--primary-oqio-blue);
      box-shadow: var(--box-shadow-oqio-blue);
    }

    &.ant-input-status-error:not(.ant-input-disabled) {
      box-shadow: var(--box-shadow-oqio-red);

      &:hover,
      &:focus {
        border-color: var(--primary-oqio-blue);
        box-shadow: var(--box-shadow-oqio-blue);
      }
    }

    &.ant-input-disabled {
      background-color: var(--greyscale-porcelain);
      border-color: var(--greyscale-dark-white);
      cursor: default;
    }
  }
`;

ExtendedInput.Password = AntInput.Password;

export const Input = (props) => <ExtendedInput {...props} maxLength={props.maxLength || 100} />;
