import { useState } from 'react';
import { onLoadHousingStocks } from '../redux/housingStocks/apiCalls';

export function useFetchHousingStocks() {
  const [housingStocks, setHousingStocks] = useState(null);

  const fetchHousingStocks = async () => {
    const response = await onLoadHousingStocks();

    setHousingStocks(response?.data);
  };

  return [housingStocks, fetchHousingStocks];
}
