import React from 'react';

const IconCircledClose = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m7.172 7.172 5.656 5.656M12.828 7.172l-5.656 5.656" stroke="#111" strokeWidth="1.5" />
    <circle cx="10" cy="10" r="8" stroke="#111" strokeWidth="1.5" />
  </svg>
);

export default IconCircledClose;
