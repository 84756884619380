import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AppRouter from './containers/App/AppRouter';
import { AuthRoutes } from './components/Auth/AuthRoutes';
import Login from './containers/Login/Login';
import InitialSignIn from './containers/Login/InitialSignin';
import ResetPassword from './containers/Login/ResetPassword';
import SimpleLayout from './containers/Layout/SimpleLayout';
import { ERROR_TYPES } from './consts/consts';
import { ErrorPage } from './components/ErrorPage/ErrorPage';
import authActions from './redux/auth/actions';
import configActions from './redux/config/actions';
import { gaRouteChange } from './config/googleAnalytics';

const PublicRoutes = ({ getConfig, validateUser, validateUserLoading, isLoggedIn }) => {
  const location = useLocation(); // Use the useLocation hook

  // On initial load, validate the user token
  useEffect(() => {
    getConfig();
    validateUser();
  }, []);

  // If the user is not logged in, shut down Intercom
  useEffect(() => {
    if (!isLoggedIn && window.Intercom) {
      window.Intercom('shutdown');
    }
  }, [isLoggedIn]);

  // Track page view on route change
  useEffect(() => {
    gaRouteChange(location.pathname);
  }, [location?.pathname]);

  if (validateUserLoading) {
    return <React.Fragment />;
  }

  return (
    <Routes>
      <Route path="/pageNotFound" element={<ErrorPage errorType={ERROR_TYPES.ERROR_PAGE_NOT_FOUND} />} />
      <Route path="/notAllowed" element={<ErrorPage errorType={ERROR_TYPES.ERROR_NOT_AUTHORIZED} />} />
      <Route path="/signin" element={<SimpleLayout component={Login} />} />
      <Route path="/initial-signin" element={<SimpleLayout component={InitialSignIn} />} />
      <Route path="/reset-password" element={<SimpleLayout component={ResetPassword} />} />
      <Route path="/*" element={<AuthRoutes component={AppRouter} />} />
      <Route path="*" element={<ErrorPage errorType={ERROR_TYPES.ERROR_PAGE_NOT_FOUND} />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.get('isLoggedIn'),
  validateUserLoading: state.Auth.get('validateUserLoading')
});

const { validateUser } = authActions;
const { getConfig } = configActions;

const mapDispatchToProps = {
  getConfig,
  validateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoutes);
