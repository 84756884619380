export default {
  authBaseUrl: '/api/v1/sessions',
  policyAuthorizationsBaseUrl: '/api/v1/policy-authorizations',
  claimAuthorizationsBaseUrl: '/api/v1/claim-authorizations',
  claimBaseUrl: '/api/v1/claims',
  config: '/api/v1/config',
  fileUploadBaseUrl: '/api/v1/blobs',
  organisationsBaseUrl: '/api/v1/organizations',
  passwordUrl: '/api/v1/password',
  performanceBaseUrl: '/api/v1/reportings',
  policyBaseUrl: '/api/v1/policies',
  schemaGroupBaseUrl: '/api/v1/schemagroups',
  settlementBaseUrl: '/api/v1/settlements',
  usersBaseUrl: '/api/v1/users',
  userRolesBaseUrl: '/api/v1/user-roles/create-or-update-user',
  messageTemplatesUrl: '/api/v1/message-templates',
  messageTemplatesPredefinedUrl: '/api/v1/message-templates-predefined',
  insuredRisksUrl: '/api/v1/insured-risks',
  insuredRisksPolicyUrl: '/api/v1/insured-risks',
  insuredRisksToPoliciesUrl: '/api/v1/insured-risk-to-policies',
  cutbackReasonsBaseUrl: '/api/v1/cutbacks',
  externalContacts: '/api/v1/external-contacts',
  housingStocksBaseUrl: 'api/v1/housing-stocks',
  fileContainersBaseUrl: 'api/v1/file-containers',
  risksBaseUrl: 'api/v1/risks',
  policiesToRisksBaseUrl: 'api/v1/policies-to-risks',
  importBaseUrl: 'api/v1/imports',
  stripeBaseUrl: 'api/v1/stripe'
};
