import { useState } from 'react';
import { onLoadUserIntercomHmac } from '../redux/users/apiCalls';

export function useFetchUserIntercomHmac() {
  const [userHmac, setUserHmac] = useState(null);

  const fetchUserIntercomHmac = async (userId) => {
    const response = await onLoadUserIntercomHmac(userId);

    setUserHmac(response?.data);
  };

  return [userHmac, fetchUserIntercomHmac];
}
