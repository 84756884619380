import React from 'react';

const IconMoreHorizontal = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="8" width="4" height="4" rx="2" fill="#111111" />
      <rect x="8" y="8" width="4" height="4" rx="2" fill="#111111" />
      <rect x="14" y="8" width="4" height="4" rx="2" fill="#111111" />
    </svg>
  );
};

export default IconMoreHorizontal;
