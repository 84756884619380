import React from 'react';
import AntTooltip from 'antd/es/tooltip';

const DEFAULT_TOOLTIP_PLACEMENT = 'top';
const VALID_TOOLTIP_PLACEMENTS = [
  DEFAULT_TOOLTIP_PLACEMENT,
  'left',
  'right',
  'bottom',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'leftTop',
  'leftBottom',
  'rightTop',
  'rightBottom'
];

const Tooltip = ({
  children,
  content,
  trigger = 'hover',
  mouseEnterDelay = 0.5,
  mouseLeaveDelay = 0,
  title,
  placement = DEFAULT_TOOLTIP_PLACEMENT,
  color = 'var(--greyscale-white)',
  textColor = 'var(--greyscale-blackish)',
  defaultOpen = false,
  destroyTooltipOnHide = true
}) => {
  const overlayInnerStyle = {
    padding: '10px 15px',
    borderRadius: 'var(--border-radius-regular)',
    color: textColor,
    boxShadow: '0 1px 20px 0 rgba(17, 17, 17, 0.2)'
  };

  // Check if the placement is valid, if not, use the default 'top' placement
  const validatedPlacement = VALID_TOOLTIP_PLACEMENTS.includes(placement) ? placement : DEFAULT_TOOLTIP_PLACEMENT;

  return (
    <AntTooltip
      trigger={trigger}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      content={content}
      title={title}
      placement={validatedPlacement}
      color={color}
      overlayInnerStyle={overlayInnerStyle}
      defaultOpen={defaultOpen}
      destroyTooltipOnHide={destroyTooltipOnHide}
    >
      {children} {''}
    </AntTooltip>
  );
};

export default Tooltip;
