import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadKpis = async (params) =>
  axios
    .get(`${apiUrls.performanceBaseUrl}/claims-and-settlements`, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadProfitabilityKpis = async (params) =>
  axios
    .get(`${apiUrls.performanceBaseUrl}/profitability`, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadPremiums = async (params) =>
  axios
    .get(`${apiUrls.performanceBaseUrl}/premiums`, { params })
    .then((res) => res)
    .catch((error) => error);

const onSetPremiumKpis = async (params) =>
  axios
    .put(`${apiUrls.performanceBaseUrl}/premiums`, params)
    .then((res) => res)
    .catch((error) => error);

export { onLoadKpis, onLoadProfitabilityKpis, onLoadPremiums, onSetPremiumKpis };
