import React from 'react';

const IconCalendar = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5z"
        stroke="#111"
        strokeWidth="1.5"
      />
      <path d="M2 7h16" stroke="#111" strokeLinecap="square" />
      <path d="M5 10h2M9 10h2M13 10h2M5 12.5h2M9 12.5h2M13 12.5h2M5 15h2M9 15h2M13 15h2" stroke="#111" />
    </svg>
  );
};
export default IconCalendar;
