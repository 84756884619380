import React from 'react';

const IconLogout = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7V4a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-3M17 10H6M14.5 13.5 18 10l-3.5-3.5"
        stroke="#111"
        strokeWidth="1.5"
      />
    </svg>
  );
};
export default IconLogout;
