import { Map } from 'immutable';
import actions from './actions';

const initialState = new Map({
  policiesToRisks: [],
  getPoliciesToRisksLoading: false,
  getPoliciesToRisksError: false,
  createPoliciesToRisksLoading: false,
  createPoliciesToRisksError: false,
  deletePoliciesToRisksLoading: false,
  deletePoliciesToRisksError: false
});

const policiesToRisksReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_POLICIES_TO_RISKS:
      return state.set('createPoliciesToRisksLoading', true);
    case actions.CREATE_POLICIES_TO_RISKS_SUCCESS:
      return state.set('createPoliciesToRisksLoading', false);
    case actions.CREATE_POLICIES_TO_RISKS_ERROR:
      return state.set('createPoliciesToRisksLoading', false).set('createPoliciesToRisksError', true);
    case actions.GET_POLICIES_TO_RISKS:
      return state.set('getPoliciesToRisksLoading', true);
    case actions.GET_POLICIES_TO_RISKS_SUCCESS:
      return state.set('policiesToRisks', action.payload).set('getPoliciesToRisksLoading', false);
    case actions.GET_POLICIES_TO_RISKS_ERROR:
      return state.set('getPoliciesToRisksLoading', false).set('getPoliciesToRisksError', true);
    case actions.DELETE_MANY_POLICIES_TO_RISKS:
      return state.set('deletePoliciesToRisksLoading', true);
    case actions.DELETE_MANY_POLICIES_TO_RISKS_SUCCESS:
      return state.set('deletePoliciesToRisksLoading', false);
    case actions.DELETE_MANY_POLICIES_TO_RISKS_ERROR:
      return state.set('deletePoliciesToRisksLoading', false).set('deletePoliciesToRisksError', true);
    case actions.CLEAR_POLICIES_TO_RISKS:
      return state.set('policiesToRisks', []);
    default:
      return state;
  }
};

export default policiesToRisksReducer;
