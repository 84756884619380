import { all, takeLatest, put, call } from 'redux-saga/effects';
import claimsActions from '../claims/actions';
import worklistActions from '../worklistClaims/actions';
import actions from './actions';
import { onAddClaimAuthorizedOrganisations, onDeleteClaimAuthorizedOrganisations } from './apiCalls';

export function* updateClaimAuthorizedOrganizations({ organizationsToAdd, organizationsToRemove, claimId }) {
  try {
    let organizationsToAddResponse;
    let organizationsToRemoveResponse;

    if (organizationsToAdd.length > 0) {
      const parsedOrganizationsToAdd = organizationsToAdd.map((org) => ({ claim_id: claimId, organization_id: org }));
      organizationsToAddResponse = yield call(onAddClaimAuthorizedOrganisations, parsedOrganizationsToAdd);
    }
    if (organizationsToRemove.length > 0) {
      organizationsToRemoveResponse = yield call(onDeleteClaimAuthorizedOrganisations, organizationsToRemove);
    }

    const isSuccess = (response) => {
      return response?.status === 200;
    };

    if (isSuccess(organizationsToAddResponse) || isSuccess(organizationsToRemoveResponse)) {
      yield put(actions.updateClaimAuthorizedOrganizationsSuccess(claimId));
    } else {
      yield put(actions.updateClaimAuthorizedOrganizationsError());
    }
  } catch {
    yield put(actions.updateClaimAuthorizedOrganizationsError());
  }
}

export function* reloadClaimOrganizationsWithAccess({ claimId }) {
  yield put(claimsActions.getClaimOrganizationsWithAccess(claimId));
  yield put(worklistActions.getMessageRecipientOrganizations(claimId));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS, updateClaimAuthorizedOrganizations),
    takeLatest(actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS, reloadClaimOrganizationsWithAccess)
  ]);
}
