import React from 'react';
import { notification } from 'antd';
import { Button } from '../../components/ui';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { ENTITY_TYPES_DICTIONARY } from '../../consts/dictionaries';
import renderNotification from './renderNotification';

const newEntityNotification = ({ entityType, entityId, navigate }) => {
  const handleClickNotificationLink = () => {
    navigate(`/${entityType}/${entityId}/main`);
    setTimeout(() => {
      notification.destroy(entityId);
    }, 500);
  };

  const message = NOTIFICATION_MESSAGES[`${entityType}_create_success`];
  const actionButton = (
    <Button className="btn-notification-success" onClick={handleClickNotificationLink} type="text">
      {ENTITY_TYPES_DICTIONARY[entityType]} ansehen
    </Button>
  );

  const rules = {
    type: 'success',
    duration: 5,
    message,
    description: actionButton,
    key: entityId
  };

  return renderNotification(rules);
};

export default newEntityNotification;
