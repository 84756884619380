import React from 'react';

const IconOpenedLock = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 8h10v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8z" stroke="#111" strokeWidth="1.5" />
      <rect x="7" y="11" width="2" height="4" rx="1" fill="#111" />
      <path d="M17 8V4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconOpenedLock;
