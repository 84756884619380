import React from 'react';

const IconEdit = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m15.026 2.237 2.121 2.122a.25.25 0 0 1 0 .353l-11.67 11.67L3 13.905 14.672 2.237a.25.25 0 0 1 .354 0z"
        stroke="#111"
        strokeWidth="1.5"
      />
      <path
        d="m2.547 16.838.095-2.572.255-.256 2.475 2.475-.256.258-2.569.095z"
        fill="#111"
        stroke="#111"
        strokeWidth="1.5"
      />
      <path d="M11.885 3.964 15.42 7.5" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconEdit;
