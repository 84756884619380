import React from 'react';
import { Button } from '../ui';
import ErrorPageLayout from '../ui/Layout/ErrorPageLayout';
import { EyecandyOldBrowser, EyecandyTechnicalProblem, EyecandyEmptySearch, EyecandyNotAuthorized } from '../ui/Icons';
import { ERROR_TYPES } from '../../consts/consts';

const {
  ErrorPageWrapper,
  ErrorPageMessageWrapper,
  ErrorPageIconWrapper,
  ErrorPageHeadline,
  ErrorPageMessage,
  ErrorPageLink
} = ErrorPageLayout;

export const ErrorPage = ({ errorType }) => {
  // This is only available in IE, so if it exists, the user is using IE
  const isWebBrowserDeprecated = window.document.documentMode;

  const getErrorIcon = () => {
    if (isWebBrowserDeprecated) {
      return <EyecandyOldBrowser />;
    }

    switch (errorType) {
      case ERROR_TYPES.ERROR_PAGE_NOT_FOUND:
        return <EyecandyEmptySearch />;
      case ERROR_TYPES.ERROR_NOT_AUTHORIZED:
        return <EyecandyNotAuthorized />;
      default:
        return <EyecandyTechnicalProblem />;
    }
  };

  const getErrorHeadline = () => {
    if (isWebBrowserDeprecated) {
      return 'Veralteter Browser';
    }
    return errorType.headline;
  };

  const getErrorMessage = () => {
    if (isWebBrowserDeprecated) {
      return 'Sie besuchen diese Seite mit einem veralteten Browser, der nicht mehr unterstützt wird (Internet Explorer). Bitte verwenden Sie einen modernen und aktualisierten Browser.';
    }
    return errorType.message;
  };

  const getErrorLink = () => {
    if (isWebBrowserDeprecated) {
      return;
    }
    return (
      <ErrorPageLink href="/">
        <Button type="primary">Zurück zur Startseite</Button>
      </ErrorPageLink>
    );
  };

  // this is the error page
  const errorPage = (
    <ErrorPageWrapper>
      <ErrorPageMessageWrapper>
        <ErrorPageIconWrapper>{getErrorIcon()}</ErrorPageIconWrapper>
        <ErrorPageHeadline>{getErrorHeadline()}</ErrorPageHeadline>
        <ErrorPageMessage>{getErrorMessage()}</ErrorPageMessage>
        {getErrorLink()}
      </ErrorPageMessageWrapper>
    </ErrorPageWrapper>
  );

  return errorPage;
};
