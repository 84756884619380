const actions = {
  FETCH_DOCUMENT: 'FETCH_DOCUMENT',
  FETCH_DOCUMENT_SUCCESS: 'FETCH_DOCUMENT_SUCCESS',
  FETCH_DOCUMENT_ERROR: 'FETCH_DOCUMENT_ERROR',
  fetchDocument: (apiUrl) => ({
    type: actions.FETCH_DOCUMENT,
    apiUrl
  }),
  fetchDocumentSuccess: (data) => ({
    type: actions.FETCH_DOCUMENT_SUCCESS,
    data
  }),
  fetchDocumentError: (error) => ({
    type: actions.FETCH_DOCUMENT_ERROR,
    error
  })
};

export default actions;
