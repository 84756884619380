import React from 'react';

const IconInfo = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8" stroke="#111" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 5a1.1 1.1 0 1 1 0 2.2A1.1 1.1 0 0 1 10 5z" fill="#111" />
    <path d="M10 15V9" stroke="#111" strokeWidth="1.5" />
  </svg>
);

export default IconInfo;
