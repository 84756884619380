import ReactGA from 'react-ga4';
import { GOOGLE_ANALYTICS_STAGING_ID } from '../consts/consts';
import { NODE_ENV } from './base';

// Enable Google Analytics in localhost and staging environments
const isEnabled = NODE_ENV === 'localhost' || NODE_ENV === 'staging';

// Initialize Google Analytics
export const initializeGoogleAnalytics = () => {
  if (isEnabled) {
    ReactGA.initialize(GOOGLE_ANALYTICS_STAGING_ID);
    ReactGA.send('pageview');
  }
};

// Track route changes
export const gaRouteChange = (pathname) => {
  if (isEnabled) {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }
};

// Track PDF report download
export const gaDownloadPdfReport = () => {
  if (isEnabled) {
    ReactGA.event({
      category: 'download', // General category for download events
      action: 'download_pdf_report', // Specifies the type of download
      label: 'PDF Report from Claim Details' // A more specific label for context
    });
  }
};
