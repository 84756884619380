import React from 'react';

const IconMessageFilter = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M15 7H5M13.5 10.5h-7M12.5 14h-5" stroke="#111" strokeWidth="1.5" />
  </svg>
);

export default IconMessageFilter;
