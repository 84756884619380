import React from 'react';

const IconCircledArrowRight = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8" stroke="#111" strokeWidth="1.5" />
    <path d="M6 10L12.5 10" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M9.5 6L13.5 10L9.5 14" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default IconCircledArrowRight;
