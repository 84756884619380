import { Map } from 'immutable';
import queryString from 'query-string';
import actions from './actions';

const initState = new Map({
  claimsActiveFilters: '',
  risksActiveFilters: '',
  risksSortingParameter: undefined,
  policiesActiveFilters: '',
  // These insured risk types are the default selection as of requirements.
  reportingActiveFilters: 'insured_risk_type=building&insured_risk_type=glass',
  isfilterDrawerOpen: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_CLAIMS_FILTERS:
      return state.set('claimsActiveFilters', queryString.stringify(action.filters));
    case actions.SET_RISKS_FILTERS:
      return state.set('risksActiveFilters', queryString.stringify(action.filters));
    case actions.SET_POLICIES_FILTERS:
      return state.set('policiesActiveFilters', queryString.stringify(action.filters));
    case actions.SET_REPORTING_FILTERS:
      return state.set('reportingActiveFilters', queryString.stringify(action.filters));
    case actions.SET_RISKS_SORTING_PARAMETER:
      return state.set('risksSortingParameter', action.sortingParameter);
    case actions.SHOW_FILTERS_DRAWER:
      return state.set('isfilterDrawerOpen', true);
    case actions.HIDE_FILTERS_DRAWER:
      return state.set('isfilterDrawerOpen', false);
    default:
      return state;
  }
};

export default reducer;
