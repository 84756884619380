import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadAll = async (params) =>
  axios
    .get(apiUrls.usersBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onCreateUser = async (user) =>
  axios
    .post(apiUrls.usersBaseUrl, user)
    .then((res) => res)
    .catch((error) => error);

const onUpdateUser = async (userId, params) =>
  axios
    .put(`${apiUrls.usersBaseUrl}/${userId}`, params)
    .then((res) => res)
    .catch((error) => error);

const onChangeUserPassword = async (currentPassword, newPassword, userId) =>
  axios
    .patch(`${apiUrls.usersBaseUrl}/${userId}/password/change`, {
      current_password: currentPassword,
      new_password: newPassword
    })
    .then((res) => res)
    .catch((error) => error);

const onGetUserRoles = async (organisationType) =>
  axios
    .get(`${apiUrls.userRolesBaseUrl}?organization_type=${organisationType}`)
    .then((res) => res)
    .catch((error) => error);

const onDeleteUser = async (userId) =>
  axios
    .delete(`${apiUrls.usersBaseUrl}/${userId}`)
    .then((res) => res)
    .catch((error) => error);

const onConfirmOptIn = async (userId) =>
  axios
    .patch(`${apiUrls.usersBaseUrl}/${userId}/terms-of-use/approve`)
    .then((res) => res)
    .catch((error) => error);
const onLoadUserIntercomHmac = async (userId) =>
  axios
    .get(`${apiUrls.usersBaseUrl}/${userId}/intercom-hmac`)
    .then((res) => res)
    .catch((error) => error);

export {
  onChangeUserPassword,
  onConfirmOptIn,
  onCreateUser,
  onGetUserRoles,
  onLoadAll,
  onUpdateUser,
  onDeleteUser,
  onLoadUserIntercomHmac
};
