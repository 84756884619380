import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  customerPortalAvailability: false,
  customerPortalAvailabilityLoading: false,
  customerPortalAvailabilityError: false,
  customerPortalSession: null,
  customerPortalSessionLoading: false,
  customerPortalSessionError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_CUSTOMER_PORTAL_AVAILIBILITY:
      return state.set('customerPortalAvailabilityLoading', true);
    case actions.GET_CUSTOMER_PORTAL_AVAILIBILITY_SUCCESS:
      return state
        .set('customerPortalAvailability', action?.customerPortalAvailability?.available)
        .set('customerPortalAvailabilityLoading', false);
    case actions.GET_CUSTOMER_PORTAL_AVAILIBILITY_ERROR:
      return state.set('customerPortalAvailabilityLoading', false).set('customerPortalAvailabilityError', true);
    case actions.POST_CUSTOMER_PORTAL_SESSION:
      return state.set('customerPortalSessionLoading', true);
    case actions.POST_CUSTOMER_PORTAL_SESSION_SUCCESS:
      return state
        .set('customerPortalSession', action?.customerPortalSession?.url)
        .set('customerPortalSessionLoading', false);
    case actions.POST_CUSTOMER_PORTAL_SESSION_ERROR:
      return state.set('customerPortalSessionLoading', false).set('customerPortalSessionError', true);
    default:
      return state;
  }
};

export default reducer;
