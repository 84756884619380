import React from 'react';

const IconEnvelope = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4h16v9.177C18 14.729 16.748 16 15.217 16H4.783C3.253 16 2 14.73 2 13.177V4z"
        stroke="#111"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path d="m2 4 7.271 7.292a1 1 0 0 0 1.415.002L18 4M17 15l-5-5m-9 5 5-5" stroke="#111" strokeMiterlimit="10" />
    </svg>
  );
};

export default IconEnvelope;
