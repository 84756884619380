import URL from 'url-parse';

export const BASE_URL = process.env.REACT_APP_OVERRIDE_API_URL || new URL(window.location.href).origin;
// We define our own NODE_ENV because the built-in process.env.NODE_ENV is set depending on npm command and not on our actual environment.
// Excerpt from https://create-react-app.dev/docs/adding-custom-environment-variables/:
// When you run npm start, it is always equal to 'development', when you run npm test it is always equal to 'test', and when you run npm run build to make a production bundle, it is always equal to 'production'
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const BING_MAPS_API_KEY = process.env.REACT_APP_BING_MAPS_API_KEY;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;
