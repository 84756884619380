import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import policyAction from '../policies/actions';
import actions from './actions';
import {
  onLoadInsuredRisk,
  onLoadInsuredRisks,
  onLoadInsuredRisksToPolicies,
  onAddInsuredRisksToPolicy,
  onUpdateInsuredRisksToPolicy,
  onDeleteInsuredRisksForPolicy,
  onLoadAvailableInsuredRisks,
  onLoadClaimsInsuredRisks
} from './apiCalls';

export function* getInsuredRisks({ params }) {
  try {
    const response = yield call(onLoadInsuredRisks, params);
    if (response.status === 200) {
      yield put(actions.getInsuredRisksSuccess(response.data));
    } else {
      yield put(actions.getInsuredRisksError());
    }
  } catch {
    yield put(actions.getInsuredRisksError());
  }
}

export function* getInsuredRisksForSmartAction({ params }) {
  try {
    const response = yield call(onLoadInsuredRisks, params);
    if (response.status === 200) {
      yield put(actions.getInsuredRisksForSmartActionSuccess(response.data));
    } else {
      yield put(actions.getInsuredRisksForSmartActionError());
    }
  } catch {
    yield put(actions.getInsuredRisksForSmartActionError());
  }
}

export function* getInsuredRisksForInsuredRiskId({ id }) {
  try {
    const response = yield call(onLoadInsuredRisk, id);
    if (response.status === 200) {
      yield put(actions.getInsuredRisksForIdSuccess(response.data));
    } else {
      yield put(actions.getInsuredRisksForIdError());
    }
  } catch {
    yield put(actions.getInsuredRisksForIdError());
  }
}
export function* getClaimsInsuredRisks({ params }) {
  try {
    const response = yield call(onLoadClaimsInsuredRisks, params);
    if (response.status === 200) {
      yield put(actions.getClaimsInsuredRisksSuccess(response.data));
    } else {
      yield put(actions.getClaimsInsuredRisksError());
    }
  } catch {
    yield put(actions.getClaimsInsuredRisksError());
  }
}

export function* getInsuredRisksForPolicy({ params }) {
  try {
    const response = yield call(onLoadInsuredRisks, params);

    if (response.status === 200) {
      yield put(actions.getInsuredRisksForPolicySuccess(response.data));
    } else {
      yield put(actions.getInsuredRisksForPolicyError());
    }
  } catch {
    yield put(actions.getInsuredRisksForPolicyError());
  }
}

export function* getAvailableInsuredRisks({ policy_type }) {
  try {
    const response = yield call(onLoadAvailableInsuredRisks, { policy_type });
    if (response.status === 200) {
      yield put(actions.getAvailableInsuredRisksSuccess(response.data));
    } else {
      yield put(actions.getAvailableInsuredRisksError());
    }
  } catch {
    yield put(actions.getAvailableInsuredRisksError());
  }
}

export function* getInsuredRisksForSelectedPolicy({ policy_id }) {
  try {
    const response = yield call(onLoadInsuredRisksToPolicies, { policy_id });
    if (response.status === 200) {
      yield put(actions.getInsuredRisksForSelectedPolicySuccess(response.data));
    } else {
      yield put(actions.getInsuredRisksForSelectedPolicyError());
    }
  } catch {
    yield put(actions.getInsuredRisksForSelectedPolicyError());
  }
}

export function* addInsuredRisksToPolicy({ insuredRisks }) {
  try {
    const response = yield call(onAddInsuredRisksToPolicy, insuredRisks);
    if (response.status === 200) {
      yield put(actions.addInsuredRisksToPolicySuccess());
    } else {
      yield put(actions.addInsuredRisksToPolicyError());
    }
  } catch {
    yield put(actions.addInsuredRisksToPolicyError());
  }
}

export function* updateInsuredRisksToPolicy({ insuredRisks }) {
  try {
    const { toUpdate, toDelete, toCreate } = insuredRisks;
    const responseUpdate = yield call(onUpdateInsuredRisksToPolicy, toUpdate);
    const responseDelete = yield call(onDeleteInsuredRisksForPolicy, toDelete);
    const responseCreate = yield call(onAddInsuredRisksToPolicy, toCreate);
    const isSuccess = responseUpdate.status === 200 && responseDelete.status === 200 && responseCreate.status === 200;
    if (isSuccess) {
      yield put(actions.updateInsuredRisksToPolicySuccess());
    } else {
      yield put(actions.updateInsuredRisksToPolicyError());
    }
  } catch {
    yield put(actions.updateInsuredRisksToPolicyError());
  }
}

export function* deleteInsuredRisksForPolicy({ insuredRisks, params }) {
  try {
    const response = yield call(onDeleteInsuredRisksForPolicy, insuredRisks);
    if (response.status === 200) {
      yield put(actions.deleteInsuredRisksForPolicySuccess());
      yield put(policyAction.loadPolicies(params));
    } else {
      yield put(actions.deleteInsuredRisksForPolicyError());
    }
  } catch {
    yield put(actions.deleteInsuredRisksForPolicyError());
  }
}

export function* getInsuredRisksFromManyPoliciesSaga({ params }) {
  try {
    const promises = params.map((policy) => onLoadInsuredRisksToPolicies({ policy_id: [policy.id] }));
    const response = yield Promise.all(promises);
    let payload = [];
    response.forEach((res) => {
      if (res.status === 200) {
        payload = [...payload, ...res.data];
      }
    });
    if (payload.length > 0) {
      yield put(actions.getInsuredRisksFromManyPoliciesSuccess(payload));
    } else {
      yield put(actions.getInsuredRisksFromManyPoliciesError());
    }
  } catch {
    actions.getInsuredRisksFromManyPoliciesError();
  }
}

const getSinglePolicy = (state) => state.Policies.get('policy');

export function* reloadInsuredRisksForSelectedPolicy() {
  const policy = yield select(getSinglePolicy);
  if (!policy) {
    return;
  }
  const { id } = policy;
  yield put(actions.getInsuredRisksForSelectedPolicy(id));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_INSURED_RISKS, getInsuredRisks),
    takeLatest(actions.GET_INSURED_RISKS_FOR_POLICY, getInsuredRisksForPolicy),
    takeLatest(actions.GET_AVAILABLE_INSURED_RISKS, getAvailableInsuredRisks),
    takeLatest(actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY, getInsuredRisksForSelectedPolicy),
    takeLatest(actions.ADD_INSURED_RISKS_TO_POLICY, addInsuredRisksToPolicy),
    takeLatest(actions.UPDATE_INSURED_RISKS_TO_POLICY, updateInsuredRisksToPolicy),
    takeLatest(actions.UPDATE_INSURED_RISKS_TO_POLICY_SUCCESS, reloadInsuredRisksForSelectedPolicy),
    takeLatest(actions.DELETE_INSURED_RISKS_FOR_POLICY, deleteInsuredRisksForPolicy),
    takeLatest(actions.GET_CLAIMS_INSURED_RISKS, getClaimsInsuredRisks),
    takeLatest(actions.GET_INSURED_RISKS_FOR_ID, getInsuredRisksForInsuredRiskId),
    takeLatest(actions.GET_INSURED_RISKS_FOR_SMART_ACTION, getInsuredRisksForSmartAction),
    takeLatest(actions.GET_INSURED_RISKS_FROM_MANY_POLICIES, getInsuredRisksFromManyPoliciesSaga)
  ]);
}
