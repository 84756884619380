import React from 'react';

const IconHousingStocks = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m6 9 6-5 6 5v8a1 1 0 0 1-1 1h-3.5v-3.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1V18H7a1 1 0 0 1-1-1V9z"
      stroke="#111"
      strokeWidth="1.5"
    />
    <path d="M9.5 3.25 8 2 2 7v8a1 1 0 0 0 1 1h1" stroke="#111" strokeWidth="1.5" />
  </svg>
);

export default IconHousingStocks;
