import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onGetActiveMessagesCount = async (params) =>
  axios
    .get(`${apiUrls.risksBaseUrl}/active-messages-count`, { params })
    .then((res) => res)
    .catch((error) => error);

const onGetRiskActiveMessagesCount = async (riskId) =>
  axios
    .get(`${apiUrls.risksBaseUrl}/${riskId}/active-messages-count`)
    .then((res) => res)
    .catch((error) => error);

const onGetWorklistRisks = async (params) => {
  const worklistRisksParams = {
    ...params,
    only_with_active_messages: true,
    sort: '-updated_at_risk_and_relations',
    paging: false
  };
  return axios
    .get(apiUrls.risksBaseUrl, { params: worklistRisksParams })
    .then((res) => res)
    .catch((error) => error);
};
const onGetWorklistRiskMessages = async (riskId, messagesParams) => {
  const params = messagesParams || {};
  return axios
    .get(`${apiUrls.risksBaseUrl}/${riskId}/messages`, { params })
    .then((responses) => responses)
    .catch((error) => error);
};
const onGetMessageRecipientOrganizations = async (riskId) => {
  return axios
    .get(`${apiUrls.risksBaseUrl}/${riskId}/message-recipient-organizations`)
    .then((response) => response)
    .catch((error) => error);
};
const onUpdateWorklistRiskMessage = async (riskId, message) => {
  const messageId = message.id;
  return axios
    .put(`${apiUrls.risksBaseUrl}/${riskId}/messages/${messageId}`, message)
    .then((res) => res)
    .catch((error) => error);
};
const onCreateWorklistRiskMessage = async (riskId, message) => {
  return axios
    .post(`${apiUrls.risksBaseUrl}/${riskId}/messages`, message)
    .then((response) => response)
    .catch((error) => error);
};

export {
  onGetActiveMessagesCount,
  onGetRiskActiveMessagesCount,
  onGetWorklistRisks,
  onGetWorklistRiskMessages,
  onGetMessageRecipientOrganizations,
  onCreateWorklistRiskMessage,
  onUpdateWorklistRiskMessage
};
