import React from 'react';

const IconWarning = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.132 2.52a1 1 0 0 1 1.736 0l7.42 12.984A1 1 0 0 1 17.42 17H2.58a1 1 0 0 1-.868-1.496l7.42-12.985z"
        stroke="#111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        className="dot"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14.7a1.1 1.1 0 1 1 0-2.2 1.1 1.1 0 0 1 0 2.2"
        fill="#111"
      />
      <path d="M10 6v5" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};

export default IconWarning;
