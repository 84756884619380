const actions = {
  GET_INSURED_RISKS: 'GET_INSURED_RISKS',
  GET_INSURED_RISKS_SUCCESS: 'GET_INSURED_RISKS_SUCCESS',
  GET_INSURED_RISKS_ERROR: 'GET_INSURED_RISKS_ERROR',
  GET_INSURED_RISKS_FOR_SMART_ACTION: 'GET_INSURED_RISKS_FOR_SMART_ACTION',
  GET_INSURED_RISKS_FOR_SMART_ACTION_SUCCESS: 'GET_INSURED_RISKS_FOR_SMART_ACTION_SUCCESS',
  GET_INSURED_RISKS_FOR_SMART_ACTION_ERROR: 'GET_INSURED_RISKS_FOR_SMART_ACTION_ERROR',
  GET_INSURED_RISKS_FROM_MANY_POLICIES: 'GET_INSURED_RISKS_FROM_MANY_POLICIES',
  GET_INSURED_RISKS_FROM_MANY_POLICIES_SUCCESS: 'GET_INSURED_RISKS_FROM_MANY_POLICIES_SUCCESS',
  GET_INSURED_RISKS_FROM_MANY_POLICIES_ERROR: 'GET_INSURED_RISKS_FROM_MANY_POLICIES_ERROR',
  CLEAR_INSURED_RISKS_FROM_MANY_POLICIES: 'CLEAR_INSURED_RISKS_FROM_MANY_POLICIES',
  GET_CLAIMS_INSURED_RISKS: 'GET_CLAIMS_INSURED_RISKS',
  GET_CLAIMS_INSURED_RISKS_SUCCESS: 'GET_CLAIMS_INSURED_RISKS_SUCCESS',
  GET_CLAIMS_INSURED_RISKS_ERROR: 'GET_CLAIMS_INSURED_RISKS_ERROR',
  GET_INSURED_RISKS_FOR_POLICY: 'GET_INSURED_RISKS_FOR_POLICY',
  GET_INSURED_RISKS_FOR_POLICY_SUCCESS: 'GET_INSURED_RISKS_FOR_POLICY_SUCCESS',
  GET_INSURED_RISKS_FOR_POLICY_ERROR: 'GET_INSURED_RISKS_FOR_POLICY_ERROR',
  GET_AVAILABLE_INSURED_RISKS: 'GET_AVAILABLE_INSURED_RISKS',
  GET_AVAILABLE_INSURED_RISKS_SUCCESS: 'GET_AVAILABLE_INSURED_RISKS_SUCCESS',
  GET_AVAILABLE_INSURED_RISKS_ERROR: 'GET_AVAILABLE_INSURED_RISKS_ERROR',
  GET_INSURED_RISKS_FOR_SELECTED_POLICY: 'GET_INSURED_RISKS_FOR_SELECTED_POLICY',
  GET_INSURED_RISKS_FOR_SELECTED_POLICY_SUCCESS: 'GET_INSURED_RISKS_FOR_SELECTED_POLICY_SUCCESS',
  GET_INSURED_RISKS_FOR_SELECTED_POLICY_ERROR: 'GET_INSURED_RISKS_FOR_SELECTED_POLICY_ERROR',
  GET_INSURED_RISKS_FOR_ID: 'GET_INSURED_RISKS_FOR_ID',
  GET_INSURED_RISKS_FOR_ID_SUCCESS: 'GET_INSURED_RISKS_FOR_ID_SUCCESS',
  GET_INSURED_RISKS_FOR_ID_ERROR: 'GET_INSURED_RISKS_FOR_ID_ERROR',
  ADD_INSURED_RISKS_TO_POLICY: 'ADD_INSURED_RISKS_TO_POLICY',
  ADD_INSURED_RISKS_TO_POLICY_ERROR: 'ADD_INSURED_RISKS_TO_POLICY_ERROR',
  ADD_INSURED_RISKS_TO_POLICY_SUCCESS: 'ADD_INSURED_RISKS_TO_POLICY_SUCCESS',
  UPDATE_INSURED_RISKS_TO_POLICY: 'UPDATE_INSURED_RISKS_TO_POLICY',
  UPDATE_INSURED_RISKS_TO_POLICY_ERROR: 'UPDATE_INSURED_RISKS_TO_POLICY_ERROR',
  UPDATE_INSURED_RISKS_TO_POLICY_SUCCESS: 'UPDATE_INSURED_RISKS_TO_POLICY_SUCCESS',
  DELETE_INSURED_RISKS_FOR_POLICY: 'DELETE_INSURED_RISKS_FOR_POLICY',
  DELETE_INSURED_RISKS_FOR_POLICY_SUCCESS: 'DELETE_INSURED_RISKS_FOR_POLICY_SUCCESS',
  DELETE_INSURED_RISKS_FOR_POLICY_ERROR: 'DELETE_INSURED_RISKS_FOR_POLICY_ERROR',
  RESET_AVAILABLE_INSURED_RISKS: 'RESET_AVAILABLE_INSURED_RISKS',
  getInsuredRisks: (params) => ({
    type: actions.GET_INSURED_RISKS,
    params
  }),
  getInsuredRisksSuccess: (payload) => ({
    type: actions.GET_INSURED_RISKS_SUCCESS,
    payload
  }),
  getInsuredRisksError: () => ({
    type: actions.GET_INSURED_RISKS_ERROR
  }),
  getInsuredRisksForSmartAction: (params) => ({
    type: actions.GET_INSURED_RISKS_FOR_SMART_ACTION,
    params
  }),
  getInsuredRisksForSmartActionSuccess: (payload) => ({
    type: actions.GET_INSURED_RISKS_FOR_SMART_ACTION_SUCCESS,
    payload
  }),
  getInsuredRisksForSmartActionError: () => ({
    type: actions.GET_INSURED_RISKS_FOR_SMART_ACTION_ERROR
  }),
  getClaimsInsuredRisks: (params) => ({
    type: actions.GET_CLAIMS_INSURED_RISKS,
    params
  }),
  getClaimsInsuredRisksSuccess: (payload) => ({
    type: actions.GET_CLAIMS_INSURED_RISKS_SUCCESS,
    payload
  }),
  getClaimsInsuredRisksError: () => ({
    type: actions.GET_CLAIMS_INSURED_RISKS_ERROR
  }),
  getInsuredRisksForPolicy: (params) => ({
    type: actions.GET_INSURED_RISKS_FOR_POLICY,
    params
  }),
  getInsuredRisksForPolicySuccess: (payload) => ({
    type: actions.GET_INSURED_RISKS_FOR_POLICY_SUCCESS,
    payload
  }),
  getInsuredRisksForPolicyError: () => ({
    type: actions.GET_INSURED_RISKS_FOR_POLICY_ERROR
  }),
  getInsuredRisksForId: (id) => ({
    type: actions.GET_INSURED_RISKS_FOR_ID,
    id
  }),
  getInsuredRisksForIdSuccess: (payload) => ({
    type: actions.GET_INSURED_RISKS_FOR_ID_SUCCESS,
    payload
  }),
  getInsuredRisksForIdError: () => ({
    type: actions.GET_INSURED_RISKS_FOR_ID_ERROR
  }),
  getAvailableInsuredRisks: (policy_type) => ({
    type: actions.GET_AVAILABLE_INSURED_RISKS,
    policy_type
  }),
  getAvailableInsuredRisksSuccess: (payload) => ({
    type: actions.GET_AVAILABLE_INSURED_RISKS_SUCCESS,
    payload
  }),
  getAvailableInsuredRisksError: () => ({
    type: actions.GET_AVAILABLE_INSURED_RISKS_ERROR
  }),
  resetAvailableInsuredRisks: () => ({
    type: actions.RESET_AVAILABLE_INSURED_RISKS
  }),
  getInsuredRisksForSelectedPolicy: (policy_id) => ({
    type: actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY,
    policy_id
  }),
  getInsuredRisksForSelectedPolicySuccess: (payload) => ({
    type: actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY_SUCCESS,
    payload
  }),
  getInsuredRisksForSelectedPolicyError: () => ({
    type: actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY_ERROR
  }),
  addInsuredRisksToPolicy: (insuredRisks) => ({
    type: actions.ADD_INSURED_RISKS_TO_POLICY,
    insuredRisks
  }),
  addInsuredRisksToPolicySuccess: () => ({
    type: actions.ADD_INSURED_RISKS_TO_POLICY_SUCCESS
  }),
  addInsuredRisksToPolicyError: () => ({
    type: actions.ADD_INSURED_RISKS_TO_POLICY_ERROR
  }),
  updateInsuredRisksToPolicy: (insuredRisks) => ({
    type: actions.UPDATE_INSURED_RISKS_TO_POLICY,
    insuredRisks
  }),
  updateInsuredRisksToPolicySuccess: () => ({
    type: actions.UPDATE_INSURED_RISKS_TO_POLICY_SUCCESS
  }),
  updateInsuredRisksToPolicyError: () => ({
    type: actions.UPDATE_INSURED_RISKS_TO_POLICY_ERROR
  }),
  deleteInsuredRisksForPolicy: (insuredRisks, params) => ({
    type: actions.DELETE_INSURED_RISKS_FOR_POLICY,
    insuredRisks,
    params
  }),
  deleteInsuredRisksForPolicySuccess: () => ({
    type: actions.DELETE_INSURED_RISKS_FOR_POLICY_SUCCESS
  }),
  deleteInsuredRisksForPolicyError: () => ({
    type: actions.DELETE_INSURED_RISKS_FOR_POLICY_ERROR
  }),
  getInsuredRisksFromManyPolicies: (params) => ({
    type: actions.GET_INSURED_RISKS_FROM_MANY_POLICIES,
    params
  }),
  getInsuredRisksFromManyPoliciesSuccess: (payload) => ({
    type: actions.GET_INSURED_RISKS_FROM_MANY_POLICIES_SUCCESS,
    payload
  }),
  getInsuredRisksFromManyPoliciesError: () => ({
    type: actions.GET_INSURED_RISKS_FROM_MANY_POLICIES_ERROR
  }),
  clearInsuredRisksFromManyPolicies: () => ({
    type: actions.CLEAR_INSURED_RISKS_FROM_MANY_POLICIES
  })
};
export default actions;
