import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';

export function* setSidebarDefaultLocationSaga({ value }) {
  yield put(actions.setSidebarLocation(value));
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_SIDEBAR_DEFAULT_LOCATION, setSidebarDefaultLocationSaga)]);
}
