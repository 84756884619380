import React from 'react';

const EyecandyNotAuthorized = () => {
  return (
    <svg width="71" height="60" viewBox="0 0 71 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 12.25a.75.75 0 0 0-.75.75v4c0 .414.336.75.75.75h12a.75.75 0 0 0 .75-.75v-4a.75.75 0 0 0-.75-.75h-12zm.75 4v-2.5h10.5v2.5h-10.5z"
        fill="#B3B3B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5 4.25c-5.937 0-10.75 4.813-10.75 10.75s4.813 10.75 10.75 10.75S46.25 20.937 46.25 15 41.437 4.25 35.5 4.25zM26.25 15a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0zM5.75 30a.75.75 0 0 1 .75-.75h58a.747.747 0 0 1 .75.75v9a.75.75 0 0 1-.75.75h-6.786l7.457 14.915a.75.75 0 0 1-.671 1.085h-6a.75.75 0 0 1-.67-.415l-4.33-8.658-4.33 8.658a.75.75 0 0 1-.67.415h-6a.75.75 0 0 1-.67-1.085l7.456-14.915H21.713l7.458 14.915a.75.75 0 0 1-.671 1.085h-6a.75.75 0 0 1-.67-.415l-4.33-8.658-4.33 8.658a.75.75 0 0 1-.67.415h-6a.75.75 0 0 1-.67-1.085l7.457-14.915H6.5a.75.75 0 0 1-.75-.75v-9zm45.964 9.75 2.457 4.915v.002l4.793 9.583h4.322l-7.25-14.5h-4.322zm3.475-1.5 7.5-7.5h-5.878l-7.5 7.5h5.878zM50.5 40.677 43.714 54.25h4.322L52.661 45 50.5 40.677zm-36 0L16.662 45l-4.625 9.25H7.713L14.5 40.677zm8.463 13.573-4.791-9.584-.001-.001-2.457-4.915h4.322l7.25 14.5h-4.323zm-7.774-16H9.311l7.5-7.5h5.878l-7.5 7.5zm8 0h-5.878l7.5-7.5h5.878l-7.5 7.5zm34.122 0h6.439v-6.44l-6.44 6.44zm-8.5-7.5h5.878l-7.5 7.5h-5.878l7.5-7.5zm-2.122 0-7.5 7.5h-5.878l7.5-7.5h5.878zm-13.878 0h5.878l-7.5 7.5h-5.878l7.5-7.5zm-18.122 0L7.25 38.19v-7.44h7.44z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default EyecandyNotAuthorized;
