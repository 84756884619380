import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  housingStocks: [],
  housingStocksLoading: false,
  housingStocksError: false,
  claimsHousingStocks: [],
  claimsHousingStocksLoading: false,
  claimsHousingStocksError: false,
  createHousingStockLoading: false,
  createHousingStockError: false,
  updateHousingStockLoading: false,
  updateHousingStockError: false,
  deleteHousingStockLoading: false,
  deleteHousingStockError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CREATE_HOUSING_STOCK:
      return state.set('createHousingStockLoading', true);
    case actions.CREATE_HOUSING_STOCK_SUCCESS:
      return state.set('createHousingStockLoading', false);
    case actions.CREATE_HOUSING_STOCK_ERROR:
      return state.set('createHousingStockError', true).set('createHousingStockLoading', false);
    case actions.CLEAR_HOUSING_STOCKS_ERRORS:
      return state.set('createHousingStockError', false).set('updateHousingStockError', false);
    case actions.UPDATE_HOUSING_STOCK:
      return state.set('updateHousingStockLoading', true).set('updateHousingStockError', false);
    case actions.UPDATE_HOUSING_STOCK_SUCCESS:
      return state.set('updateHousingStockLoading', false);
    case actions.UPDATE_HOUSING_STOCK_ERROR:
      return state.set('updateHousingStockError', true).set('updateHousingStockLoading', false);
    case actions.DELETE_HOUSING_STOCK:
      return state.set('deleteHousingStockLoading', true).set('deleteHousingStockError', false);
    case actions.DELETE_HOUSING_STOCK_SUCCESS:
      return state.set('deleteHousingStockLoading', false);
    case actions.DELETE_HOUSING_STOCK_ERROR:
      return state.set('deleteHousingStockError', true).set('deleteHousingStockLoading', false);
    case actions.LOAD_HOUSING_STOCKS:
      return state.set('housingStocksLoading', true);
    case actions.LOAD_HOUSING_STOCKS_SUCCESS:
      return state.set('housingStocks', action.housingStocks).set('housingStocksLoading', false);
    case actions.LOAD_HOUSING_STOCKS_ERROR:
      return state.set('housingStocksError', true).set('housingStocksLoading', false);
    case actions.LOAD_CLAIMS_HOUSING_STOCKS:
      return state.set('claimsHousingStocksLoading', true);
    case actions.LOAD_CLAIMS_HOUSING_STOCKS_SUCCESS:
      return state.set('claimsHousingStocks', action.housingStocks).set('claimsHousingStocksLoading', false);
    case actions.LOAD_CLAIMS_HOUSING_STOCKS_ERROR:
      return state.set('claimsHousingStocksError', true).set('claimsHousingStocksLoading', false);
    default:
      return state;
  }
};

export default reducer;
