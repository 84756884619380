import React from 'react';

const LoadingIndicator = () => (
  <svg
    className="loading-indicator"
    xmlns="http://www.w3.org/2000/svg"
    style={{ background: 'rgba(241, 242, 243, 0)', display: 'block', shapeRendering: 'auto' }}
    width="20px"
    height="20px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#627fff"
      strokeWidth="14"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.0204081632653061s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export default LoadingIndicator;
