import React from 'react';
import AntModal from 'antd/es/modal';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { windowMaxWidth } from '../Layout/breakpoints';

const ExtendedModal = styled(AntModal)`
  height: 100%;
  width: 100% !important;

  .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
    height: 100%;
  }

  &.ant-modal {
    font-family: var(--font-family-regular);
    margin: 0;
    max-width: 100%;
    padding: 0;
    top: 0;

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body {
      font-size: var(--font-size-regular);
      height: 100%;
      padding: 0;
    }

    .ant-modal-footer {
      background: transparent;
      border-radius: 0 0 8px 8px;
      border-top: 1px solid var(--greyscale-dark-white);
      padding: 20px 30px 30px;
      text-align: right;
    }

    @media (${windowMaxWidth.mobile}) {
      .ant-modal-footer {
        padding: 20px 20px 30px;
        margin-top: 0;
      }
    }
  }

  &.worklist-claim-reminder-modal,
  &.worklist-claim-attachments-modal {
    animation-duration: 0.2s !important;
    height: calc(100vh - 60px);
    max-width: 600px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 60px;

    .ant-modal-content {
      background-color: var(--secondary-whisper);
      border-radius: unset;
      box-shadow: none;
      display: flex;
      padding: 0;
      flex-direction: column;
      height: calc(100vh - 60px);
      justify-content: space-between;

      .ant-modal-close-x {
        height: 40px;
        padding-right: 20px;
        padding-top: 20px;
        width: 40px;

        .close-icon-wrapper {
          height: 20px;
          width: 20px;

          svg {
            path {
              stroke: var(--primary-oqio-blue);
            }
          }

          &:hover {
            background-color: initial;
          }
        }
      }

      .ant-modal-header {
        align-items: flex-end;
        border: 1px solid var(--greyscale-dark-white);
        border-radius: unset;
        flex: 1 0 102px;
        height: 100px;
        justify-content: flex-start;

        .ant-modal-title {
          color: var(--greyscale-blackish);
          line-height: var(--font-line-height-large);
        }
      }

      .ant-modal-body {
        flex: 1 1 100%;
        overflow: auto;
      }

      @media ${windowMaxWidth.mobile} {
        height: 100dvh;
      }
    }

    .ant-modal-footer {
      background-color: var(--greyscale-white);
      height: 80px;
      padding: 20px;
    }

    @media ${windowMaxWidth.splitScreen} {
      max-width: 100%;
    }

    @media (${windowMaxWidth.mobile}) {
      height: auto;
      min-height: 100dvh;
      top: 0;
    }
  }
`;

ExtendedModal.confirm = AntModal.confirm;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalFooter = ({ ...props }) => {
  const { onCancel, onSubmit, disableSubmit } = props;
  const disableSubmitButton = disableSubmit || false;
  return (
    <StyledFooter>
      <Button onClick={onCancel} type="secondary">
        Abbrechen
      </Button>
      <Button disabled={disableSubmitButton} type="primary" onClick={onSubmit}>
        Speichern
      </Button>
    </StyledFooter>
  );
};

const WorklistModal = (props) => {
  const footer = props.footer || <ModalFooter {...props} />;
  return (
    <ExtendedModal closable={false} footer={footer} {...props}>
      {props.children}
    </ExtendedModal>
  );
};
// const Modal = (props) => <ExtendedModal {...props}>{props.children}</ExtendedModal>;
export default WorklistModal;
