import React from 'react';

const IconReminderActive = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.196 6.777a9.036 9.036 0 0 0-4.134-4.31c1.221-.977 2.971-.934 4.06.154 1.116 1.117 1.132 2.932.074 4.156zM6.086 2.393a9.037 9.037 0 0 0-4.193 4.193c-.9-1.216-.832-2.905.228-3.965s2.749-1.128 3.965-.228z"
        fill="#111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 10.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0zm-8.25-5v4.25H6v1.5h4.75V5.5h-1.5z"
        fill="#111"
      />
    </svg>
  );
};

export default IconReminderActive;
