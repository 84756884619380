import React from 'react';

const IconWorklist = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 14v-4l-3.14-5.496A1 1 0 0 0 13.99 4H6.01a1 1 0 0 0-.868.504L2 10v4a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2z"
      stroke="#111"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M18 10h-5.5c0 1.5-1 2.5-2.5 2.5s-2.5-1-2.5-2.5H2" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);
export default IconWorklist;
