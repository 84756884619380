import React from 'react';

const IconMaximize = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.684 8.316 18 2M2 18l6.316-6.316M7.895 18H2v-5.895M18 7.895V2h-5.895"
      stroke="#111"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconMaximize;
