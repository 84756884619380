import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  isSplitScreen: false,
  isSidebarOpen: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_SPLITSCREEN_MODE:
      return state.set('isSplitScreen', action.value);
    case actions.OPEN_SIDEBAR:
      return state.set('isSidebarOpen', true);
    case actions.CLOSE_SIDEBAR:
      return state.set('isSidebarOpen', false);
    case actions.TOGGLE_SIDEBAR_SUCCESS:
      return state.set('isSidebarOpen', !state.get('isSidebarOpen'));
    default:
      return state;
  }
};

export default reducer;
