const actions = {
  CREATE_RISKS: 'CREATE_RISKS',
  CREATE_RISKS_ERROR: 'CREATE_RISKS_ERROR',
  CREATE_RISKS_SUCCESS: 'CREATE_RISKS_SUCCESS',
  GET_RISK: 'GET_RISK',
  GET_RISK_ERROR: 'GET_RISK_ERROR',
  GET_RISK_SUCCESS: 'GET_RISK_SUCCESS',
  GET_RISKS: 'GET_RISKS',
  GET_RISKS_ERROR: 'GET_RISKS_ERROR',
  GET_RISKS_SUCCESS: 'GET_RISKS_SUCCESS',
  GET_ALL_RISKS: 'GET_ALL_RISKS',
  GET_ALL_RISKS_ERROR: 'GET_ALL_RISKS_ERROR',
  GET_ALL_RISKS_SUCCESS: 'GET_ALL_RISKS_SUCCESS',
  UPDATE_RISKS: 'UPDATE_RISKS',
  UPDATE_RISKS_ERROR: 'UPDATE_RISKS_ERROR',
  UPDATE_RISKS_SUCCESS: 'UPDATE_RISKS_SUCCESS',
  EXPORT_RISKS: 'EXPORT_RISKS',
  EXPORT_RISKS_SUCCESS: 'EXPORT_RISKS_SUCCESS',
  EXPORT_RISKS_ERROR: 'EXPORT_RISKS_ERROR',
  DELETE_RISKS: 'DELETE_RISKS',
  DELETE_RISKS_ERROR: 'DELETE_RISKS_ERROR',
  DELETE_RISKS_SUCCESS: 'DELETE_RISKS_SUCCESS',
  RESET_RISKS: 'RESET_RISKS',
  RESET_ALL_RISKS: 'RESET_ALL_RISKS',
  GET_RISKS_HOUSING_STOCKS: 'GET_RISKS_HOUSING_STOCKS',
  GET_RISKS_HOUSING_STOCKS_ERROR: 'GET_RISKS_HOUSING_STOCKS_ERROR',
  GET_RISKS_HOUSING_STOCKS_SUCCESS: 'GET_RISKS_HOUSING_STOCKS_SUCCESS',
  GET_RISKS_COMPANY_CODES: 'GET_RISKS_COMPANY_CODES',
  GET_RISKS_COMPANY_CODES_ERROR: 'GET_RISKS_COMPANY_CODES_ERROR',
  GET_RISKS_COMPANY_CODES_SUCCESS: 'GET_RISKS_COMPANY_CODES_SUCCESS',
  GET_RISKS_ECONOMIC_UNITS: 'GET_RISKS_ECONOMIC_UNITS',
  GET_RISKS_ECONOMIC_UNITS_ERROR: 'GET_RISKS_ECONOMIC_UNITS_ERROR',
  GET_RISKS_ECONOMIC_UNITS_SUCCESS: 'GET_RISKS_ECONOMIC_UNITS_SUCCESS',
  CLEAR_SINGLE_RISK: 'CLEAR_SINGLE_RISK',
  GET_CONNECTED_POLICIES: 'GET_CONNECTED_POLICIES',
  GET_CONNECTED_POLICIES_ERROR: 'GET_CONNECTED_POLICIES_ERROR',
  GET_CONNECTED_POLICIES_SUCCESS: 'GET_CONNECTED_POLICIES_SUCCESS',
  CLEAR_CONNECTED_POLICIES: 'CLEAR_CONNECTED_POLICIES',
  SET_LAST_VISITED_RISK_ID: 'SET_LAST_VISITED_RISK_ID',
  RESET_LAST_VISITED_RISK_ID: 'RESET_LAST_VISITED_RISK_ID',

  /**
   * Provide a list of risks to the reducer
   * @param {Array} risks - The list of risks to be created
   * @param {Array} linkedPolicies - The policies linked to this buidings
   */
  createRisks: (risks, linkedPolicies, callback, navigate) => ({
    type: actions.CREATE_RISKS,
    risks,
    linkedPolicies,
    callback,
    navigate
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias createRisksError
   */
  createRisksError: () => ({
    type: actions.CREATE_RISKS_ERROR
  }),

  /**
   * Returns an array that contains the newly created risks
   * @param {Array} payload - Array of risks that have been created
   * @memberof actions
   * @alias createRisksSuccess
   */
  createRisksSuccess: (payload) => ({
    type: actions.CREATE_RISKS_SUCCESS,
    payload
  }),

  /**
   * Provide a set of filter parameters to the reducer.
   * Returns a list of risks that match the filter parameters.
   * @param {Object} params - filter parameters to be used to filter the list of risks
   * @memberof actions
   * @alias getRisk
   */
  getRisks: (params) => ({
    type: actions.GET_RISKS,
    params
  }),

  /**
   * Provide an error to the reducer
   *  @memberof actions
   * @alias getRisksError
   */
  getRisksError: () => ({
    type: actions.GET_RISKS_ERROR
  }),

  /**
   * Provide a success message to the reducer
   * @param {Array} payload - List of risks
   * @memberof actions
   * @alias getRisksSuccess
   */
  getRisksSuccess: (payload, meta) => ({
    type: actions.GET_RISKS_SUCCESS,
    payload,
    meta
  }),

  /**
   * Provide a risk ID to the reducer
   * @param {string} riskId
   * @memberof actions
   * @alias getRisk
   */
  getRisk: (riskId) => ({
    type: actions.GET_RISK,
    riskId
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias getRiskError
   */
  getRiskError: () => ({
    type: actions.GET_RISK_ERROR
  }),

  /**
   * Provide the risk to the reducer
   * @param {Object} payload - The risk
   * @memberof actions
   * @alias getRiskSuccess
   */
  getRiskSuccess: (payload) => ({
    type: actions.GET_RISK_SUCCESS,
    payload
  }),

  /**
   * Action to fetch all risks that match the risksActiveFilters o the Filters reducer
   * Response is not paginated
   */
  getAllRisks: () => ({
    type: actions.GET_ALL_RISKS
  }),

  getAllRisksError: () => ({
    type: actions.GET_ALL_RISKS_ERROR
  }),

  getAllRisksSuccess: (payload) => ({
    type: actions.GET_ALL_RISKS_SUCCESS,
    payload
  }),

  /**
   * Clears the allRisks state in the reducer
   */
  resetAllRisks: () => ({
    type: actions.RESET_ALL_RISKS
  }),

  /**
   * Provide to the reducer the list of risks that we want to update
   * @param {Array} risks - The list of risks to be updated
   * @memberof actions
   * @alias updateRisks
   */
  updateRisk: (risks, callback) => ({
    type: actions.UPDATE_RISKS,
    risks,
    callback
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias updateRisksError
   */
  updateRisksError: () => ({
    type: actions.UPDATE_RISKS_ERROR
  }),

  /**
   * Provide to the reducer the list of risks that have been updated
   * @param {Array} payload - The list of risks that have been updated
   * @memberof actions
   * @alias updateRisksSuccess
   */
  updateRisksSuccess: (risks) => ({
    type: actions.UPDATE_RISKS_SUCCESS,
    risks
  }),
  /**
   * @memberof actions
   * @alias exportRisks
   */
  exportRisks: (params) => ({
    type: actions.EXPORT_RISKS,
    params
  }),
  /**
   * @memberof actions
   * @alias exportRisksSuccess
   */
  exportRisksSuccess: () => ({
    type: actions.EXPORT_RISKS_SUCCESS
  }),
  /**
   * Provides an error to the reducer
   * @memberof actions
   * @alias exportRisksError
   */
  exportRisksError: () => ({
    type: actions.EXPORT_RISKS_ERROR
  }),
  /**
   * Provide to the reducer the list of risks that we want to delete
   * @param {Array} payload - The list of risks ids to be deleted
   * @memberof actions
   * */
  deleteRisks: (payload, callback) => ({
    type: actions.DELETE_RISKS,
    payload,
    callback
  }),

  deleteRisksError: () => ({
    type: actions.DELETE_RISKS_ERROR
  }),

  deleteRisksSuccess: () => ({
    type: actions.DELETE_RISKS_SUCCESS
  }),

  /**
   * @memberof actions
   * @alias resetRisks
   */
  resetRisks: () => ({
    type: actions.RESET_RISKS
  }),

  /**
   * @memberof actions
   * @alias getRisksCompanyCodes
   */
  getRisksCompanyCodes: () => ({
    type: actions.GET_RISKS_COMPANY_CODES
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias getRisksCompanyCodesError
   */
  getRisksCompanyCodesError: () => ({
    type: actions.GET_RISKS_COMPANY_CODES_ERROR
  }),

  /**
   * Provide the list of company codes to the reducer
   * @param {Array} companyCodes - The list of company codes
   * @memberof actions
   * @alias getRisksCompanyCodesSuccess
   */
  getRisksCompanyCodesSuccess: (payload) => ({
    type: actions.GET_RISKS_COMPANY_CODES_SUCCESS,
    payload
  }),

  /**
   * @memberof actions
   * @alias getRisksEconomicUnits
   */
  getRisksEconomicUnits: () => ({
    type: actions.GET_RISKS_ECONOMIC_UNITS
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias getRisksEconomicUnitsError
   */
  getRisksEconomicUnitsError: () => ({
    type: actions.GET_RISKS_ECONOMIC_UNITS_ERROR
  }),

  /**
   * Provide the list of economic units to the reducer
   * @param {Array} companyCodes - The list of economic units
   * @memberof actions
   * @alias getRisksEconomicUnitsSuccess
   */
  getRisksEconomicUnitsSuccess: (payload) => ({
    type: actions.GET_RISKS_ECONOMIC_UNITS_SUCCESS,
    payload
  }),

  /**
   * Action used for fetching the housing stocks used in the risks filters.
   * @memberof actions
   * @alias getRisksHousingStocks
   */
  getRisksHousingStocks: () => ({
    type: actions.GET_RISKS_HOUSING_STOCKS
  }),

  getRisksHousingStocksError: () => ({
    type: actions.GET_RISKS_HOUSING_STOCKS_ERROR
  }),

  /**
   * Provides the list of housing stocks used as options in the risks filters to the reducer.
   * @param {Array} payload - The list of housing stocks
   * @memberof actions
   * @alias getRisksHousingStocksSuccess
   */
  getRisksHousingStocksSuccess: (payload) => ({
    type: actions.GET_RISKS_HOUSING_STOCKS_SUCCESS,
    payload
  }),

  clearSingleRisk: () => ({
    type: actions.CLEAR_SINGLE_RISK
  }),

  /**
   * Using the normal loadPolicies API call for fetching policies
   * */
  getConnectedPolicies: (riskId) => ({
    type: actions.GET_CONNECTED_POLICIES,
    riskId
  }),

  getConnectedPoliciesError: () => ({
    type: actions.GET_CONNECTED_POLICIES_ERROR
  }),

  getConnectedPoliciesSuccess: (payload) => ({
    type: actions.GET_CONNECTED_POLICIES_SUCCESS,
    payload
  }),

  clearConnectedPolicies: () => ({
    type: actions.CLEAR_CONNECTED_POLICIES
  }),

  setlastVisitedRiskId: (riskId) => ({
    type: actions.SET_LAST_VISITED_RISK_ID,
    riskId
  }),

  resetLastVisitedRiskId: () => ({
    type: actions.RESET_LAST_VISITED_RISK_ID
  })
};

export default actions;
