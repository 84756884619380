import axios from '../../config/axios';
import apiUrls from '../apiUrls';

/**
 * Creates new risks.
 * @param {Array} risks - Array of risks
 * @returns {Array} Array of the risks created
 */
const onCreateRisks = async (risks) =>
  axios
    .post(apiUrls.risksBaseUrl, risks)
    .then((res) => res)
    .catch((error) => error);

/**
 * Updates existing risks.
 * @param {Array} risks - Array of risks
 * @returns {Array} updated risks
 */
const onUpdateRisks = async (risks) =>
  axios
    .put(apiUrls.risksBaseUrl, risks)
    .then((res) => res)
    .catch((error) => error);

/**
 * Returns an array of risks that match the filter parameters.
 * @param {Object} params - filter parameters to be used to filter the list of risks
 * @returns {Array} Array of risks
 */
const onGetRisks = async (params) =>
  axios
    .get(apiUrls.risksBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onExportRisks = async (risksIds) =>
  axios
    .post(`${apiUrls.risksBaseUrl}/create-export-file`, risksIds, { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);

/**
 * Expects a risk id and returns a risk object.
 * @param {string} riskId - Risk id
 * @returns {object} Risk object
 */
const onGetRisk = async (riskId) =>
  axios
    .get(`${apiUrls.risksBaseUrl}/${riskId}`)
    .then((res) => res)
    .catch((error) => error);

const onDeleteRisks = async (params) =>
  axios
    .post(`${apiUrls.risksBaseUrl}/delete-many`, params)
    .then((res) => res)
    .catch((error) => error);

const onGetRisksCompanyCodes = async () =>
  axios
    .get(`${apiUrls.risksBaseUrl}/company-codes`)
    .then((res) => res)
    .catch((error) => error);

const onGetRisksEconomicUnits = async () =>
  axios
    .get(`${apiUrls.risksBaseUrl}/economic-units`)
    .then((res) => res)
    .catch((error) => error);

const onGetRisksHousingStocks = async () =>
  axios
    .get(`${apiUrls.risksBaseUrl}/housing-stocks`)
    .then((res) => res)
    .catch((error) => error);

export {
  onCreateRisks,
  onUpdateRisks,
  onGetRisks,
  onGetRisk,
  onExportRisks,
  onGetRisksHousingStocks,
  onGetRisksCompanyCodes,
  onGetRisksEconomicUnits,
  onDeleteRisks
};
