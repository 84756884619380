import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { notification } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import * as Sentry from '@sentry/react';
import { sentryCaptureValidationException } from '../../config/sentry';
import { Form, Input, InputPassword, Button } from '../../components/ui';
import renderNotification from '../../helpers/notifications/renderNotification';
import authAction from '../../redux/auth/actions';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { findMetaDataDescriptionByProperty } from '../../helpers/utility';
import { DEFAULT_RULES } from '../../consts/formRules';
import {
  LoginContentWrapper,
  LoginFormBackground,
  LoginFormWrapper,
  LoginForgotPasswordWrapper,
  LoginButtonWrapper,
  LoginWelcomMessage
} from './Login.style';
import ForgotPassword from './ForgotPassword';

/**
 * Functional component for the login process.
 * Allows users to log in with their email and password, and provides a link to reset password.
 * @property {Boolean} isLoggedIn - boolean indicating if the user is logged in.
 * @property {Boolean} loginLoading - boolean indicating if the login process is loading.
 * @property {Boolean} loginError - boolean indicating if there was an error during login.
 * @property {Function} login - function to trigger the login process.
 * @property {Object} user - the current logged-in user information.
 */
const Login = ({ isLoggedIn, loginLoading, loginError, login, user }) => {
  // Set user data for Sentry, once its available
  useEffect(() => {
    if (user) {
      const { id, role, organization_id } = user;
      const organization_type = findMetaDataDescriptionByProperty(user, 'organization_type', '-', 'description');
      Sentry.setUser({ id, role, organization_id, organization_type });
    }
  }, [user]);

  const [form] = Form.useForm();
  const { validateFields } = form;

  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);

  // Remove notifications related to forgot password on login
  const removeForgotPassWordNotificationsOnLogin = () => {
    notification.destroy('notification-forgot-password');
  };

  // Handle form submission and trigger login process
  const handleLogin = () => {
    validateFields()
      .then((values) => {
        if (loginLoading) {
          return;
        }
        login(values.username, values.password, removeForgotPassWordNotificationsOnLogin);
      })
      .catch((error) => {
        sentryCaptureValidationException(error);
        const { input_is_not_valid_email } = NOTIFICATION_MESSAGES;
        const { username, password } = error?.values;
        if (username && password) {
          return renderNotification({ type: 'error', message: input_is_not_valid_email });
        }
      });
  };

  // Toggle the forgot password modal visibility
  const handleToggleForgotPassword = () => setForgotPasswordVisible(!forgotPasswordVisible);

  // this is used to login if you press enter inside the password field
  // some users requested this feature
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const location = useLocation(); // Use useLocation to get the location object
  const { from } = location.state || { from: { pathname: '/' } };
  const isLoginSuccess = !loginLoading && isLoggedIn && !loginError;

  return isLoginSuccess ? (
    <Navigate to={from} replace />
  ) : (
    <LoginContentWrapper>
      <LoginWelcomMessage>
        <span>Willkommen zu Ihrem Versicherungsportal.</span>
      </LoginWelcomMessage>
      <LoginFormBackground>
        <LoginFormWrapper>
          <Form form={form} requiredMark="optional">
            <Form.Item
              name="username"
              label="E-Mail"
              className="login-form-item"
              validateTrigger="onSubmit"
              rules={DEFAULT_RULES.EMAIL}
            >
              <Input $hasErrors={loginError} autoComplete="email" size="large" />
            </Form.Item>
            <Form.Item label="Passwort" name="password" rules={DEFAULT_RULES.INPUT_REQUIRED}>
              <InputPassword $hasErrors={loginError} onKeyDown={handleKeyDown} />
            </Form.Item>
            <LoginForgotPasswordWrapper>
              <Button onClick={handleToggleForgotPassword} type="link">
                Passwort vergessen?
              </Button>
            </LoginForgotPasswordWrapper>
            <FormItem className="item-no-margin">
              <LoginButtonWrapper>
                <Button onClick={handleLogin} block>
                  Anmelden
                </Button>
              </LoginButtonWrapper>
            </FormItem>
          </Form>
        </LoginFormWrapper>
      </LoginFormBackground>
      {forgotPasswordVisible && (
        <ForgotPassword
          forgotPasswordVisible={forgotPasswordVisible}
          cancelForgotPassword={handleToggleForgotPassword}
        />
      )}
    </LoginContentWrapper>
  );
};

const { login } = authAction;

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.get('isLoggedIn'),
  loginLoading: state.Auth.get('loginLoading'),
  loginError: state.Auth.get('loginError'),
  user: state.Auth.get('user')
});

export default connect(mapStateToProps, { login })(Login);
