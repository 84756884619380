import styled from 'styled-components';

const ContentWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
`;

const NavigationAddButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-right: 10px;
`;

const ListWrapper = styled.div`
  border-radius: var(--border-radius-regular);
  overflow: hidden;
`;

const ListHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
`;

const ListHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
`;

const ListHeaderButtonWrapper = styled.div`
  display: flex;
`;

const ListItemNameLabelWrapper = styled.span`
  display: block;
  flex: 1;
  margin-right: 20px;
  max-width: 100%;
  min-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListItemNameLabel = styled.span`
  font-weight: var(--font-weight-medium);
`;

const ListItemLabel = styled.span`
  display: flex;
  flex: 1;
  font-weight: var(--font-weight-regular);
  margin-right: 20px;
  min-width: 200px;
`;

export const HousingStocksLayout = {
  ContentWrapper,
  NavigationAddButtonWrapper,
  ListWrapper,
  ListHeadingWrapper,
  ListHeader,
  ListHeaderButtonWrapper,
  ListItemNameLabelWrapper,
  ListItemNameLabel,
  ListItemLabel
};
