import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onUploadFile = async (file, file_container_id) => {
  const formData = new FormData();

  // Append the file container ID and the file to the FormData object
  // formData.append('file_container_id', file_container_id);
  formData.append('file', file);
  const param = file_container_id ? `?file_container_id=${file_container_id}` : '';

  // Make the POST request with the FormData object
  try {
    const response = await axios
      .post(`${apiUrls.fileUploadBaseUrl}${param}`, formData)
      .then((res) => res)
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
};

export { onUploadFile };
