const actions = {
  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_BY_ORGANIZATION: 'LOAD_USERS_BY_ORGANIZATION',
  LOAD_USERS_BY_ORGANIZATION_ERROR: 'LOAD_USERS_BY_ORGANIZATION_ERROR',
  LOAD_USERS_BY_ORGANIZATION_SUCCESS: 'LOAD_USERS_BY_ORGANIZATION_SUCCESS',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_ERROR: 'CHANGE_USER_PASSWORD_ERROR',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  UNSELECT_USER: 'UNSELECT_USER',
  GET_USER_ROLES: 'GET_USER_ROLES',
  GET_USER_ROLES_ERROR: 'GET_USER_ROLES_ERROR',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  CLEAR_USER_ERRORS: 'CLEAR_USER_ERRORS',
  CONFIRM_OPT_IN: 'CONFIRM_OPT_IN',
  CONFIRM_OPT_IN_ERROR: 'CONFIRM_OPT_IN_ERROR',
  CONFIRM_OPT_IN_SUCCESS: 'CONFIRM_OPT_IN_SUCCESS',
  TOGGLE_USER_CHANGE_PASSWORD_MODAL: 'TOGGLE_USER_CHANGE_PASSWORD_MODAL',
  LOAD_USER_INTERCOM_HMAC: 'LOAD_USER_INTERCOM_HMAC',
  LOAD_USER_INTERCOM_HMAC_SUCCESS: 'LOAD_USER_INTERCOM_HMAC_SUCCESS',
  LOAD_USER_INTERCOM_HMAC_ERROR: 'LOAD_USER_INTERCOM_HMAC_ERROR',
  toggleChangeUserPasswordModal: () => ({
    type: actions.TOGGLE_USER_CHANGE_PASSWORD_MODAL
  }),
  getUsers: () => ({
    type: actions.LOAD_USERS
  }),
  getUsersSuccess: (users) => ({
    type: actions.LOAD_USERS_SUCCESS,
    users
  }),
  getUsersError: () => ({
    type: actions.LOAD_USERS_ERROR
  }),
  getUsersByOrganization: (organizationId) => ({
    type: actions.LOAD_USERS_BY_ORGANIZATION,
    organizationId
  }),
  getUsersByOrganizationSuccess: (users) => ({
    type: actions.LOAD_USERS_BY_ORGANIZATION_SUCCESS,
    users
  }),
  getUsersByOrganizationError: () => ({
    type: actions.LOAD_USERS_BY_ORGANIZATION_ERROR
  }),
  createUser: (user, callback) => ({
    type: actions.CREATE_USER,
    payload: { user },
    callback
  }),
  createUserSuccess: () => ({
    type: actions.CREATE_USER_SUCCESS
  }),
  createUserError: () => ({
    type: actions.CREATE_USER_ERROR
  }),
  updateUser: (userId, params, callback) => ({
    type: actions.UPDATE_USER,
    userId,
    params,
    callback
  }),
  updateUserSuccess: () => ({
    type: actions.UPDATE_USER_SUCCESS
  }),
  updateUserError: () => ({
    type: actions.UPDATE_USER_ERROR
  }),
  changeUserPassword: (currentPassword, newPassword, userId) => ({
    type: actions.CHANGE_USER_PASSWORD,
    currentPassword,
    newPassword,
    userId
  }),
  changeUserPasswordSuccess: () => ({
    type: actions.CHANGE_USER_PASSWORD_SUCCESS
  }),
  changeUserPasswordError: () => ({
    type: actions.CHANGE_USER_PASSWORD_ERROR
  }),
  deleteUser: (userId) => ({
    type: actions.DELETE_USER,
    userId
  }),
  deleteUserSuccess: () => ({
    type: actions.DELETE_USER_SUCCESS
  }),
  deleteUserError: () => ({
    type: actions.DELETE_USER_ERROR
  }),
  getUserRoles: (organisationType) => ({
    type: actions.GET_USER_ROLES,
    organisationType
  }),
  getUserRolesSuccess: (userRoles) => ({
    type: actions.GET_USER_ROLES_SUCCESS,
    userRoles
  }),
  getUserRolesError: () => ({
    type: actions.GET_USER_ROLES_ERROR
  }),
  clearUserErrors: () => ({
    type: actions.CLEAR_USER_ERRORS
  }),
  confirmOptIn: (userId) => ({
    type: actions.CONFIRM_OPT_IN,
    userId
  }),
  confirmOptInSuccess: () => ({
    type: actions.CONFIRM_OPT_IN_SUCCESS
  }),
  confirmOptInError: () => ({
    type: actions.CONFIRM_OPT_IN_ERROR
  }),
  loadUserIntercomHmac: (userId) => ({
    type: actions.LOAD_USER_INTERCOM_HMAC,
    userId
  }),
  loadUserIntercomHmacSuccess: () => ({
    type: actions.LOAD_USER_INTERCOM_HMAC_SUCCESS
  }),
  loadUserIntercomHmacError: () => ({
    type: actions.LOAD_USER_INTERCOM_HMAC_ERROR
  })
};

export default actions;
