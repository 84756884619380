import React from 'react';

const IconCurrencyEuro = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12.064v-1.235h1.482a12.152 12.152 0 0 1-.019-1.634H4V7.96h1.634c.279-1.419.861-2.533 1.748-3.344.9-.81 1.989-1.216 3.268-1.216 1.153 0 2.103.253 2.85.76.747.494 1.292 1.216 1.634 2.166l-1.368.646c-.215-.722-.576-1.273-1.083-1.653-.494-.393-1.127-.589-1.9-.589-.887 0-1.647.285-2.28.855-.633.57-1.077 1.362-1.33 2.375h4.522l-.38 1.235H6.983a11.692 11.692 0 0 0 .019 1.634h3.933l-.38 1.235H7.23c.253.925.678 1.647 1.273 2.166.595.507 1.355.76 2.28.76.798 0 1.47-.215 2.014-.646.557-.443.95-1.09 1.178-1.938l1.387.513c-.355 1.077-.931 1.913-1.729 2.508s-1.767.893-2.907.893c-1.355 0-2.464-.374-3.325-1.121-.861-.76-1.438-1.805-1.729-3.135H4z"
      fill="#111"
    />
  </svg>
);

export default IconCurrencyEuro;
