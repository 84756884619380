import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadUserRights = async (userId) =>
  axios
    .get(`${apiUrls.usersBaseUrl}/${userId}/ui-rights`)
    .then((res) => res)
    .catch((error) => error);

const onLoginReqeust = async (username, password) =>
  axios
    .post(apiUrls.authBaseUrl, {
      email: username,
      password
    })
    .then((res) => res)
    .catch((error) => error);

const onLogoutRequest = async () => axios.delete(apiUrls.authBaseUrl).then((res) => res);

const onValidateUser = async () =>
  axios
    .get(`${apiUrls.authBaseUrl}/validate`)
    .then((res) => res)
    .catch((error) => error);

const onResetPassword = async (token, newPassword) =>
  axios
    .patch(`${apiUrls.passwordUrl}/reset`, { token, new_password: newPassword })
    .then((res) => res)
    .catch((err) => err);

const onForgotPassword = async (email) =>
  axios
    .post(`${apiUrls.passwordUrl}/request-reset`, { email })
    .then((res) => res)
    .catch((err) => err);

export { onLoadUserRights, onLoginReqeust, onLogoutRequest, onValidateUser, onResetPassword, onForgotPassword };
