import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onAddClaimAuthorizedOrganisations = async (organizationsToAuthorize) =>
  axios
    .post(apiUrls.claimAuthorizationsBaseUrl, organizationsToAuthorize)
    .then((res) => res)
    .catch((error) => error);

const onDeleteClaimAuthorizedOrganisations = async (organizationsToDelete) =>
  axios
    .post(`${apiUrls.claimAuthorizationsBaseUrl}/delete-many`, organizationsToDelete)
    .then((res) => res)
    .catch((error) => error);

export { onAddClaimAuthorizedOrganisations, onDeleteClaimAuthorizedOrganisations };
