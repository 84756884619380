const actions = {
  GET_CUSTOMER_PORTAL_AVAILIBILITY: 'GET_CUSTOMER_PORTAL_AVAILIBILITY',
  GET_CUSTOMER_PORTAL_AVAILIBILITY_SUCCESS: 'GET_CUSTOMER_PORTAL_AVAILIBILITY_SUCCESS',
  GET_CUSTOMER_PORTAL_AVAILIBILITY_ERROR: 'GET_CUSTOMER_PORTAL_AVAILIBILITY_ERROR',
  POST_CUSTOMER_PORTAL_SESSION: 'POST_CUSTOMER_PORTAL_SESSION',
  POST_CUSTOMER_PORTAL_SESSION_SUCCESS: 'POST_CUSTOMER_PORTAL_SESSION_SUCCESS',
  POST_CUSTOMER_PORTAL_SESSION_ERROR: 'POST_CUSTOMER_PORTAL_SESSION_ERROR',

  getCustomerPortalAvailability: () => ({
    type: actions.GET_CUSTOMER_PORTAL_AVAILIBILITY
  }),
  getCustomerPortalAvailabilitySuccess: (customerPortalAvailability) => ({
    type: actions.GET_CUSTOMER_PORTAL_AVAILIBILITY_SUCCESS,
    customerPortalAvailability
  }),
  getCustomerPortalAvailabilityError: () => ({
    type: actions.GET_CUSTOMER_PORTAL_AVAILIBILITY_ERROR
  }),
  postCustomerPortalSession: () => ({
    type: actions.POST_CUSTOMER_PORTAL_SESSION
  }),
  postCustomerPortalSessionSuccess: (customerPortalSession) => ({
    type: actions.POST_CUSTOMER_PORTAL_SESSION_SUCCESS,
    customerPortalSession
  }),
  postCustomerPortalSessionError: () => ({
    type: actions.POST_CUSTOMER_PORTAL_SESSION_ERROR
  })
};

export default actions;
