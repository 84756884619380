import styled from 'styled-components';
import { windowMaxWidth } from './breakpoints';

const EmptyTableWrapper = styled.div`
  height: ${(props) => (props.$isClaimsList ? 'calc(100vh - 303px)' : 'calc(100vh - 343px)')};
  overflow: hidden;
  width: 100%;

  @media ${windowMaxWidth.splitScreen} {
    height: ${(props) => (props.$isClaimsList ? 'calc(100vh - 303px)' : 'calc(100vh - 354px)')};
  }
`;

const EmptyListWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  @media ${windowMaxWidth.mobile} {
    min-height: 50dvh;
  }
`;

const EmptyAdministrationTableWrapper = styled.div`
  height: calc(100vh - 411px);
  width: 100%;

  @media ${windowMaxWidth.splitScreen} {
    height: calc(100vh - 411px);
  }

  @media (height <= 700px) {
    height: calc(100vh - 411px);
    min-height: 200px;
  }
`;

const NoContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1 360px;
  height: 100%;
  justify-content: center;
`;

const NoContentContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1 360px;
  flex-direction: column;
  justify-content: center;

  svg {
    margin-bottom: 20px;
  }
`;

export const EmptyStatesLayout = {
  NoContentWrapper,
  NoContentContentWrapper,
  EmptyTableWrapper,
  EmptyListWrapper,
  EmptyAdministrationTableWrapper
};
