import React from 'react';

const IconInbox = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 14V10L14.8594 4.50386C14.6813 4.19229 14.35 4 13.9911 4H6.00889C5.65004 4 5.31869 4.19229 5.14065 4.50386L2 10V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14Z"
      stroke="#111111"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M18 10L12.5 10C12.5 11.5 11.5 12.5 10 12.5C8.5 12.5 7.5 11.5 7.5 10L2 10"
      stroke="#111111"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconInbox;
