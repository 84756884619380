import React from 'react';
import dayjs from 'dayjs';
import { DatePicker } from './DatePicker';

// this will disable all future dates
const getDisabledDates = (current) => {
  return current && current > dayjs().endOf('day');
};

const DatePickerFormInput = ({ handleChangeDate, value }) => (
  <DatePicker value={value} disabledDate={(current) => getDisabledDates(current)} onChange={handleChangeDate} />
);

DatePickerFormInput.displayName = 'DatePickerFormInput';

export { DatePickerFormInput };
