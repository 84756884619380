const actions = {
  LOAD_KPIS: 'LOAD_KPIS',
  LOAD_KPIS_SUCCESS: 'LOAD_KPIS_SUCCESS',
  LOAD_KPIS_ERROR: 'LOAD_KPIS_ERROR',
  LOAD_PROFITABILITY_KPIS: 'LOAD_PROFITABILITY_KPIS',
  LOAD_PROFITABILITY_KPIS_SUCCESS: 'LOAD_PROFITABILITY_KPIS_SUCCESS',
  LOAD_PROFITABILITY_KPIS_ERROR: 'LOAD_PROFITABILITY_KPIS_ERROR',
  GET_PREMIUMS: 'GET_PREMIUMS',
  GET_PREMIUMS_SUCCESS: 'GET_PREMIUMS_SUCCESS',
  GET_PREMIUMS_ERROR: 'GET_PREMIUMS_ERROR',
  SET_PREMIUM_KPIS: 'SET_PREMIUM_KPIS',
  SET_PREMIUM_KPIS_SUCCESS: 'SET_PREMIUM_KPIS_SUCCESS',
  SET_PREMIUM_KPIS_ERROR: 'SET_PREMIUM_KPIS_ERROR',
  CLEAR_SET_PREMIUM_KPIS_ERROR: 'CLEAR_SET_PREMIUM_KPIS_ERROR',
  SET_SHOW_PREMIUMS_MODAL: 'SET_SHOW_PREMIUMS_MODAL',
  SET_HIDE_PREMIUMS_MODAL: 'SET_HIDE_PREMIUMS_MODAL',
  getKpis: (params) => ({
    type: actions.LOAD_KPIS,
    params
  }),
  getKpisSuccess: (kpis) => ({
    type: actions.LOAD_KPIS_SUCCESS,
    kpis
  }),
  getKpisError: () => ({
    type: actions.LOAD_KPIS_ERROR
  }),
  getProfitabilityKpis: (params) => ({
    type: actions.LOAD_PROFITABILITY_KPIS,
    params
  }),
  getProfitabilityKpisSuccess: (kpis) => ({
    type: actions.LOAD_PROFITABILITY_KPIS_SUCCESS,
    kpis
  }),
  getProfitabilityKpisError: () => ({
    type: actions.LOAD_PROFITABILITY_KPIS_ERROR
  }),
  getPremiums: () => ({
    type: actions.GET_PREMIUMS
  }),
  getPremiumsSuccess: (premiums) => ({
    type: actions.GET_PREMIUMS_SUCCESS,
    premiums
  }),
  getPremiumsError: () => ({
    type: actions.GET_PREMIUMS_ERROR
  }),
  setPremiumKpis: (params, profitabilityFilterParams) => ({
    type: actions.SET_PREMIUM_KPIS,
    params,
    profitabilityFilterParams
  }),
  setPremiumKpisSuccess: (kpis) => ({
    type: actions.SET_PREMIUM_KPIS_SUCCESS,
    kpis
  }),
  setPremiumKpisError: () => ({
    type: actions.SET_PREMIUM_KPIS_ERROR
  }),
  clearSetPremiumKpisError: () => ({
    type: actions.CLEAR_SET_PREMIUM_KPIS_ERROR
  }),
  setShowPremiumsModal: () => ({
    type: actions.SET_SHOW_PREMIUMS_MODAL
  }),
  setHidePremiumsModal: () => ({
    type: actions.SET_HIDE_PREMIUMS_MODAL
  })
};
export default actions;
