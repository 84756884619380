const actions = {
  GET_ALL_FILE_CONTAINERS: 'GET_ALL_FILE_CONTAINERS',
  GET_ALL_FILE_CONTAINERS_SUCCESS: 'GET_ALL_FILE_CONTAINERS_SUCCESS',
  GET_ALL_FILE_CONTAINERS_ERROR: 'GET_ALL_FILE_CONTAINERS_ERROR',
  GET_INSURED_RISK_FILE_CONTAINERS: 'GET_INSURED_RISK_FILE_CONTAINERS',
  GET_INSURED_RISK_FILE_CONTAINERS_SUCCESS: 'GET_INSURED_RISK_FILE_CONTAINERS_SUCCESS',
  GET_INSURED_RISK_FILE_CONTAINERS_ERROR: 'GET_INSURED_RISK_FILE_CONTAINERS_ERROR',
  CLEAR_INSURED_RISK_FILE_CONTAINERS: 'CLEAR_INSURED_RISK_FILE_CONTAINERS',

  getAllFileContainers: () => ({
    type: actions.GET_ALL_FILE_CONTAINERS
  }),
  getAllFileContainersSuccess: (fileContainers) => ({
    type: actions.GET_ALL_FILE_CONTAINERS_SUCCESS,
    fileContainers
  }),
  getAllFileContainersError: () => ({
    type: actions.GET_ALL_FILE_CONTAINERS_ERROR
  }),
  getInsuredRiskFileContainers: (insuredRiskId) => ({
    type: actions.GET_INSURED_RISK_FILE_CONTAINERS,
    insuredRiskId
  }),
  getInsuredRiskFileContainersSuccess: (fileContainers) => ({
    type: actions.GET_INSURED_RISK_FILE_CONTAINERS_SUCCESS,
    fileContainers
  }),
  getInsuredRiskFileContainersError: () => ({
    type: actions.GET_INSURED_RISK_FILE_CONTAINERS_ERROR
  }),
  clearInsuredRiskFileContainers: () => ({
    type: actions.CLEAR_INSURED_RISK_FILE_CONTAINERS
  })
};

export default actions;
