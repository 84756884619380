import styled from 'styled-components';
import { IconChevronLeft, IconFilter } from '../Icons';
import { windowMaxWidth } from './breakpoints';

// helper function that returns the margin for the action buttons wrapper
const adjustMarginRight = ({ $showSidebar, $windowWidth }) => {
  const CONTENT_MAX_SIZE = 1800;
  const SIDEBAR_WIDTH = 600;
  const DEFAULT_MARGIN = 0;
  const SMALL_WINDOW_MAX_SIZE = 1420;
  const MEDIUM_WINDOW_MAX_SIZE = 1840;
  const REDUCED_MARGIN = 20;

  if ($showSidebar) {
    if ($windowWidth <= SMALL_WINDOW_MAX_SIZE) {
      return `${SIDEBAR_WIDTH}px`;
    }

    if ($windowWidth <= MEDIUM_WINDOW_MAX_SIZE) {
      return `${SIDEBAR_WIDTH - REDUCED_MARGIN}px`;
    }

    const margin = Math.ceil(SIDEBAR_WIDTH - ($windowWidth - CONTENT_MAX_SIZE) / 2);
    if (margin > 0) {
      return `${margin}px`;
    }
  }

  return `${DEFAULT_MARGIN}px`;
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 20px;

  @media ${windowMaxWidth.splitScreen} {
    overflow: hidden;
    margin: 0 10px;
  }

  @media ${windowMaxWidth.mobile} {
    overflow-x: hidden;
    margin: 0;
    height: auto;
  }
`;

const PageHeader = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 100px;
  height: 100px;
  justify-content: space-between;
  padding: 0 20px;

  @media ${windowMaxWidth.mobile} {
    height: 60px;
    flex: 0 0 60px;
    padding: 15px 20px;
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: var(--secondary-whisper);

    h2 {
      flex: 1 0 auto;
    }
  }
`;

const HeaderActionButtons = styled.div`
  display: flex;
  margin-right: ${({ $showSidebar, $windowWidth }) => adjustMarginRight({ $showSidebar, $windowWidth })};
  transition: all 0.5s;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 181px);
  max-height: 1645px;
  min-height: 0;

  @media ${windowMaxWidth.splitScreen} {
    flex-direction: column;
  }

  @media ${windowMaxWidth.mobile} {
    min-height: calc(100% - 190px);
    max-height: unset;
    margin-top: 60px;
  }
`;

const MainContentWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  flex: 1 1 auto;
  margin-left: 20px;
  min-height: 0;
  min-width: 850px;
  overflow: hidden;

  @media (${windowMaxWidth.splitScreen}) {
    margin: 0;
    min-width: calc(100vw - 40px);
  }

  @media (${windowMaxWidth.mobile}) {
    border-radius: unset;
    border: none;
  }
`;

const MainContentHeaderRow = styled.div`
  align-items: center;
  border-bottom: solid 1px var(--greyscale-dark-white);
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;

  @media (${windowMaxWidth.mobile}) {
    padding: 15px;
  }
`;

const MainContentHeaderOptionsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const MainContentHeaderFilterButton = styled.div`
  display: none;

  @media ${windowMaxWidth.splitScreen} {
    display: flex;
    margin-right: 10px;
    position: relative;
  }
`;

const TableWrapper = styled.div`
  height: calc(100% - 71px);
  overflow: auto;
  scrollbar-width: thin !important;

  .ant-table-empty {
    overflow: hidden;

    .ant-table-thead {
      display: none;
    }

    colgroup {
      display: none;
    }

    td {
      border: none;
      padding: 0;
    }
  }
`;

const MapWrapper = styled.div`
  height: calc(100% - 71px);
  overflow: hidden;
  position: relative;

  .google-map-claims-overview {
    border-radius: 0 0 var(--border-radius-small);
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 0;

    & > div {
      border-radius: 0 0 var(--border-radius-small);

      .gm-style > div {
        border: none !important;
      }
    }
  }
`;

const ListWrapper = styled.div`
  height: calc(100% - 71px);
  overflow: auto;
  scrollbar-width: thin !important;

  @media ${windowMaxWidth.splitScreen} {
    height: calc(100dvh - 302px);
  }

  @media ${windowMaxWidth.mobile} {
    height: auto;
    min-height: calc(100dvh - 251px);
  }
`;

const ListLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const LeftColumnContainer = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  min-width: 260px;
  width: 260px;

  @media ${windowMaxWidth.splitScreen} {
    display: none;
  }
`;

const ActionExtrasButton = styled.button`
  align-items: center;
  background: transparent;
  border: 2px solid var(--primary-oqio-blue);
  border-radius: var(--border-radius-small);
  display: flex;
  font-size: var(--font-size-heading-3);
  height: 40px;
  justify-content: space-around;
  margin: 0 0 0 2px;
  transition: background-color 0.1s;
  width: 40px;

  svg * {
    fill: var(--primary-oqio-blue);

    rect {
      fill: var(--primary-oqio-blue);
    }
  }

  @media ${windowMaxWidth.splitScreen} {
    margin: 0 0 0 2px;
  }

  &:hover {
    background-color: var(--primary-oqio-blue-hover-transparent);
    cursor: pointer;
  }

  @media (${windowMaxWidth.mobile}) {
    display: ${(props) => (props.$hideOnMobile ? 'none' : 'flex')};
  }
`;

const WorklistButtonWrapper = styled.div`
  display: flex;
  margin-left: 10px;

  button {
    background: transparent;
    position: relative;

    .items-count {
      position: absolute;
      right: -10px;
      top: -10px;
    }

    &:hover {
      background-color: var(--primary-oqio-blue-hover-transparent);
    }
  }
`;

const PaginationContentWrapper = styled.div`
  display: flex;
  flex: 0 0 70px;

  @media (${windowMaxWidth.mobile}) {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--secondary-whisper);
  }
`;

const PaginationLeftColumnPlaceHolder = styled.div`
  min-width: 260px;
  width: 260px;

  @media (${windowMaxWidth.splitScreen}) {
    display: none;
  }
`;

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  min-width: 850px;
  padding: 20px 30px;
  width: calc(100vw - 100px);

  @media (${windowMaxWidth.splitScreen}) {
    margin: 0;
    min-width: 100%;
    padding: 15px 20px;
  }

  @media (${windowMaxWidth.mobile}) {
    padding: 15px 20px;
  }
`;

const PaginationTextWrapper = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-line-height-regular);
`;

const PaginationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PaginationButton = styled.button`
  align-items: center;
  background-color: ${(props) => (props.disabled ? 'var(--greyscale-porcelain)' : 'var(--greyscale-white)')};
  border: solid 1px var(--greyscale-dark-white);
  border-radius: ${(props) => (props.$left ? '5px 0 0 5px' : '0 5px 5px 0')};
  border-right: ${(props) => props.$left && 'none'};
  color: ${(props) => (props.disabled ? 'var(--greyscale-concrete)' : 'var(--greyscale-blackish)')};
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 0;
  width: 40px;

  svg {
    path {
      stroke: ${(props) => (props.disabled ? 'var(--greyscale-concrete)' : 'var(--greyscale-blackish)')};
    }
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }
`;

/**
 * Object containing styled-components for the layout of our landing pages.
 * @type {Object}
 * @property {Component} PageHeader - Styled-component for the header of the page.
 * @property {Component} HeaderActionButtons - Styled-component for the action buttons in the header.
 * @property {Component} ContentWrapper - Styled-component for the wrapper of of left column and main content.
 * @property {Component} MainContentWrapper - Styled-component for the content wrapper of the page.
 * @property {Component} MainContentHeader - Styled-component for the header of the content.
 * @property {Component} MainContentHeaderOptionsWrapper - Styled-component for the options wrapper of the content header.
 * @property {Component} TableWrapper - Styled-component for the wrapper of the table.
 * @property {Component} LeftColumnContainer - Styled-component for the left column of the page.
 * @property {Component} PaginationContentWrapper - Styled-component for the pagination wrapper of the page.
 * @property {Component} PaginationLeftColumnPlaceHolder - Styled-component for the left column placeholder of the pagination.
 * @property {Component} PaginationWrapper - Styled-component for the pagination wrapper of the page.
 * @property {Component} PaginationTextWrapper - Styled-component for the text wrapper of the pagination.
 * @property {Component} PaginationButtonsWrapper - Styled-component for the buttons wrapper of the pagination.
 * @property {Component} PaginationButton - Styled-component for the buttons of the pagination.
 */
const MainLayout = {
  MainWrapper,
  Header: PageHeader,
  ActionButtons: HeaderActionButtons,
  ActionExtrasButton,
  WorklistButtonWrapper,
  ContentWrapper,
  MainContentWrapper,
  MainContentHeaderRow,
  MainContentHeaderOptionsWrapper,
  MainContentHeaderFilterButton,
  IconChevronLeft,
  IconFilter,
  TableWrapper,
  MapWrapper,
  ListWrapper,
  ListLoadingWrapper,
  LeftColumnContainer,
  PaginationLeftColumnPlaceHolder,
  PaginationContentWrapper,
  PaginationWrapper,
  PaginationTextWrapper,
  PaginationButtonsWrapper,
  PaginationButton
};

export default MainLayout;
