import React, { useState } from 'react';
import AntInputNumber from 'antd/es/input-number';
import styled from 'styled-components';

const StyledInputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    box-shadow: none;
    transition: box-shadow 0.2s;
    width: 100%;

    &.ant-input-number-focused {
      box-shadow: var(--box-shadow-oqio-blue);
    }

    &.ant-input-number-status-error:not(.ant-input-number-disabled) {
      box-shadow: var(--box-shadow-oqio-red);

      &:hover,
      &:focus-within {
        border-color: var(--primary-oqio-blue);
        box-shadow: var(--box-shadow-oqio-blue);
      }
    }
  }

  &.ant-input-number-group-wrapper {
    box-shadow: none;
    transition:
      box-shadow 0.2s,
      border-radius 0.2s;
    width: 100%;

    .ant-input-number,
    .ant-input-number-group-addon {
      background-color: var(--greyscale-porcelain);
      border-color: var(--greyscale-dark-white);
      color: var(--greyscale-concrete);
      cursor: default;

      .ant-input-number-input {
        cursor: default;
      }
    }

    .ant-input-number-group-addon {
      color: var(--greyscale-shade);
      padding: 0 10px;
      border-color: var(--greyscale-concrete);
    }

    &.has-focus {
      border-radius: 6px;
      box-shadow: var(--box-shadow-oqio-blue);

      .ant-input-number-group-addon {
        border-color: var(--primary-oqio-blue);
      }
    }

    .ant-input-number-wrapper {
      .ant-input-number {
        border-right: none;
      }

      &:hover {
        .ant-input-number,
        .ant-input-number-group-addon {
          border-color: var(--primary-oqio-blue);
        }
      }
    }

    &.ant-input-number-group-wrapper-disabled {
      .ant-input-number-wrapper {
        &:hover {
          .ant-input-number,
          .ant-input-number-group-addon {
            border-color: var(--greyscale-dark-white);
          }
        }
      }
    }

    .ant-input-number-affix-wrapper {
      width: 100%;
      border-right: none;
    }
  }

  &.ant-input-number-affix-wrapper {
    width: 100%;
  }
`;

const InputNumber = ({ controls = false, addonAfter = false, inputMode = 'text', ...props }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <StyledInputNumber
      controls={controls}
      addonAfter={addonAfter}
      inputMode={inputMode}
      {...props}
      className={isFocused ? 'has-focus' : ''}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
};

export default InputNumber;
