import styled from 'styled-components';
import { windowMaxWidth } from './breakpoints';

const MainContentWrapper = styled.div`
  border-radius: var(--border-radius-regular);
  flex: 1 1 auto;
  margin-left: 20px;
  min-height: 0;
  min-width: 850px;
  overflow: hidden;

  @media (${windowMaxWidth.splitScreen}) {
    margin: 0;
    min-width: calc(100vw - 40px);
  }

  @media (${windowMaxWidth.mobile}) {
    border-radius: unset;
    border: none;
  }
`;

const ReportingContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

// Section Wrapper
const ReportingSectionWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

// Chart Styles
const SettlementsReportingTypesOverTimeChartWrapper = styled.div`
  position: relative;

  .chart-legend {
    bottom: 26px;
    position: absolute;
    right: 0;

    @media (width <= 1160px) {
      right: 140px;
      bottom: -20px;
      display: flex;
      align-items: center;
      transform: translateX(-50%);
    }

    @media (width <= 768px) {
      right: 0;
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 8px;

        @media (width <= 1160px) {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }

      i {
        margin-right: 8px;
      }

      &.open {
        color: #8294ae;
      }

      &.denied {
        color: #c41a1a;
      }

      &.closed {
        color: #52c41a;
      }
    }
  }
`;

const ReportingLayout = {
  MainContentWrapper,
  ReportingContentWrapper,
  ReportingSectionWrapper,
  SettlementsReportingTypesOverTimeChartWrapper
};

export default ReportingLayout;
