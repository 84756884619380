import { Map } from 'immutable';
import actions from './actions.js';

const initState = new Map({
  allFileContainers: [],
  allFileContainersLoading: false,
  allFileContainersError: false,
  insuredRiskFileContainers: [],
  insuredRiskFileContainersLoading: false,
  insuredRiskFileContainersError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_ALL_FILE_CONTAINERS:
      return state.set('allFileContainersLoading', true).set('allFileContainersError', false);
    case actions.GET_ALL_FILE_CONTAINERS_SUCCESS:
      return state
        .set('allFileContainersLoading', false)
        .set('allFileContainersError', false)
        .set('allFileContainers', action.fileContainers);
    case actions.GET_ALL_FILE_CONTAINERS_ERROR:
      return state.set('allFileContainersError', true).set('allFileContainersLoading', false);
    case actions.GET_INSURED_RISK_FILE_CONTAINERS:
      return state.set('insuredRiskFileContainersLoading', true).set('insuredRiskFileContainersError', false);
    case actions.GET_INSURED_RISK_FILE_CONTAINERS_SUCCESS:
      return state
        .set('insuredRiskFileContainersLoading', false)
        .set('insuredRiskFileContainersError', false)
        .set('insuredRiskFileContainers', action.fileContainers);
    case actions.GET_INSURED_RISK_FILE_CONTAINERS_ERROR:
      return state.set('insuredRiskFileContainersError', true).set('insuredRiskFileContainersLoading', false);
    case actions.CLEAR_INSURED_RISK_FILE_CONTAINERS:
      return state.set('insuredRiskFileContainers', []);
    default:
      return state;
  }
};

export default reducer;
