import AntMenu from 'antd/es/menu';
import styled from 'styled-components';
import { windowMaxWidth } from '../Layout/breakpoints';

export const HorizontalMenu = styled(AntMenu)`
  border-bottom: none;
  width: 100%;

  &.ant-menu-horizontal {
    .ant-menu-item {
      height: 40px;
      display: flex;
      align-items: center;

      &::after {
        border-bottom: none;
        transition: none;
        border-bottom-color: var(--greyscale-blackish) !important;
      }
    }

    .ant-menu-item-active {
      &::after {
        border-bottom: none;
        transition: none;
      }
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      color: var(--greyscale-blackish);
      font-weight: var(--font-weight-medium);

      &::after {
        border-bottom: 2px solid;
      }
    }

    &.ant-menu-overflow {
      height: 39px;
    }

    &.ant-menu-light {
      background: var(--greycale-white);
    }
  }

  @media (${windowMaxWidth.mobile}) {
    display: none !important;
  }
`;
