import React from 'react';
import { Slider as AntSlider } from 'antd';
import styled from 'styled-components';

const StyledSlider = styled(AntSlider)``;

const Slider = (props) => {
  return <StyledSlider step={1} {...props} />;
};

export default Slider;
