import React from 'react';

const IconOqio = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x=".5" y=".5" width="29" height="29" rx="5.5" fill="#ffffff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.659 5.48A7.23 7.23 0 0 0 8.32 9.648c-.626 1.635-.735 3.61-.57 5.585a3.146 3.146 0 0 1 2.907 3.127c0 2.14-2.074 4.433-2.743 5.114h1.712c.329.954.57 1.525.57 1.525s1.174-.56 2.787-1.525h1.734a6.471 6.471 0 0 1-.33-.878 38.302 38.302 0 0 0 1.79-1.251l1.898-1.547c1.635-1.47 3.072-3.16 3.774-4.982 1.405-3.73-.46-7.911-4.191-9.338zm.208 9.655a3.57 3.57 0 0 1-1.492-.318l-1.306 1.789-1.306-1.778a3.79 3.79 0 0 1-1.492.318 3.704 3.704 0 0 1-3.709-3.709 3.711 3.711 0 0 1 6.507-2.435 3.702 3.702 0 0 1 2.798-1.273 3.704 3.704 0 1 1 0 7.406zm1.613-3.709c0 .889-.724 1.613-1.613 1.613a1.616 1.616 0 0 1-1.613-1.613c0-.889.724-1.613 1.613-1.613a1.602 1.602 0 0 1 1.613 1.613zm-7.21 1.613c.89 0 1.614-.724 1.614-1.613 0-.9-.724-1.624-1.613-1.613-.889 0-1.613.724-1.613 1.613s.724 1.613 1.613 1.613z"
      fill="#b3b3b3"
    />
    <rect x=".5" y=".5" width="29" height="29" rx="5.5" stroke="#ffffff" />
  </svg>
);

export default IconOqio;
