import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { onFetchDocument } from './apiCalls';

export function* fetchDocument({ apiUrl }) {
  try {
    const response = yield call(onFetchDocument, apiUrl);
    if (response.data) {
      yield put(actions.fetchDocumentSuccess(response.data));
    } else {
      yield put(actions.fetchDocumentError());
    }
  } catch (error) {
    yield put(actions.fetchDocumentError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_DOCUMENT, fetchDocument)]);
}
