import React from 'react';

const IconDisabledEdit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.5 10.415L2.96278 13.9523L5.43086 16.4203L9 12.8512M12.6698 9.1823L17.1435 4.7092C17.5341 4.31868 17.5341 3.68548 17.1436 3.29494L16.0897 2.24108C15.6992 1.85057 15.0661 1.85055 14.6755 2.24103L10.1931 6.72287"
        stroke="var(--greyscale-blackish)"
        strokeWidth="1.5"
      />
      <path
        d="M2.54748 16.838L2.64272 14.2666L2.89716 14.0106L5.37203 16.4854L5.11619 16.7429L2.54748 16.838Z"
        fill="var(--greyscale-blackish)"
        stroke="var(--greyscale-blackish)"
        strokeWidth="1.5"
      />
      <path d="M11.8848 3.96484L15.4203 7.50038" stroke="var(--greyscale-blackish)" strokeWidth="1.5" />
      <path d="M3 3L17.5 17.5" stroke="var(--greyscale-blackish)" strokeWidth="1.5" />
    </svg>
  );
};
export default IconDisabledEdit;
