import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onGetSettlements = async (params) =>
  axios
    .get(apiUrls.settlementBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onCreateSettlement = async (settlement) =>
  axios
    .post(apiUrls.settlementBaseUrl, settlement)
    .then((res) => res)
    .catch((error) => error);

const onUpdateSettlement = async (settlement) =>
  axios
    .put(`${apiUrls.settlementBaseUrl}/${settlement.id}`, settlement)
    .then((res) => res)
    .catch((error) => error);

const onLoadCutbackReasons = async (params) =>
  axios
    .get(apiUrls.cutbackReasonsBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onCreateCutbackReason = async (reason) =>
  axios
    .post(apiUrls.cutbackReasonsBaseUrl, [{ reason }])
    .then((res) => res)
    .catch((error) => error);

const onDownloadSettlementSummary = async (settlementId) =>
  axios
    .get(`${apiUrls.settlementBaseUrl}/${settlementId}/pdf-summary`, {
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    })
    .then((res) => res)
    .catch((error) => error);
const onExportSettlements = async (params) =>
  axios
    .post(`${apiUrls.performanceBaseUrl}/settlements/create-export-file`, null, { params, responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);
export {
  onCreateSettlement,
  onUpdateSettlement,
  onGetSettlements,
  onLoadCutbackReasons,
  onCreateCutbackReason,
  onDownloadSettlementSummary,
  onExportSettlements
};
