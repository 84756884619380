import { all, takeLatest, put, call } from 'redux-saga/effects';
import actions from './actions';
import { onGetFileContainers } from './apiCalls';

export function* getAllFileContainersSaga() {
  try {
    const response = yield call(onGetFileContainers);
    if (response.data) {
      yield put(actions.getAllFileContainersSuccess(response.data));
    } else {
      yield put(actions.getAllFileContainersError());
    }
  } catch {
    yield put(actions.getAllFileContainersError());
  }
}

export function* getInsuredRiskFileContainersSaga({ insuredRiskId }) {
  const params = { insured_risk_id: insuredRiskId };
  try {
    const response = yield call(onGetFileContainers, params);
    if (response.data) {
      yield put(actions.getInsuredRiskFileContainersSuccess(response.data));
    } else {
      yield put(actions.getInsuredRiskFileContainersError());
    }
  } catch {
    yield put(actions.getInsuredRiskFileContainersError());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_ALL_FILE_CONTAINERS, getAllFileContainersSaga),
    takeLatest(actions.GET_INSURED_RISK_FILE_CONTAINERS, getInsuredRiskFileContainersSaga)
  ]);
}
