import React from 'react';
import AntSpin from 'antd/es/spin';
import styled from 'styled-components';

const Wrapper = styled.div`
  .ant-spin {
    display: block;
    padding: 100px;

    &-dot-item {
      background: var(--primary-oqio-blue);
    }
  }
`;

const Spinner = () => (
  <Wrapper>
    <AntSpin size="large" />
  </Wrapper>
);

export default Spinner;
