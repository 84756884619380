import React from 'react';

const IconTrash = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="m16 5-1 11c-.073 1.385-.533 2.002-2 2H7c-1.467.002-1.927-.615-2-2L4 5h12z"
        stroke="#111"
        strokeWidth="1.5"
      />
      <path d="M2 5h16" stroke="#111" strokeWidth="1.5" />
      <path d="M8 7.5V15M12 7.5V15" stroke="#111" />
      <path
        d="m7 5 .409-1.63c.203-.817.776-1.369 1.421-1.37h2.336c.646.001 1.218.553 1.421 1.37L13 5"
        stroke="#111"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconTrash;
