import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '..';

const BACKGROUND_COLORS = {
  standard: 'var(--greyscale-concrete)'
};

const StyledLabel = styled.span`
  background-color: ${({ color }) => BACKGROUND_COLORS[color] || color};
  border-radius: var(--border-radius-medium);
  color: var(--greyscale-white);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-small);
  max-width: 220px;
  overflow: hidden;
  padding: 2px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FilterLabel = ({ children, color = 'standard' }) => (
  <StyledLabel color={color}>
    <Tooltip placement="topLeft" title={children} size="large">
      {children}
    </Tooltip>
  </StyledLabel>
);

export default FilterLabel;
