import { ATTACHMENT_MAX_SIZES } from '../../consts/consts';

const NOTIFICATION_MESSAGES = {
  risk_create_success: 'Risiko erfolgreich erstellt.',
  risk_delete_error: 'Es ist nicht erlaubt ein Risiko mit verknüpften Schäden zu löschen.',
  risk_delete_success: 'Risiko erfolgreich gelöscht.',
  risks_no_risk_selected: 'Sie haben keine Risiken ausgewählt.',
  risk_to_policies_create_success: 'Verknüpfung erfolgreich erstellt.',
  risk_to_policies_delete_success: 'Verknüpfung erfolgreich getrennt.',
  risk_year_of_completion_invalid: 'Baujahr zwischen 1000 und 2500 angeben.',
  risk_year_of_modernization_invalid: 'Modernisierungsjahr zwischen 1000 und 2500 angeben.',
  risk_year_of_renovation_invalid: 'Sanierungsjahr zwischen 1000 und 2500 angeben.',
  risk_year_value_year_of_completion_invalid: 'Im Jahr zwischen 1000 und 2500 angeben.',
  claim_create_success: 'Schaden erfolgreich erstellt.',
  claims_export_no_claim_selected: 'Sie haben keine Schäden ausgewählt.',
  external_contact_create_success: 'Kontakt erfolgreich erstellt.',
  external_contact_update_success: 'Kontakt erfolgreich aktualisiert.',
  external_contact_delete_success: 'Kontakt erfolgreich gelöscht.',
  form_complete_all_fields: 'Bitte füllen Sie alle Pflichtfelder aus.',
  message_attachment_exists: 'Eine Datei mit diesem Namen ist bereits angehängt.',
  message_attachment_size_exceeded: `Die Datei "$FILE_NAME" überschreitet die maximale Größe von ${ATTACHMENT_MAX_SIZES.megaByte} MB.`,
  message_attachment_invalid_file_type: 'Die Datei "$FILE_NAME" hat ein ungültiges Format.',
  input_is_not_valid_email: 'Die Eingabe ist keine gültige E-Mail.',
  message_create_success: 'Anmerkung erfolgreich erstellt.',
  message_delete_success: 'Anmerkung erfolgreich gelöscht.',
  message_template_create_success: 'Vorlage erfolgreich erstellt.',
  message_template_update_success: 'Vorlage erfolgreich aktualisiert.',
  message_template_delete_success: 'Vorlage erfolgreich gelöscht.',
  organization_create_success: 'Organisation erfolgreich erstellt.',
  organization_update_success: 'Organisation erfolgreich aktualisiert.',
  policy_create_success: 'Vertrag erfolgreich erstellt.',
  policy_delete_error: 'Es ist nicht erlaubt einen Vertrag mit verknüpften Schäden zu löschen.',
  policy_delete_success: 'Vertrag erfolgreich gelöscht.',
  policies_bulk_actions_add_organizations_success: 'Organisationen erfolgreich hinzugefügt.',
  policies_bulk_actions_remove_organizations_success: 'Organisationen erfolgreich entfernt.',
  policies_bulk_actions_add_insured_risks_success: 'Versicherte Gefahren erfolgreich hinzugefügt.',
  policies_bulk_actions_remove_insured_risks_success: 'Versicherte Gefahren erfolgreich entfernt.',
  policies_no_policy_selected: 'Sie haben keine Verträge ausgewählt.',
  password_change_current_password_incorrect: 'Das aktuelle Passwort stimmt nicht überein.',
  password_initial_signin_no_password_input: 'Bitte geben Sie ein Passwort ein.',
  password_reset_no_input: 'Bitte geben Sie ein Passwort ein.',
  password_reset_success: 'Passwort erfolgreich zurückgesetzt.',
  password_forgot_success: 'Bitte prüfen Sie Ihren E-Mail-Posteingang.',
  reporting_no_settlements_selected: 'Sie haben keine Rechnungen ausgewählt.',
  settlement_close_missing_settled_amount_error: 'Eine Rechnung ohne Regulierungsbetrag kann nicht geschlossen werden.',
  settlement_close_missing_date_error: "Eine Rechnung ohne 'Erledigt am' kann nicht geschlossen werden.",
  settlement_reject_settled_amount_error: 'Eine Rechnung mit Regulierungsbetrag kann nicht abgelehnt/storniert werden.',
  settlement_reject_missing_date_error: "Eine Rechnung ohne 'Erledigt am' kann nicht abgelehnt/storniert werden.",
  settlement_quotation_close_missing_settled_amount_error:
    'Ein Kostenvoranschlag ohne Freigabebetrag kann nicht geschlossen werden.',
  settlement_quotation_close_missing_date_error:
    "Ein Kostenvoranschlag ohne 'Freigegeben am' kann nicht geschlossen werden.",
  settlement_quotation_reject_settled_amount_error:
    'Ein Kostenvoranschlag mit Freigabebetrag kann nicht abgelehnt/storniert werden.',
  settlement_quotation_reject_missing_date_error:
    "Ein Kostenvoranschlag ohne 'Freigegeben am' kann nicht abgelehnt/storniert werden.",
  settlement_new_cutback_reason_create_success: 'Grund erfolgreich erstellt.',
  settlement_no_new_cutback_reason_input: 'Sie haben keinen neuen Grund eingegeben.',
  user_create_success: 'Benutzer erfolgreich erstellt.',
  user_update_success: 'Benutzer erfolgreich aktualisiert.',
  user_delete_success: 'Benutzer erfolgreich gelöscht.',
  claim_pdf_report_too_big_for_message_error: `Die Schadenakte ist zu groß, um sie einer Nachricht hinzuzufügen. Die maximale Dateigröße beträgt ${ATTACHMENT_MAX_SIZES.megaByte} MB.`,
  message_attachment_max_files_exceeded: 'Sie können maximal $NUMBER_OF_FILES_ALLOWED hochladen.'
};

export { NOTIFICATION_MESSAGES };
