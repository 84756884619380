const authActions = {
  LOAD_USER_UI_RIGHTS: 'LOAD_USER_UI_RIGHTS',
  LOAD_USER_UI_RIGHTS_SUCCESS: 'LOAD_USER_UI_RIGHTS_SUCCESS',
  LOAD_USER_UI_RIGHTS_ERROR: 'LOAD_USER_UI_RIGHTS_ERROR',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  SIGNIN: 'SIGNIN',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_ERROR: 'SIGNIN_ERROR',
  VALIDATE_USER: 'VALIDATE_USER',
  VALIDATE_USER_SUCCESS: 'VALIDATE_USER_SUCCESS',
  VALIDATE_USER_ERROR: 'VALIDATE_USER_ERROR',
  getUserRights: (userId) => ({
    type: authActions.LOAD_USER_UI_RIGHTS,
    userId
  }),
  getUserRightsSuccess: (payload) => ({
    type: authActions.LOAD_USER_UI_RIGHTS_SUCCESS,
    payload
  }),
  getUserRightsError: () => ({
    type: authActions.LOAD_USER_UI_RIGHTS_ERROR
  }),
  login: (username, password, callback) => ({
    type: authActions.LOGIN,
    payload: {
      username,
      password
    },
    callback
  }),
  loginSuccess: (payload) => ({
    type: authActions.LOGIN_SUCCESS,
    payload
  }),
  loginError: () => ({
    type: authActions.LOGIN_ERROR
  }),
  logout: () => ({
    type: authActions.LOGOUT
  }),
  validateUser: () => ({
    type: authActions.VALIDATE_USER
  }),
  validateUserSuccess: (payload) => ({
    type: authActions.VALIDATE_USER_SUCCESS,
    payload
  }),
  validateUserError: () => ({
    type: authActions.VALIDATE_USER_ERROR
  }),
  resetPassword: (token, newPassword, navigate) => ({
    type: authActions.RESET_PASSWORD,
    token,
    newPassword,
    navigate
  }),
  resetPasswordSuccess: () => ({
    type: authActions.RESET_PASSWORD_SUCCESS
  }),
  resetPasswordError: () => ({
    type: authActions.RESET_PASSWORD_ERROR
  }),
  forgotPassword: (email, callback) => ({
    type: authActions.FORGOT_PASSWORD,
    email,
    callback
  }),
  forgotPasswordSuccess: () => ({
    type: authActions.FORGOT_PASSWORD_SUCCESS
  }),
  forgotPasswordError: (error) => ({
    type: authActions.FORGOT_PASSWORD_ERROR,
    error
  }),
  signIn: (payload, navigate) => ({
    type: authActions.SIGNIN,
    payload,
    navigate
  }),
  signInSuccess: () => ({
    type: authActions.SIGNIN_SUCCESS
  }),
  signInError: (error = true) => ({
    type: authActions.SIGNIN_ERROR,
    error
  })
};
export default authActions;
