import React from 'react';

const IconReset = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      version="1.1"
      id="svg6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs10" />
      <path
        d="m 9.0409853,15.518446 h 3.6840007 c 2.431,0 4.421,-1.906 4.421,-4.235 0,-2.3300007 -1.99,-4.2360007 -4.421,-4.2360007 H 3.1459853"
        stroke="#111111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        id="path2"
      />
      <path
        d="m 7.1459853,10.547445 -4,-3.4999997 4,-3.5000001"
        stroke="#111111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        id="path4"
      />
    </svg>
  );
};

export default IconReset;
