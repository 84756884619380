import React from 'react';

const IconForward = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 8h-5.526C7.264 8 3 11.06 3 17" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="m13 3 5 5-5.053 5" stroke="#111" strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

export default IconForward;
