import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadAll = async (params) =>
  axios
    .get(apiUrls.policyBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadOne = async (policyId) =>
  axios
    .get(`${apiUrls.policyBaseUrl}/${policyId}`)
    .then((res) => res)
    .catch((error) => error);

// This API call returns a list of organizations that are authorized for the current policy (! not policy authorizations)
const onGetPolicyOrganizationsWithAccess = async (policyId) =>
  axios
    .get(`${apiUrls.policyBaseUrl}/${policyId}/organizations-with-access`)
    .then((res) => res)
    .catch((error) => error);

const onCreatePolicy = async (policies) =>
  axios
    .post(apiUrls.policyBaseUrl, policies)
    .then((res) => res)
    .catch((error) => error);
const onDeletePolicy = async (params) =>
  axios
    .post(`${apiUrls.policyBaseUrl}/delete-many`, params)
    .then((res) => res)
    .catch((error) => error);
const onUpdatePolicy = async (policies) =>
  axios
    .put(apiUrls.policyBaseUrl, policies)
    .then((res) => res)
    .catch((error) => error);

const onLoadAuth = async (policyId) =>
  axios
    .get(apiUrls.policyAuthorizationsBaseUrl, { params: { policy_id: policyId, paging: false } })
    .then((res) => res)
    .catch((error) => error);

const onLoadCustomValues = async (insured_risk_type) =>
  axios
    .get(`${apiUrls.policyBaseUrl}/custom-fields`, { params: { insured_risk_type } })
    .then((res) => res)
    .catch((error) => error);

const onDownloadImportTemplate = async () =>
  axios
    .post(`${apiUrls.policyBaseUrl}/create-export-file`, [], { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);

const onExportPolicies = async (policiesIds) =>
  axios
    .post(`${apiUrls.policyBaseUrl}/create-export-file`, policiesIds, { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);

const onImportPoliciesFile = async (file, housing_stock_id) =>
  axios
    .post(`${apiUrls.policyBaseUrl}/create-data-collation?housing_stock_id=${housing_stock_id}`, file, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then((res) => res)
    .catch((error) => error);

const onCreateInsuredRisksForPolicy = async (insuredRisks) =>
  axios
    .post(apiUrls.insuredRisksToPoliciesUrl, insuredRisks)
    .then((res) => res)
    .catch((error) => error);

const onUpdateInsuredRisksForPolicy = async (insuredRisks) =>
  axios
    .put(apiUrls.insuredRisksToPoliciesUrl, insuredRisks)
    .then((res) => res)
    .catch((error) => error);

const onLoadInsuredRisksPairs = async (insuredRisks, policy_id) =>
  axios
    .get(apiUrls.insuredRisksToPoliciesUrl, {
      params: { insured_risk_id: insuredRisks, policy_id, paging: false }
    })
    .then((res) => res)
    .catch((error) => error);

const onDeleteInsuredRisksForPolicy = async (insuredRisks) =>
  axios
    .post(`${apiUrls.insuredRisksToPoliciesUrl}/delete-many`, insuredRisks)
    .then((res) => res)
    .catch((error) => error);

const onLoadPoliciesInsuredRiskTypes = async (params) =>
  axios
    .get(`${apiUrls.policyBaseUrl}/insured-risk-types`, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadPoliciesInsuredRisks = async (params) =>
  axios
    .get(`${apiUrls.policyBaseUrl}/insured-risks`, { params })
    .then((res) => res)
    .catch((error) => error);

const onGetPoliciesFiltersOrganizationsWithAccess = async (params) =>
  axios
    .get(`${apiUrls.policyBaseUrl}/organizations-with-access`, { params })
    .then((res) => res)
    .catch((error) => error);

export {
  onLoadOne,
  onLoadAll,
  onCreatePolicy,
  onLoadAuth,
  onUpdatePolicy,
  onLoadCustomValues,
  onDownloadImportTemplate,
  onImportPoliciesFile,
  onExportPolicies,
  onCreateInsuredRisksForPolicy,
  onUpdateInsuredRisksForPolicy,
  onLoadInsuredRisksPairs,
  onDeleteInsuredRisksForPolicy,
  onDeletePolicy,
  onLoadPoliciesInsuredRiskTypes,
  onLoadPoliciesInsuredRisks,
  onGetPoliciesFiltersOrganizationsWithAccess,
  onGetPolicyOrganizationsWithAccess
};
