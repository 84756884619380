import React from 'react';
import styled from 'styled-components';
import AntProgress from 'antd/es/progress';

const Progress = styled(AntProgress)`
  .ant-progress-inner {
    font-size: 26px !important;

    .ant-progress-text {
      color: var(--primary-oqio-blue);
      font-weight: 500;
      letter-spacing: -0.5px;
      line-height: 1.15;

      .anticon {
        font-size: 40px;
      }
    }

    .ant-progress-circle-trail {
      stroke: var(--greyscale-dark-white);
    }
  }
`;

const ProgressBar = (props) => (
  <Progress
    type="circle"
    strokeColor="var(--primary-oqio-blue)"
    strokeLinecap={props.strokeLinecap || 'straight'}
    strokeWidth={props.strokeWidth || 18}
    size={props.width || 142}
    {...props}
  />
);

export default ProgressBar;
