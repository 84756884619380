import { all, takeLatest, put, call } from 'redux-saga/effects';
import actions from './actions';
import { onLoadConfig, onUpdateConfig } from './apiCalls';

export function* getUserConfig() {
  try {
    const response = yield call(onLoadConfig);
    if (response.data) {
      yield put(actions.getConfigSuccess(response.data));
    } else {
      yield put(actions.getConfigError());
    }
  } catch (error) {
    yield put(actions.getConfigError());
  }
}

export function* updateConfig({ payload }) {
  try {
    const response = yield call(onUpdateConfig, payload);
    if (response.data) {
      yield put(actions.updateConfigSuccess(response.data));
    } else {
      yield put(actions.updateConfigError());
    }
  } catch (error) {
    yield put(actions.updateConfigError());
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_CONFIG, getUserConfig), takeLatest(actions.UPDATE_CONFIG, updateConfig)]);
}
