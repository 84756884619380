import React from 'react';
import AntModal from 'antd/es/modal';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { IconClose } from '../Icons';
import { windowMaxWidth } from '../Layout/breakpoints';

const ExtendedModal = styled(AntModal)`
  .ant-modal-content {
    background: var(--greyscale-white);
    border-radius: var(--border-radius-regular);
    padding: 0;

    .ant-modal-close {
      align-items: center;
      border-radius: var(--border-radius-small);
      display: flex;
      height: 30px;
      justify-content: center;
      right: 20px;
      top: 20px;
      width: 30px;

      .ant-modal-close-x {
        display: flex;
        height: 20px;
        width: 20px;
      }

      &:hover {
        background-color: var(--secondary-whisper);
      }
    }

    .ant-modal-header {
      align-items: center;
      background: var(--greyscale-white);
      border-bottom: 1px solid var(--greyscale-dark-white);
      border-radius: var(--border-radius-regular) 8px 0 0;
      display: flex;
      height: 70px;
      justify-content: space-around;
      margin-bottom: 0;

      .ant-modal-title {
        color: var(--greyscale-blackish);
        font-size: var(--font-size-heading-2);
        font-weight: var(--font-weight-medium);
        letter-spacing: var(--font-letter-spacing-small);
        line-height: var(--font-line-height-large);
      }
    }
  }

  &.ant-modal {
    top: 58px;

    .ant-modal-body {
      font-size: var(--font-size-regular);
      overflow: auto;
      padding: 20px 40px;
      position: relative;
    }

    .ant-modal-footer {
      background: transparent;
      border-radius: 0 0 8px 8px;
      border-top: 1px solid var(--greyscale-dark-white);
      margin-top: 0;
      padding: 20px 30px 30px;
      text-align: right;
    }

    .ant-modal-close-icon {
      content: '\\e90e';
      font-size: var(--font-size-regular);
    }

    @media (${windowMaxWidth.mobile}) {
      top: 0;
      max-width: 100%;
      margin: 0;
      padding: 0;

      .ant-modal-header {
        border-radius: 0;
        height: 60px;

        .ant-modal-title {
          font-size: var(--font-size-heading-3);
          display: inline-block;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .ant-modal-content {
        border-radius: 0;
        overflow-x: hidden;

        .ant-modal-close {
          top: 15px;
        }
      }

      .ant-modal-body {
        min-height: calc(100vh - 141px);
        padding: 20px;
        height: auto;

        @supports (height: 100dvh) {
          min-height: calc(100dvh - 141px);
        }
      }

      .ant-modal-footer {
        padding: 20px;
      }
    }
  }

  &.modal {
    background: var(--greyscale-white);

    &-no {
      &-padding {
        .ant-modal-body {
          padding: 0;
        }
      }
    }

    &-settlements {
      .ant-modal-body {
        height: 500px;
        overflow: auto;
        padding: 0;
        scrollbar-color: rgb(136 136 136 / 30%) transparent;
        scrollbar-width: thin;
      }
    }

    &-custom-header {
      .ant-modal-header {
        height: 80px;
        padding-bottom: 10px;
        padding-top: 20px;
      }
    }
  }

  &.premiums-modal {
    .ant-modal-body {
      height: 400px;
      overflow: auto;
      padding: 0;
      scrollbar-color: rgb(136 136 136 / 30%) transparent;
      scrollbar-width: thin;
    }
  }

  &.no-padding {
    .ant-modal-body {
      padding: 0;
    }
  }

  &.claim-participants {
    .ant-modal-body {
      height: 500px;
      overflow: auto;
      padding: 0;
      scrollbar-color: rgba(136 136 136 / 30%) transparent;
      scrollbar-width: thin;

      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &.centered-modal {
    .ant-modal-body {
      height: auto;
      min-height: auto;
    }
  }

  &.worklist-claim-reminder-modal {
    animation-duration: 0.2s !important;
    height: calc(100vh - 60px);
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    .ant-modal-content {
      background-color: var(--secondary-whisper);
      border-radius: unset;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .ant-modal-close-x {
        height: 40px;
        padding-right: 20px;
        padding-top: 20px;
        width: 40px;

        .close-icon-wrapper {
          height: 20px;
          width: 20px;

          svg {
            path {
              stroke: var(--primary-oqio-blue);
            }
          }

          &:hover {
            background-color: initial;
          }
        }

        &:hover {
          .close-icon-wrapper {
            svg {
              path {
                stroke: var(--primary-oqio-blue-hover);
              }
            }

            &::before {
              color: var(--primary-oqio-blue-hover);
            }
          }
        }
      }

      .ant-modal-header {
        align-items: flex-end;
        border-radius: unset;
        flex: 1 0 101px;
        height: 100px;
        justify-content: flex-start;

        .ant-modal-title {
          color: var(--greyscale-blackish);
          line-height: var(--font-line-height-large);
        }
      }

      .ant-modal-body {
        flex: 1 1 100%;
        overflow: auto;
        padding: 20px;
      }
    }

    .ant-modal-footer {
      background-color: var(--greyscale-white);
      height: 80px;
      padding: 20px;
    }
  }

  &.initial-signin-modal {
    top: 10%;

    @media (${windowMaxWidth.mobile}) {
      top: 0;

      .ant-modal-body {
        min-height: calc(100dvh - 70px);
        padding: 20px;
      }
    }
  }

  &.import-custom-headers-modal {
    .ant-modal-content {
      .ant-modal-body {
        height: 500px;
        overflow: auto;
        scrollbar-color: rgb(136 136 136 / 30%) transparent;
        scrollbar-width: thin;
      }
    }
  }
`;

const ModalContainer = styled.div`
  &.no-pointer-events {
    .ant-modal-wrap {
      pointer-events: none;
    }
  }
`;

ExtendedModal.confirm = AntModal.confirm;

const closeIcon = (
  <div className="close-icon-wrapper">
    <IconClose />
  </div>
);

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalFooter = ({ ...props }) => {
  const { onCancel, onSubmit, disableSubmit, isButtonLoading } = props;
  const disableSubmitButton = disableSubmit || false;
  return (
    <StyledFooter>
      <Button onClick={onCancel} type="secondary">
        Abbrechen
      </Button>
      <Button disabled={disableSubmitButton} type="primary" onClick={onSubmit} loading={isButtonLoading}>
        Speichern
      </Button>
    </StyledFooter>
  );
};

const getContainer = (props) => {
  if (props?.getContainer && props?.createContainer) {
    return `.${props?.getContainer}`;
  }

  return getDefaultContainer();
};

const getDefaultContainer = () => {
  const container = document.getElementById('page-modal-wrapper');
  return container;
};

const Modal = (props) => {
  const footer = props.footer || <ModalFooter {...props} />;
  const modalContainer = getContainer(props);

  return props.createContainer ? (
    <ModalContainer footer={footer} className={props.getContainer}>
      <ExtendedModal closeIcon={closeIcon} {...props} getContainer={modalContainer}>
        {props.children}
      </ExtendedModal>
    </ModalContainer>
  ) : (
    <ExtendedModal width={600} footer={footer} closeIcon={closeIcon} {...props} getContainer={modalContainer}>
      {props.children}
    </ExtendedModal>
  );
};
// const Modal = (props) => <ExtendedModal {...props}>{props.children}</ExtendedModal>;
export default Modal;
