import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import risksActions from '../risks/actions';
import policiesActions from '../policies/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import actions from './actions';
import { onLoadPoliciesToRisks, onCreatePoliciesToRisks, onDeleteManyPoliciesToRisks } from './apiCalls';

export function* createPoliciesToRisksSaga({ params, showNotification }) {
  try {
    const response = yield call(onCreatePoliciesToRisks, params);
    if (response.data) {
      const { risk_to_policies_create_success } = NOTIFICATION_MESSAGES;
      if (showNotification) {
        renderNotification({ type: 'success', message: risk_to_policies_create_success });
      }
      yield put(actions.createPoliciesToRisksSuccess(response.data));
    } else {
      yield put(actions.createPoliciesToRisksError());
    }
  } catch (error) {
    yield put(actions.createPoliciesToRisksError());
  }
}

export function* deleteManyPoliciesToRisksSaga({ params }) {
  try {
    const response = yield call(onDeleteManyPoliciesToRisks, params);
    if (response.status === 200) {
      const { risk_to_policies_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: risk_to_policies_delete_success });
      yield put(actions.deleteManyPoliciesToRisksSuccess(response.data));
    } else {
      yield put(actions.deleteManyPoliciesToRisksError());
    }
  } catch (error) {
    yield put(actions.deleteManyPoliciesToRisksError());
  }
}

export function* getPoliciesToRisksSaga({ params }) {
  try {
    const response = yield call(onLoadPoliciesToRisks, { ...params, paging: false });
    if (response.data) {
      yield put(
        actions.getPoliciesToRisksSuccess(response.data, {
          perPage: response.headers.perpage,
          total: response.headers.total
        })
      );
    } else {
      yield put(actions.getPoliciesToRisksError());
    }
  } catch (error) {
    yield put(actions.getPoliciesToRisksError());
  }
}

const getRisk = (state) => state.Risks.get('risk');
const getSinglePolicy = (state) => state.Policies.get('policy');

export function* reloadPoliciesToRisks() {
  const risk = yield select(getRisk);
  const policy = yield select(getSinglePolicy);
  const item = risk || policy;
  if (!item) {
    return;
  }
  const { id } = item;
  if (risk) {
    yield put(actions.getPoliciesToRisks({ risk_id: [id] }));
    yield put(risksActions.getConnectedPolicies([id]));
    return;
  }
  yield put(actions.getPoliciesToRisks({ policy_id: [id] }));
  yield put(policiesActions.getConnectedRisks({ policy_id: [id], paging: false }));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_POLICIES_TO_RISKS, createPoliciesToRisksSaga),
    takeEvery(actions.CREATE_POLICIES_TO_RISKS_SUCCESS, reloadPoliciesToRisks),
    takeEvery(actions.DELETE_MANY_POLICIES_TO_RISKS, deleteManyPoliciesToRisksSaga),
    takeEvery(actions.DELETE_MANY_POLICIES_TO_RISKS_SUCCESS, reloadPoliciesToRisks),
    takeEvery(actions.GET_POLICIES_TO_RISKS, getPoliciesToRisksSaga)
  ]);
}
