import React from 'react';

const IconReject = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="8" stroke="#111" strokeWidth="1.5" />
      <path d="M15.657 4.343 4.343 15.657" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconReject;
