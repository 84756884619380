import React from 'react';

const IconUnlink = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10.016 5.773 1.668-1.668a2.977 2.977 0 1 1 4.21 4.21l-1.667 1.669M9.984 14.227l-1.668 1.668a2.977 2.977 0 0 1-4.21-4.21l1.667-1.669"
      stroke="#111"
      strokeWidth="1.5"
    />
    <path
      d="m5.5 5.5-3-3M8 4.536V1M4.536 8H1M14.5 14.5l3 3M12 15.5V19M15.5 12.314H19"
      stroke="111111"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconUnlink;
