import { Map } from 'immutable';
import actions from './actions';

const INITIAL_STATE = new Map({
  insuredRisks: [],
  insuredRisksLoading: false,
  insuredRisksError: false,
  insuredRisksForSmartAction: [],
  insuredRisksForSmartActionLoading: false,
  insuredRisksForSmartActionError: false,
  claimsInsuredRisks: [],
  claimsInsuredRisksLoading: false,
  claimsInsuredRisksError: false,
  insuredRisksForPolicy: [],
  insuredRisksForPolicyLoading: false,
  insuredRisksForPolicyError: false,
  insuredRisksForId: [],
  insuredRisksForIdLoading: false,
  insuredRisksForIdError: false,
  availableInsuredRisks: [],
  availableInsuredRisksLoading: false,
  availableInsuredRisksError: false,
  insuredRisksForSelectedPolicy: [],
  insuredRisksForSelectedPolicyLoading: false,
  insuredRisksForSelectedPolicyError: false,
  addInsuredRisksToPolicyLoading: false,
  addInsuredRisksToPolicyError: false,
  updateInsuredRisksToPolicyLoading: false,
  updateInsuredRisksToPolicyError: false,
  deleteInsuredRisksForPolicyLoading: false,
  deleteInsuredRisksForPolicyError: false,
  insuredRisksFromManyPolicies: [],
  insuredRisksFromManyPoliciesLoading: false,
  insuredRisksFromManyPoliciesError: false
});

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.GET_INSURED_RISKS:
      return state.set('insuredRisksLoading', true);
    case actions.GET_INSURED_RISKS_SUCCESS:
      return state.set('insuredRisks', action.payload).set('insuredRisksLoading', false);
    case actions.GET_INSURED_RISKS_ERROR:
      return state.set('insuredRisksLoading', false).set('insuredRisksError', true);
    case actions.GET_INSURED_RISKS_FOR_SMART_ACTION:
      return state.set('insuredRisksForSmartActionLoading', true);
    case actions.GET_INSURED_RISKS_FOR_SMART_ACTION_SUCCESS:
      return state.set('insuredRisksForSmartAction', action.payload).set('insuredRisksForSmartActionLoading', false);
    case actions.GET_INSURED_RISKS_FOR_SMART_ACTION_ERROR:
      return state.set('insuredRisksForSmartActionLoading', false).set('insuredRisksForSmartActionError', true);
    case actions.GET_CLAIMS_INSURED_RISKS:
      return state.set('claimsInsuredRisksLoading', true);
    case actions.GET_CLAIMS_INSURED_RISKS_SUCCESS:
      return state.set('claimsInsuredRisks', action.payload).set('claimsInsuredRisksLoading', false);
    case actions.GET_CLAIMS_INSURED_RISKS_ERROR:
      return state.set('claimsInsuredRisksLoading', false).set('claimsInsuredRisksError', true);
    case actions.GET_INSURED_RISKS_FOR_POLICY:
      return state.set('insuredRisksForPolicyLoading', true);
    case actions.GET_INSURED_RISKS_FOR_POLICY_SUCCESS:
      return state.set('insuredRisksForPolicy', action.payload).set('insuredRisksForPolicyLoading', false);
    case actions.GET_INSURED_RISKS_FOR_POLICY_ERROR:
      return state.set('insuredRisksForPolicyLoading', false).set('insuredRisksForPolicyError', true);
    case actions.GET_AVAILABLE_INSURED_RISKS:
      return state.set('availableInsuredRisksLoading', true);
    case actions.GET_AVAILABLE_INSURED_RISKS_SUCCESS:
      return state.set('availableInsuredRisks', action.payload).set('availableInsuredRisksLoading', false);
    case actions.GET_AVAILABLE_INSURED_RISKS_ERROR:
      return state.set('availableInsuredRisksLoading', false).set('availableInsuredRisksError', true);
    case actions.RESET_AVAILABLE_INSURED_RISKS:
      return state.set('availableInsuredRisks', []);
    case actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY:
      return state.set('insuredRisksForSelectedPolicyLoading', true);
    case actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY_SUCCESS:
      return state
        .set('insuredRisksForSelectedPolicy', action.payload)
        .set('insuredRisksForSelectedPolicyLoading', false);
    case actions.GET_INSURED_RISKS_FOR_SELECTED_POLICY_ERROR:
      return state.set('insuredRisksForSelectedPolicyLoading', false).set('insuredRisksForSelectedPolicyError', true);
    case actions.ADD_INSURED_RISKS_TO_POLICY:
      return state.set('addInsuredRisksToPolicyLoading', true);
    case actions.ADD_INSURED_RISKS_TO_POLICY_SUCCESS:
      return state.set('addInsuredRisksToPolicyLoading', false);
    case actions.ADD_INSURED_RISKS_TO_POLICY_ERROR:
      return state.set('addInsuredRisksToPolicyLoading', false).set('addInsuredRisksToPolicyError', true);
    case actions.UPDATE_INSURED_RISKS_TO_POLICY:
      return state.set('updateInsuredRisksToPolicyLoading', true);
    case actions.UPDATE_INSURED_RISKS_TO_POLICY_SUCCESS:
      return state.set('updateInsuredRisksToPolicyLoading', false);
    case actions.UPDATE_INSURED_RISKS_TO_POLICY_ERROR:
      return state.set('updateInsuredRisksToPolicyLoading', false).set('updateInsuredRisksToPolicyError', true);
    case actions.DELETE_INSURED_RISKS_FOR_POLICY:
      return state.set('deleteInsuredRisksForPolicyLoading', true);
    case actions.DELETE_INSURED_RISKS_FOR_POLICY_SUCCESS:
      return state.set('deleteInsuredRisksForPolicyLoading', false);
    case actions.DELETE_INSURED_RISKS_FOR_POLICY_ERROR:
      return state.set('deleteInsuredRisksForPolicyLoading', false).set('deleteInsuredRisksForPolicyError', true);
    case actions.GET_INSURED_RISKS_FOR_ID:
      return state.set('insuredRisksForIdLoading', true);
    case actions.GET_INSURED_RISKS_FOR_ID_SUCCESS:
      return state.set('insuredRisksForId', [action.payload]).set('insuredRisksForIdLoading', false);
    case actions.GET_INSURED_RISKS_FOR_ID_ERROR:
      return state.set('insuredRisksForIdLoading', false).set('insuredRisksForIdError', true);
    case actions.GET_INSURED_RISKS_FROM_MANY_POLICIES:
      return state.set('insuredRisksFromManyPoliciesLoading', true);
    case actions.GET_INSURED_RISKS_FROM_MANY_POLICIES_SUCCESS:
      return state
        .set('insuredRisksFromManyPolicies', [...state.get('insuredRisksFromManyPolicies'), ...action.payload])
        .set('insuredRisksFromManyPoliciesLoading', false);
    case actions.GET_INSURED_RISKS_FROM_MANY_POLICIES_ERROR:
      return state.set('insuredRisksFromManyPoliciesLoading', false).set('insuredRisksFromManyPoliciesError', true);
    case actions.CLEAR_INSURED_RISKS_FROM_MANY_POLICIES:
      return state.set('insuredRisksFromManyPolicies', []);
    default:
      return state;
  }
};

export default authReducer;
