import React from 'react';
import styled from 'styled-components';
import { IconArrowLeft, IconArrowRight } from '../Icons';

const PaginationWrapper = styled.div`
  display: flex;
`;

const PaginationButton = styled.button`
  align-items: center;
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: ${(props) => (props.left ? '5px 0 0 5px' : '0 5px 5px 0')};
  border-right: ${(props) => props.left && 'none'};
  color: ${(props) => (props.disabled ? 'var(--greyscale-concrete)' : 'var(--greyscale-blackish)')};
  display: flex;
  height: 30px;
  padding: 5px 10px;

  svg {
    path {
      stroke: ${(props) => (props.disabled ? 'var(--greyscale-concrete)' : 'var(--greyscale-blackish)')};
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const Pagination = ({ currentPage, pages, onChange }) => {
  const prevPage = currentPage === 1 ? 0 : currentPage - 1;
  const totalPages = pages < 1 ? 1 : pages;

  return (
    <PaginationWrapper>
      <PaginationButton $left className="button" disabled={currentPage === 1} onClick={() => onChange(prevPage)}>
        <IconArrowLeft />
      </PaginationButton>
      <PaginationButton
        className="button"
        disabled={currentPage === totalPages}
        onClick={() => onChange(currentPage + 1, totalPages)}
      >
        <IconArrowRight />
      </PaginationButton>
    </PaginationWrapper>
  );
};

export default Pagination;
