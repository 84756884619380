import React from 'react';

const EyecandyOldBrowser = () => {
  return (
    <svg width="71" height="61" viewBox="0 0 71 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.75 10.5c0 1.336.456 2.566 1.22 3.542a4.607 4.607 0 0 0-1.104 2.026 4.11 4.11 0 0 0-.114.725 1.785 1.785 0 0 0-.002.052V16.873s0 .002.75.002h-.75c0 .414.336.75.75.75h18a.75.75 0 0 0 .744-.846l-.744.096.744-.097v-.004l-.002-.006-.002-.017a2.685 2.685 0 0 0-.041-.21 4.522 4.522 0 0 0-.158-.53 5.338 5.338 0 0 0-.9-1.566c-.886-1.074-2.405-2.078-4.938-2.284-.13-.971-.528-1.765-1.11-2.348a3.598 3.598 0 0 0-1.015-.712A5.752 5.752 0 0 0 48.75 10.5zm5.75-4.25a4.25 4.25 0 0 0-3.354 6.86c.885-.514 2.071-.883 3.653-.967.133-.963.53-1.75 1.108-2.33a3.653 3.653 0 0 1 2.468-1.06A4.252 4.252 0 0 0 54.5 6.25zm2.468 4.624c-.406.406-.718 1.05-.718 2.001a.75.75 0 0 1-.75.75c-2.817 0-4.07.928-4.65 1.7a3.119 3.119 0 0 0-.434.8h16.042a3.888 3.888 0 0 0-.474-.726c-.662-.802-1.95-1.716-4.501-1.774a.75.75 0 0 1-.733-.75c0-.95-.312-1.595-.718-2a2.158 2.158 0 0 0-1.532-.625c-.565 0-1.12.211-1.532.624zM42.05 24.173l1.443-.369a2.335 2.335 0 1 0-1.157-4.525l-1.786.457v7.128a.75.75 0 0 0 1.5 0v-2.69zm1.7-2.631a.835.835 0 0 0-1.042-.81l-.658.169v1.724l1.072-.274a.835.835 0 0 0 .628-.81z"
        fill="#B3B3B3"
      />
      <path d="M37.5 19.977a.75.75 0 0 1 .75.75v6.546a.75.75 0 0 1-1.5 0v-6.546a.75.75 0 0 1 .75-.75z" fill="#B3B3B3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.25 25.91v2.59a.75.75 0 0 1-1.5 0v-7.128l1.786-.456a2.335 2.335 0 0 1 1.68 4.322c.337.312.609.702.785 1.154l.398 1.017a.75.75 0 0 1-1.398.546l-.397-1.017a1.627 1.627 0 0 0-1.354-1.027zm1.7-2.732a.835.835 0 0 0-1.042-.81l-.658.169v1.724l1.072-.274a.835.835 0 0 0 .628-.809z"
        fill="#B3B3B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 23.732V48.75h-5.773l.939-.626a.75.75 0 1 0-.832-1.248l-.91.606L13.1 46.05a.75.75 0 1 0-1.2.9l1.35 1.8H6.5a.75.75 0 0 0 0 1.5h58a.75.75 0 0 0 0-1.5h-9.19l2.22-2.22a.75.75 0 1 0-1.06-1.06l-1.22 1.22V44.5a.75.75 0 0 0-1.5 0v2.19l-1.22-1.22a.75.75 0 1 0-1.06 1.06l2.22 2.22h-4.44V22.805c0-7.375-6.715-12.927-13.959-11.54-.14.026-.28.056-.42.087l-4.487.822a11.742 11.742 0 0 0-7.982 5.55 9.306 9.306 0 0 0-2.2-.452c-.133-.965-.53-1.754-1.11-2.334a3.658 3.658 0 0 0-2.592-1.063c-.935 0-1.88.351-2.593 1.063-.579.58-.975 1.367-1.108 2.33-2.743.146-4.298 1.148-5.149 2.282a4.606 4.606 0 0 0-.784 1.643 4.112 4.112 0 0 0-.114.725 2.112 2.112 0 0 0-.002.052V21.998s0 .002.75.002h-.75c0 .414.336.75.75.75h13.29c-.026.324-.04.652-.04.982zm6.5-.927V48.75h2.94l-2.22-2.22a.75.75 0 1 1 1.06-1.06l1.22 1.22V44.5a.75.75 0 0 1 1.5 0v2.19l1.22-1.22a.75.75 0 1 1 1.06 1.06l-2.22 2.22h15.94V22.805c0-6.413-5.821-11.246-12.117-10.079l.002.012-.46.084a10.25 10.25 0 0 0-7.925 9.983zm-1.5 0V48.75h-3.5V23.732a10.25 10.25 0 0 1 7.695-9.927 11.744 11.744 0 0 0-4.195 9zm-4.04-3.727a7.283 7.283 0 0 0-2.197-.328.75.75 0 0 1-.763-.75c0-.95-.312-1.595-.718-2a2.158 2.158 0 0 0-1.532-.625c-.565 0-1.12.211-1.532.624-.406.406-.718 1.05-.718 2.001a.75.75 0 0 1-.75.75c-2.817 0-4.07.928-4.65 1.7a3.118 3.118 0 0 0-.434.8h12.598c.162-.752.396-1.478.696-2.172z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default EyecandyOldBrowser;
