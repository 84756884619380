import { all, takeLatest, put, call, takeEvery, select } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import queryString from 'query-string';
import insuredRisksActions from '../insuredRisks/actions';
import worklistClaimsActions from '../worklistClaims/actions';
import fileUploadActions from '../fileUpload/actions';
import fileContainersActions from '../fileContainers/actions';
import newEntityNotification from '../../helpers/notifications/newEntityNotification';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { getFileNameFromApiResponseHeader } from '../../helpers/utility';
import { onGetRisk } from '../risks/apiCalls';
import { gaDownloadPdfReport } from '../../config/googleAnalytics';
import {
  onLoadAll,
  onLoadAllWorklistClaims,
  onGetClaimHistory,
  onLoadMapRisks,
  onGetClaim,
  onUpdateClaim,
  onCreateClaim,
  onUpdateClaimStatus,
  onLoadCreatedByOrganizations,
  onGetClaimsFiltersOrganizationsWithAccess,
  onExportClaims,
  onDownloadReport,
  onGetClaimOrganizationsWithAccess,
  onLoadClaimsInsuredRiskTypes
} from './apiCalls';
import actions from './actions';

// ---------------------------------------SAGA FUNCTIONS FOR CLAIMS LIST--------------------------------------- //
export function* loadClaims({ params }) {
  try {
    const response = yield call(onLoadAll, params);
    if (response.data) {
      yield put(
        actions.loadClaimsSuccess(response.data, {
          perPage: response.headers.perpage,
          total: response.headers.total
        })
      );
    } else {
      yield put(actions.loadClaimsError());
    }
  } catch {
    yield put(actions.loadClaimsError());
  }
}

export function* loadClaimsInsuredRiskTypes() {
  try {
    const response = yield call(onLoadClaimsInsuredRiskTypes);
    if (response.data) {
      yield put(actions.getClaimsInsuredRiskTypesSuccess(response.data));
    } else {
      yield put(actions.getClaimsInsuredRiskTypesError());
    }
  } catch {
    yield put(actions.getClaimsInsuredRiskTypesError());
  }
}

export function* loadClaimsByPolicyId({ policyId, otherParams }) {
  try {
    const response = yield call(onLoadAll, { policy_id: policyId, ...otherParams });
    if (response.data) {
      yield put(actions.loadClaimsByPolicyIdSuccess(response.data));
    } else {
      yield put(actions.loadClaimsByPolicyIdError());
    }
  } catch {
    yield put(actions.loadClaimsByPolicyIdError());
  }
}

export function* loadWorklistClaims({ params }) {
  try {
    const response = yield call(onLoadAllWorklistClaims, params);
    if (response.data) {
      yield put(
        actions.loadWorklistClaimsSuccess(response.data, {
          perPage: response.headers.perPage,
          total: response.headers.total
        })
      );
    } else {
      yield put(actions.loadWorklistClaimsError(response));
    }
  } catch {
    yield put(actions.loadWorklistClaimsError());
  }
}

export function* loadMapRisks({ params }) {
  try {
    const response = yield call(onLoadMapRisks, params);
    if (response.status && response.data) {
      yield put(actions.loadMapRisksSuccess(response.data));
    } else {
      yield put(actions.loadMapRisksError());
    }
  } catch {
    yield put(actions.loadMapRisksError());
  }
}

export function* loadMapSelectedRiskSaga({ riskId }) {
  try {
    const response = yield call(onGetRisk, riskId);
    if (response.data) {
      yield put(actions.loadMapSelectedRiskSuccess(response.data));
    } else {
      yield put(actions.loadMapSelectedRiskError());
    }
  } catch {
    yield put(actions.loadMapSelectedRiskError());
  }
}

export function* loadMapSelectedRiskClaimsSaga({ riskId, params }) {
  try {
    const response = yield call(onLoadAll, { risk_id: riskId, ...params });
    if (response.data) {
      yield put(actions.loadMapSelectedRiskClaimsSuccess(response.data));
    } else {
      yield put(actions.loadMapSelectedRiskClaimsError());
    }
  } catch {
    yield put(actions.loadMapSelectedRiskClaimsError());
  }
}

export function* loadChoosableCreatedByOrganizations() {
  try {
    const response = yield call(onLoadCreatedByOrganizations);
    if (response.data) {
      yield put(actions.loadChoosableCreatedByOrganizationsSuccess(response.data));
    } else {
      yield put(actions.loadChoosableCreatedByOrganizationsError());
    }
  } catch {
    yield put(actions.loadChoosableCreatedByOrganizationsError());
  }
}

export function* getClaimsFiltersOrganizationsWithAccessSaga() {
  try {
    const response = yield call(onGetClaimsFiltersOrganizationsWithAccess);
    if (response.data) {
      yield put(actions.getClaimsFiltersOrganizationsWithAccessSuccess(response.data));
    } else {
      yield put(actions.getClaimsFiltersOrganizationsWithAccessError());
    }
  } catch {
    yield put(actions.getClaimsFiltersOrganizationsWithAccessError());
  }
}

export function* exportClaims({ params }) {
  try {
    const claimsWithoutPagination = yield call(onLoadAll, { ...params, paging: false });
    const claimsIds = claimsWithoutPagination.data.map((claim) => claim.id);
    const response = yield call(onExportClaims, claimsIds);

    if (response.data) {
      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      // only IE does support this method
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet:base64'
        });
        FileSaver.saveAs(blob);
      }

      yield put(actions.exportClaimsSuccess());
    } else {
      yield put(actions.exportClaimsError());
    }
  } catch (error) {
    yield put(actions.exportClaimsError());
  }
}

// ---------------------------------------SAGA FUNCTIONS FOR SINGLE CLAIM--------------------------------------- //
export function* createClaimSaga({ claimData, params, callback, navigate }) {
  try {
    const response = yield call(onCreateClaim, claimData);
    if (response.data) {
      yield all([put(actions.createClaimSuccess(response.data, params))]);

      if (callback && typeof callback === 'function') {
        callback();
      }
      newEntityNotification({
        entityType: 'claim',
        entityId: response.data.id,
        navigate
      });
    } else {
      yield put(actions.createClaimError());
    }
  } catch {
    yield put(actions.createClaimError());
  }
}

export function* getClaimSaga({ claimId }) {
  try {
    yield all([
      put(worklistClaimsActions.clearWorklistClaimMessages()),
      put(worklistClaimsActions.setWorklistClaimMessagesFilterToDefault()),
      put(actions.clearClaimOrganizationsWithAccess()),
      put(fileContainersActions.clearInsuredRiskFileContainers())
    ]);
    const response = yield call(onGetClaim, claimId);
    if (response.data) {
      const defaultWorklistClaimMessagesFilter = yield select(getDefaultWorklistClaimMessagesFilter);

      const prepareActionsArray = (claim) => {
        const _actionsArray = [
          put(worklistClaimsActions.getWorklistClaimMessages(claimId, defaultWorklistClaimMessagesFilter)),
          put(worklistClaimsActions.getClaimActiveMessagesCount(claimId)),
          put(actions.getClaimSuccess(claim)),
          put(actions.getClaimHistory(claimId)),
          put(actions.getClaimOrganizationsWithAccess(claimId))
        ];

        const hasInsuredRiskId = !!claim.insured_risk_id;
        if (hasInsuredRiskId) {
          _actionsArray.push(put(fileContainersActions.getInsuredRiskFileContainers(claim.insured_risk_id)));
        }

        return _actionsArray;
      };

      const actionsArray = prepareActionsArray(response.data);
      yield all(actionsArray);
    } else {
      yield put(actions.getClaimError());
    }
  } catch {
    yield put(actions.getClaimError());
  }
}

const getDefaultWorklistClaimMessagesFilter = (state) => state.WorklistClaims.get('worklistClaimMessagesDefaultFilter');

export function* getClaimHistorySaga({ claimId }) {
  try {
    const response = yield call(onGetClaimHistory, claimId);
    if (response.data) {
      yield put(actions.getClaimHistorySuccess(response.data));
    } else {
      yield put(actions.getClaimHistoryError());
    }
  } catch (error) {
    yield put(actions.getClaimHistoryError());
  }
}

export function* updateClaimSaga({ claim, callback }) {
  try {
    const response = yield call(onUpdateClaim, claim);
    if (response.data) {
      yield all([
        put(fileContainersActions.clearInsuredRiskFileContainers()),
        put(actions.clearClaimOrganizationsWithAccess())
      ]);
      const prepareActionsArray = (claim) => {
        const _actionsArray = [
          put(actions.updateClaimSuccess(claim)),
          put(actions.getClaimHistory(claim.id)),
          put(worklistClaimsActions.getWorklistClaimMessages(claim.id)),
          put(actions.getClaimOrganizationsWithAccess(claim.id))
        ];

        const hasInsuredRiskId = !!claim.insured_risk_id;
        if (hasInsuredRiskId) {
          _actionsArray.push(put(fileContainersActions.getInsuredRiskFileContainers(claim.insured_risk_id)));
        }

        return _actionsArray;
      };

      const actionsArray = prepareActionsArray(response.data);
      yield all(actionsArray);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateClaimError());
    }
  } catch {
    yield put(actions.updateClaimError());
  }
}

export function* updateClaimStatusSaga({ claimId, status, callback }) {
  try {
    const response = yield call(onUpdateClaimStatus, claimId, status);
    if (response.data) {
      const _newClaimObject = response.data;
      yield all([
        put(actions.updateClaimStatusSuccess(_newClaimObject, claimId, callback)),
        put(worklistClaimsActions.getWorklistClaimMessages(claimId))
      ]);
    } else {
      yield put(actions.updateClaimStatusError(response));
    }
  } catch (error) {
    yield put(actions.updateClaimStatusError(error));
  }
}

export function* updateClaimStatusSuccessSaga({ claimId, callback }) {
  try {
    yield put(actions.getClaimHistory(claimId));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(actions.updateClaimStatusError(error));
  }
}

export function* updateClaimStatusErrorSaga({ errorMessage }) {
  try {
    if (errorMessage && errorMessage.length > 0) {
      const notificationOptions = {
        type: 'error',
        message: errorMessage,
        duration: 5
      };
      renderNotification(notificationOptions);
    }
  } catch (error) {
    yield put(actions.updateClaimStatusError(error));
  }
}

export function* getClamAuthorizedOrganizationsSaga({ claimId }) {
  try {
    const response = yield call(onGetClaimOrganizationsWithAccess, claimId);
    if (response.data) {
      yield put(actions.getClaimAuthorizedOrganizationsSuccess(response.data));
    } else {
      yield put(actions.getClaimAuthorizedOrganizationsError());
    }
  } catch {
    yield put(actions.getClaimAuthorizedOrganizationsError());
  }
}

export function* getClaimOrganizationsWithAccessSaga({ claimId }) {
  try {
    const response = yield call(onGetClaimOrganizationsWithAccess, claimId);
    if (response.data) {
      yield put(actions.getClaimOrganizationsWithAccessSuccess(response.data));
    } else {
      yield put(actions.getClaimOrganizationsWithAccessError());
    }
  } catch {
    yield put(actions.getClaimOrganizationsWithAccessError());
  }
}

export function* getPolicyDetailsClaimsSaga({ policyId, search }) {
  try {
    const params = { policy_id: policyId, search, paging: false };
    const response = yield call(onLoadAll, params);
    if (response.data) {
      yield put(actions.getPolicyDetailsClaimsSuccess(response.data));
    } else {
      yield put(actions.getPolicyDetailsClaimsError());
    }
  } catch {
    yield put(actions.getPolicyDetailsClaimsError());
  }
}

export function* getPDFReportSaga({ claimId, callback }) {
  try {
    const response = yield call(onDownloadReport, claimId);
    if (response.data) {
      yield put(actions.getPDFReportSuccess());
      gaDownloadPdfReport();

      const filename = getFileNameFromApiResponseHeader(response.headers, '.pdf');
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const blob = new Blob([response.data], { type: 'application/pdf' });

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.getPDFReportError());
    }
  } catch (error) {
    yield put(actions.getPDFReportError());
  }
}

export function* getClaimPDFSummarySaga({ claimId, isMessage }) {
  try {
    const response = yield call(onDownloadReport, claimId);
    if (response.data) {
      const blobToFile = (theBlob, fileName) => {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return b;
      };
      let pdfClaimFile;

      const isEdgeBrowser = navigator.userAgent.indexOf('Edge') > -1;
      const fileName = getFileNameFromApiResponseHeader(response.headers, '.pdf');
      const blob = new Blob([response.data], { type: 'application/pdf' });

      if (isEdgeBrowser || window.navigator.msSaveOrOpenBlob) {
        pdfClaimFile = blobToFile(response.data, fileName);
      } else {
        pdfClaimFile = new File([blob], fileName, { type: 'application/pdf' });
      }

      yield put(actions.getClaimPDFSummarySuccess(pdfClaimFile));

      if (isMessage) {
        // Check if the file is bigger than 20MB and close the loading for the attachments modal
        const isFileBiggerThan20MB = pdfClaimFile.size > 20971520;

        if (isFileBiggerThan20MB) {
          yield put(worklistClaimsActions.setIsNewMessageAttachmentsUploadLoading(false));

          const _message = NOTIFICATION_MESSAGES.claim_pdf_report_too_big_for_message_error;
          const notificationOptions = {
            type: 'error',
            message: _message,
            duration: 5
          };
          renderNotification(notificationOptions);
        } else {
          yield put(fileUploadActions.uploadFileRequestForMessageAttachments(pdfClaimFile, claimId, 'claimReport'));
        }
      }
    } else {
      yield put(actions.getClaimPDFSummaryError());
    }
  } catch (error) {
    yield put(actions.getClaimPDFSummaryError());
  }
}

const getActiveFilters = (state) => state.Filters.get('claimsActiveFilters');

export function* reloadClaimsSaga() {
  try {
    const claimsListActiveFilters = yield select(getActiveFilters);
    const params = queryString.parse(claimsListActiveFilters);

    yield all([
      put(actions.loadClaims(params)),
      put(actions.getClaimsInsuredRiskTypes()),
      put(insuredRisksActions.getClaimsInsuredRisks())
    ]);
  } catch {
    yield put(actions.loadClaimsError());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_CLAIMS, loadClaims),
    takeLatest(actions.LOAD_WORKLIST_CLAIMS, loadWorklistClaims),
    takeLatest(actions.LOAD_CLAIMS_BY_POLICY_ID, loadClaimsByPolicyId),
    takeEvery(actions.LOAD_CHOOSABLE_CREATED_BY_ORGANIZATIONS, loadChoosableCreatedByOrganizations),
    takeEvery(actions.GET_CLAIMS_FILTERS_ORGANIZATIONS_WITH_ACCESS, getClaimsFiltersOrganizationsWithAccessSaga),
    takeLatest(actions.EXPORT_CLAIMS, exportClaims),
    takeEvery(actions.GET_CLAIMS_INSURED_RISK_TYPES, loadClaimsInsuredRiskTypes),
    takeLatest(actions.LOAD_MAP_RISKS, loadMapRisks),
    takeLatest(actions.LOAD_MAP_SELECTED_RISK, loadMapSelectedRiskSaga),
    takeLatest(actions.LOAD_MAP_SELECTED_RISK_CLAIMS, loadMapSelectedRiskClaimsSaga),
    takeLatest(actions.GET_CLAIM, getClaimSaga),
    takeLatest(actions.UPDATE_CLAIM, updateClaimSaga),
    takeLatest(actions.UPDATE_CLAIM_STATUS, updateClaimStatusSaga),
    takeLatest(actions.UPDATE_CLAIM_STATUS_SUCCESS, updateClaimStatusSuccessSaga),
    takeLatest(actions.UPDATE_CLAIM_STATUS_ERROR, updateClaimStatusErrorSaga),
    takeLatest(actions.CREATE_CLAIM, createClaimSaga),
    takeLatest(actions.CREATE_CLAIM_SUCCESS, reloadClaimsSaga),
    takeLatest(actions.GET_CLAIM_HISTORY, getClaimHistorySaga),
    takeLatest(actions.GET_CLAIM_AUTHORIZED_ORGANIZATIONS, getClamAuthorizedOrganizationsSaga),
    takeEvery(actions.GET_CLAIM_ORGANIZATIONS_WITH_ACCESS, getClaimOrganizationsWithAccessSaga),
    takeEvery(actions.GET_PDF_REPORT, getPDFReportSaga),
    takeEvery(actions.GET_CLAIM_PDF_SUMMARY, getClaimPDFSummarySaga),
    takeLatest(actions.GET_POLICY_DETAILS_CLAIMS, getPolicyDetailsClaimsSaga)
  ]);
}
