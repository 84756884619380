import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MENU_ITEMS } from '../../consts/consts';
import checkUserRights from '../../helpers/checkUserUIRights';
import { windowMaxWidth } from '../ui/Layout/breakpoints';

const HeaderMenuWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 544px;
  width: 60%;

  @media ${windowMaxWidth.splitScreen} {
    display: none;
  }
`;

const MenuItem = styled.span`
  align-items: center;
  box-shadow: ${(props) => (props.$isActive ? 'inset 0 -4px 0 0 var(--greyscale-blackish)' : 'none')};
  color: ${(props) => (props.$isActive ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};
  cursor: default;
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: ${(props) => (props.$isActive ? 'var(--font-weight-medium)' : 'var(--font-weight-regular)')};
  height: 100%;
  line-height: var(--font-line-height-regular);
  margin: 0 15px;
  padding: 0 4px;
`;

const MenuLink = styled(Link)`
  color: ${(props) => (props.$isActive ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};

  &:hover {
    color: var(--greyscale-blackish);
  }
`;

export const HeaderMenu = ({ path, userRights }) => {
  // Find the position of the second '/' in the path
  const mainRootDividerIndex = path.indexOf('/', path.indexOf('/') + 1);
  // Get the root of the path (e.g. '/claims')
  const mainRoot = mainRootDividerIndex < 0 ? path : path.substring(0, mainRootDividerIndex);

  const renderedMenuItems = MENU_ITEMS.map((item) => {
    let isActive = item.mainPathRoot === mainRoot || item.detailsPathRoot === mainRoot;

    return (
      checkUserRights(userRights, item.accessRights) && (
        <MenuItem key={item.key} $isActive={isActive}>
          <MenuLink to={item.link} $isActive={isActive}>
            {item.label}
          </MenuLink>
        </MenuItem>
      )
    );
  });

  return <HeaderMenuWrapper>{renderedMenuItems}</HeaderMenuWrapper>;
};
