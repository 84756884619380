import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadInsuredRisks = async (params) =>
  axios
    .get(apiUrls.insuredRisksUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadInsuredRisk = async (id) =>
  axios
    .get(`${apiUrls.insuredRisksUrl}/${id}`)
    .then((res) => res)
    .catch((error) => error);

const onLoadClaimsInsuredRisks = async (params) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/insured-risks`, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadAvailableInsuredRisks = async (params) =>
  axios
    .get(apiUrls.insuredRisksPolicyUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadInsuredRisksToPolicies = async (params) =>
  axios
    .get(apiUrls.insuredRisksToPoliciesUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onAddInsuredRisksToPolicy = async (insuredRisks) =>
  axios
    .post(apiUrls.insuredRisksToPoliciesUrl, insuredRisks)
    .then((res) => res)
    .catch((error) => error);

const onUpdateInsuredRisksToPolicy = async (insuredRisks) =>
  axios
    .put(apiUrls.insuredRisksToPoliciesUrl, insuredRisks)
    .then((res) => res)
    .catch((error) => error);

const onDeleteInsuredRisksForPolicy = async (insuredRisks) =>
  axios
    .post(`${apiUrls.insuredRisksToPoliciesUrl}/delete-many`, insuredRisks)
    .then((res) => res)
    .catch((error) => error);

export {
  onLoadInsuredRisks,
  onLoadInsuredRisksToPolicies,
  onAddInsuredRisksToPolicy,
  onUpdateInsuredRisksToPolicy,
  onDeleteInsuredRisksForPolicy,
  onLoadAvailableInsuredRisks,
  onLoadClaimsInsuredRisks,
  onLoadInsuredRisk
};
