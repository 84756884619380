const actions = {
  SET_SPLITSCREEN_MODE: 'SET_SPLITSCREEN_MODE',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  TOGGLE_SIDEBAR_SUCCESS: 'TOGGLE_SIDEBAR_SUCCESS',
  setSplitscreenMode: (value) => ({
    type: actions.SET_SPLITSCREEN_MODE,
    value
  }),
  openSidebar: () => ({
    type: actions.OPEN_SIDEBAR
  }),
  closeSidebar: () => ({
    type: actions.CLOSE_SIDEBAR
  }),
  toggleSidebar: () => ({
    type: actions.TOGGLE_SIDEBAR
  }),
  toggleSidebarSuccess: () => ({
    type: actions.TOGGLE_SIDEBAR_SUCCESS
  })
};

export default actions;
