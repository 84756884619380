const actions = {
  LOAD_ORGANIZATIONS: 'LOAD_ORGANIZATIONS',
  LOAD_ORGANIZATIONS_SUCCESS: 'LOAD_ORGANIZATIONS_SUCCESS',
  LOAD_ORGANIZATIONS_ERROR: 'LOAD_ORGANIZATIONS_ERROR',
  LOAD_ORGANIZATION_ONE: 'LOAD_ORGANIZATION_ONE',
  LOAD_ORGANIZATION_ONE_SUCCESS: 'LOAD_ORGANIZATION_ONE_SUCCESS',
  LOAD_ORGANIZATION_ONE_ERROR: 'LOAD_ORGANIZATION_ONE_ERROR',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_ERROR: 'CREATE_ORGANIZATION_ERROR',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_ERROR: 'UPDATE_ORGANIZATION_ERROR',
  CLEAR_ORGANIZATION_ERRORS: 'CLEAR_ORGANIZATION_ERRORS',
  GET_EXTERNAL_CONTACTS: 'GET_EXTERNAL_CONTACTS',
  GET_EXTERNAL_CONTACTS_SUCCESS: 'GET_EXTERNAL_CONTACTS_SUCCESS',
  GET_EXTERNAL_CONTACTS_ERROR: 'GET_EXTERNAL_CONTACTS_ERROR',
  CREATE_EXTERNAL_CONTACTS: 'CREATE_EXTERNAL_CONTACTS',
  CREATE_EXTERNAL_CONTACTS_SUCCESS: 'CREATE_EXTERNAL_CONTACTS_SUCCESS',
  CREATE_EXTERNAL_CONTACTS_ERROR: 'CREATE_EXTERNAL_CONTACTS_ERROR',
  UPDATE_EXTERNAL_CONTACTS: 'UPDATE_EXTERNAL_CONTACTS',
  UPDATE_EXTERNAL_CONTACTS_SUCCESS: 'UPDATE_EXTERNAL_CONTACTS_SUCCESS',
  UPDATE_EXTERNAL_CONTACTS_ERROR: 'UPDATE_EXTERNAL_CONTACTS_ERROR',
  DELETE_EXTERNAL_CONTACTS: 'DELETE_EXTERNAL_CONTACTS',
  DELETE_EXTERNAL_CONTACTS_SUCCESS: 'DELETE_EXTERNAL_CONTACTS_SUCCESS',
  DELETE_EXTERNAL_CONTACTS_ERROR: 'DELETE_EXTERNAL_CONTACTS_ERROR',
  GET_MESSAGE_TEMPLATES: 'GET_MESSAGE_TEMPLATES',
  GET_MESSAGE_TEMPLATES_ERROR: 'GET_MESSAGE_TEMPLATES_ERROR',
  GET_MESSAGE_TEMPLATES_SUCCESS: 'GET_MESSAGE_TEMPLATES_SUCCESS',
  CREATE_MESSAGE_TEMPLATE: 'CREATE_MESSAGE_TEMPLATE',
  CREATE_MESSAGE_TEMPLATE_ERROR: 'CREATE_MESSAGE_TEMPLATE_ERROR',
  CREATE_MESSAGE_TEMPLATE_SUCCESS: 'CREATE_MESSAGE_TEMPLATE_SUCCESS',
  UPDATE_MESSAGE_TEMPLATE: 'UPDATE_MESSAGE_TEMPLATE',
  UPDATE_MESSAGE_TEMPLATE_ERROR: 'UPDATE_MESSAGE_TEMPLATE_ERROR',
  UPDATE_MESSAGE_TEMPLATE_SUCCESS: 'UPDATE_MESSAGE_TEMPLATE_SUCCESS',
  DELETE_MESSAGE_TEMPLATE: 'DELETE_MESSAGE_TEMPLATE',
  DELETE_MESSAGE_TEMPLATE_ERROR: 'DELETE_MESSAGE_TEMPLATE_ERROR',
  DELETE_MESSAGE_TEMPLATE_SUCCESS: 'DELETE_MESSAGE_TEMPLATE_SUCCESS',
  GET_PREDEFINED_MESSAGE_TEMPLATES: 'GET_PREDEFINED_MESSAGE_TEMPLATES',
  GET_PREDEFINED_MESSAGE_TEMPLATES_ERROR: 'GET_PREDEFINED_MESSAGE_TEMPLATES_ERROR',
  GET_PREDEFINED_MESSAGE_TEMPLATES_SUCCESS: 'GET_PREDEFINED_MESSAGE_TEMPLATES_SUCCESS',
  CLEAR_MESSAGE_TEMPLATES_ERRORS: 'CLEAR_MESSAGE_TEMPLATES_ERRORS',
  SET_SELECTED_ORGANIZATION: 'SET_SELECTED_ORGANIZATION',
  SET_SELECTED_ORGANIZATION_SUCCESS: 'SET_SELECTED_ORGANIZATION_SUCCESS',

  getAllOrganizations: () => ({
    type: actions.LOAD_ORGANIZATIONS
  }),
  getAllOrganizationsSuccess: (organizations) => ({
    type: actions.LOAD_ORGANIZATIONS_SUCCESS,
    organizations
  }),
  getAllOrganizationsError: () => ({
    type: actions.LOAD_ORGANIZATIONS_ERROR
  }),
  getOneOrganization: (organizationId) => ({
    type: actions.LOAD_ORGANIZATION_ONE,
    organizationId
  }),
  getOneOrganizationSuccess: (organization) => ({
    type: actions.LOAD_ORGANIZATION_ONE_SUCCESS,
    organization
  }),
  getOneOrganizationError: () => ({
    type: actions.LOAD_ORGANIZATION_ONE_ERROR
  }),
  createOrganization: (payload, callback) => ({
    type: actions.CREATE_ORGANIZATION,
    payload,
    callback
  }),
  createOrganizationSuccess: (newSelectedOrganization) => ({
    type: actions.CREATE_ORGANIZATION_SUCCESS,
    newSelectedOrganization
  }),
  createOrganizationError: () => ({
    type: actions.CREATE_ORGANIZATION_ERROR
  }),
  updateOrganization: (organizationData, callback) => ({
    type: actions.UPDATE_ORGANIZATION,
    organizationData,
    callback
  }),
  updateOrganizationSuccess: (newSelectedOrganization) => ({
    type: actions.UPDATE_ORGANIZATION_SUCCESS,
    newSelectedOrganization
  }),
  updateOrganizationError: () => ({
    type: actions.UPDATE_ORGANIZATION_ERROR
  }),
  clearOrganizationErrors: () => ({
    type: actions.CLEAR_ORGANIZATION_ERRORS
  }),
  setSelectedOrganization: (organization) => ({
    type: actions.SET_SELECTED_ORGANIZATION,
    organization
  }),
  setSelectedOrganizationSuccess: (organization) => ({
    type: actions.SET_SELECTED_ORGANIZATION_SUCCESS,
    organization
  }),

  // ----------------- External Contacts Actions-----------------
  getExternalContacts: (organization_id) => ({
    type: actions.GET_EXTERNAL_CONTACTS,
    organization_id
  }),
  getExternalContactsSuccess: (externalContacts) => ({
    type: actions.GET_EXTERNAL_CONTACTS_SUCCESS,
    externalContacts
  }),
  getExternalContactsError: () => ({
    type: actions.GET_EXTERNAL_CONTACTS_ERROR
  }),
  createExternalContacts: (externalContacts, callback) => ({
    type: actions.CREATE_EXTERNAL_CONTACTS,
    externalContacts,
    callback
  }),
  createExternalContactsSuccess: (externalContacts) => ({
    type: actions.CREATE_EXTERNAL_CONTACTS_SUCCESS,
    externalContacts
  }),
  createExternalContactsError: () => ({
    type: actions.CREATE_EXTERNAL_CONTACTS_ERROR
  }),
  updateExternalContacts: (externalContacts, callback) => ({
    type: actions.UPDATE_EXTERNAL_CONTACTS,
    externalContacts,
    callback
  }),
  updateExternalContactsSuccess: (externalContacts) => ({
    type: actions.UPDATE_EXTERNAL_CONTACTS_SUCCESS,
    externalContacts
  }),
  updateExternalContactsError: () => ({
    type: actions.UPDATE_EXTERNAL_CONTACTS_ERROR
  }),
  deleteExternalContacts: (externalContactsIds) => ({
    type: actions.DELETE_EXTERNAL_CONTACTS,
    externalContactsIds
  }),
  deleteExternalContactsSuccess: () => ({
    type: actions.DELETE_EXTERNAL_CONTACTS_SUCCESS
  }),
  deleteExternalContactsError: () => ({
    type: actions.DELETE_EXTERNAL_CONTACTS_ERROR
  }),
  // ----------------- Message Templates Actions-----------------
  getMessageTemplates: (params) => ({
    type: actions.GET_MESSAGE_TEMPLATES,
    params
  }),
  getMessageTemplatesSuccess: (templates) => ({
    type: actions.GET_MESSAGE_TEMPLATES_SUCCESS,
    templates
  }),
  getMessageTemplatesError: () => ({
    type: actions.GET_MESSAGE_TEMPLATES_ERROR
  }),
  getPredefinedMessageTemplates: () => ({
    type: actions.GET_PREDEFINED_MESSAGE_TEMPLATES
  }),
  getPredefinedMessageTemplatesSuccess: (templates) => ({
    type: actions.GET_PREDEFINED_MESSAGE_TEMPLATES_SUCCESS,
    templates
  }),
  getPredefinedMessageTemplatesError: () => ({
    type: actions.GET_PREDEFINED_MESSAGE_TEMPLATES_ERROR
  }),
  createMessageTemplate: ({ organization_id, name, text }, callback) => ({
    type: actions.CREATE_MESSAGE_TEMPLATE,
    organization_id,
    name,
    text,
    callback
  }),
  createMessageTemplateSuccess: () => ({
    type: actions.CREATE_MESSAGE_TEMPLATE_SUCCESS
  }),
  createMessageTemplateError: () => ({
    type: actions.CREATE_MESSAGE_TEMPLATE_ERROR
  }),
  updateMessageTemplate: (payload, callback) => ({
    type: actions.UPDATE_MESSAGE_TEMPLATE,
    payload,
    callback
  }),
  updateMessageTemplateSuccess: () => ({
    type: actions.UPDATE_MESSAGE_TEMPLATE_SUCCESS
  }),
  updateMessageTemplateError: () => ({
    type: actions.UPDATE_MESSAGE_TEMPLATE_ERROR
  }),
  deleteMessageTemplate: (id) => ({
    type: actions.DELETE_MESSAGE_TEMPLATE,
    id
  }),
  deleteMessageTemplateSuccess: () => ({
    type: actions.DELETE_MESSAGE_TEMPLATE_SUCCESS
  }),
  deleteMessageTemplateError: () => ({
    type: actions.DELETE_MESSAGE_TEMPLATE_ERROR
  })
};

export default actions;
