import styled from 'styled-components';
import { windowMaxWidth } from '../../components/ui/Layout/breakpoints';

const InitialSigninWrapper = styled.div`
  .initial-signin-button {
    margin: 20px 40px 0;
    padding-bottom: 40px;
  }

  .initial-signin-password-input {
    margin: 0 40px 20px;

    .ant-form-item-label {
      label {
        margin-left: 10px;
      }
    }
  }

  @media (${windowMaxWidth.mobile}) {
    .initial-signin-password-input {
      margin: 0 0 20px;
    }
  }
`;

const EmailWrapper = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-regular);
  margin-bottom: 15px;
  margin-left: 10px;
  padding: 0 40px;

  @media (${windowMaxWidth.mobile}) {
    padding: 0;
    margin-left: 0;
  }
`;

const EmailLabel = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 0 40px;

  @media (${windowMaxWidth.mobile}) {
    padding: 0;
    margin-left: 0;
  }
`;

const GreetingMessageWrapper = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 20px;
  padding: 40px 40px 0;
  text-align: justify;

  @media (${windowMaxWidth.mobile}) {
    padding: 40px 0 0;
  }
`;

const UserAgreementMessageWrapper = styled.div`
  padding: 20px 40px 0;

  .ant-checkbox {
    top: 10px;

    + span {
      color: var(--greyscale-blackish);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      line-height: var(--font-line-height-small);
      padding: 0 0 0 10px;

      a {
        color: var(--primary-oqio-blue);

        :hover {
          color: var(--primary-oqio-blue);
        }
      }
    }
  }

  @media (${windowMaxWidth.mobile}) {
    padding: 20px 00 0;
  }
`;

const SubmitButtonWrapper = styled.div`
  padding: 20px 40px 40px;

  @media (${windowMaxWidth.mobile}) {
    padding: 20px 0 40px;
  }
`;

export {
  InitialSigninWrapper,
  EmailWrapper,
  EmailLabel,
  GreetingMessageWrapper,
  UserAgreementMessageWrapper,
  SubmitButtonWrapper
};
