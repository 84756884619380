import React, { useEffect, useState } from 'react';
import { Table, Checkbox } from '../ui';
import {
  ImportTableWrapper,
  ImportTableCheckboxesWrapper,
  ImportTableCheckboxesList,
  ImportTableSectionWrapper
} from '../ui/Layout/ImportLayout';

/**
 * This component is the second step of the import process.
 * It renders the import table contining the processed data from the import file
 * plus a status column that marks if a row is valid or not.
 * @param {Array} importTableColumns
 * @param {Array} importTableData
 */
export const ImportTableSection = ({ importTableColumns, importTableData }) => {
  // This variable is used to determine the height of the table body (the table header is fixed)
  // The 208px are the sum of the page header (100px), the table columns headers (41px),
  // the checkboxes (45px), the 20px bottom margin and the 2px border
  const heightOfComponentsOutsideTable = 208;
  const [tableBodyHeight, setTableBodyHeight] = useState(window.innerHeight - heightOfComponentsOutsideTable);

  const [activeColumns, setActiveColumns] = useState([]);
  const [importColumnsCheckboxes, setImportColumnsCheckboxes] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setTableBodyHeight(window.innerHeight - heightOfComponentsOutsideTable);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // In the following useEffects a logic is implemented to hide/show columns in the table, based on checkboxes
  useEffect(() => {
    // The invalid columns checkboxes are unchecked by default
    const columnsCheckboxes = importTableColumns.map((column) => ({
      key: column.dataIndex,
      label: column.title,
      checked: column.className !== 'invalid-column',
      className: column.className === 'invalid-column' ? 'checkbox-invalid-column' : ''
    }));
    setImportColumnsCheckboxes(columnsCheckboxes);
  }, [importTableColumns]);

  // Based on the checkboxes, the columns are hidden or shown
  useEffect(() => {
    const activeColumns = importTableColumns.map((column) => {
      const columnCheckbox = importColumnsCheckboxes.find((checkbox) => checkbox.key === column.dataIndex);
      const isColumnHidden = !columnCheckbox?.checked;
      return { ...column, hidden: isColumnHidden };
    });
    setActiveColumns(activeColumns);
  }, [importColumnsCheckboxes]);

  const handleCheckboxClick = (key) => {
    const updatedCheckboxes = importColumnsCheckboxes.map((checkbox) => {
      if (checkbox.key === key) {
        return { ...checkbox, checked: !checkbox.checked };
      }
      return checkbox;
    });
    setImportColumnsCheckboxes(updatedCheckboxes);
  };

  const renderColumnCheckboxes = () => {
    return importColumnsCheckboxes.map((checkbox) => {
      return (
        <Checkbox
          key={checkbox.key}
          className={checkbox.className}
          checked={checkbox.checked}
          onChange={() => handleCheckboxClick(checkbox.key)}
        >
          {checkbox.label}
        </Checkbox>
      );
    });
  };

  const getRowClassName = (record) => {
    return record.valid_row ? 'valid-row' : 'invalid-row';
  };

  return (
    <ImportTableSectionWrapper>
      <ImportTableCheckboxesWrapper>
        <ImportTableCheckboxesList>{renderColumnCheckboxes()}</ImportTableCheckboxesList>
      </ImportTableCheckboxesWrapper>
      <ImportTableWrapper>
        <Table
          virtual
          scroll={{ y: tableBodyHeight }}
          rowHoverable={false}
          pagination={false}
          columns={activeColumns}
          dataSource={importTableData}
          rowClassName={getRowClassName}
        />
      </ImportTableWrapper>
    </ImportTableSectionWrapper>
  );
};
