import React from 'react';

const IconSuccess = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.14258 14.2308L14.0997 26.5385L27.8569 1.9231"
        stroke="#627FFF"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
export default IconSuccess;
