import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onGetFileContainers = async (params) =>
  axios
    .get(apiUrls.fileContainersBaseUrl, { params })
    .then((response) => response)
    .catch((error) => error);

export { onGetFileContainers };
