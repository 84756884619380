import React from 'react';

const IconPaperclip = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.021 8.712c1.287-1.29 1.287-3.443 0-4.733-1.29-1.287-3.443-1.287-4.733 0l-7.31 7.31c-1.286 1.29-1.286 3.443 0 4.732 1.29 1.287 3.444 1.287 4.734 0l4.632-4.727a1.846 1.846 0 0 0 0-2.582 1.846 1.846 0 0 0-2.583 0l-4.87 4.961"
      stroke="#111"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconPaperclip;
