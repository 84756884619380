import React from 'react';

const IconEyeView = ({ size }) => {
  const large = (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 10s-3.583 5-8 5-8-5-8-5 3.583-5 8-5 8 5 8 5z"
        stroke="#111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path className="fill-path" d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" fill="#111" />
    </svg>
  );
  const small = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 10s-3.583 5-8 5-8-5-8-5 3.583-5 8-5 8 5 8 5z"
        stroke="#111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path className="fill-path" d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" fill="#111" />
    </svg>
  );
  const tiny = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 10s-3.583 5-8 5-8-5-8-5 3.583-5 8-5 8 5 8 5z"
        stroke="#111"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path className="fill-path" d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" fill="#111" />
    </svg>
  );

  switch (size) {
    case 'tiny':
      return tiny;
    case 'small':
      return small;
    case 'large':
      return large;
    default:
      return tiny;
  }
};

export default IconEyeView;
