import React from 'react';
import AntDatePicker from 'antd/es/date-picker';
import styled from 'styled-components';
import { IconCalendar, IconClose } from '../Icons';

const StyledDatePicker = styled(AntDatePicker)`
  &.ant-picker {
    background-color: var(--greyscale-porcelain);
    border-radius: var(--border-radius-small);
    padding: 0 10px;
    border: 1px solid var(--greyscale-concrete);
    width: 100%;

    &-focused {
      border: 1px solid var(--primary-oqio-blue);
      box-shadow: var(--box-shadow-oqio-blue);
    }

    &:hover:not(.ant-picker-disabled) {
      border: 1px solid var(--primary-oqio-blue);
    }

    &.ant-picker-status-error {
      box-shadow: var(--box-shadow-oqio-red);
      background-color: var(--greyscale-porcelain);

      &:hover {
        box-shadow: var(--box-shadow-oqio-blue);
        background-color: var(--greyscale-porcelain);
      }

      &.ant-picker-focused {
        box-shadow: var(--box-shadow-oqio-blue);
        background-color: var(--greyscale-porcelain);
        border: 1px solid var(--primary-oqio-blue);
      }
    }

    &.ant-picker-disabled {
      cursor: default;

      svg {
        path {
          stroke: var(--greyscale-concrete);
        }
      }
    }
  }

  .ant-picker-input {
    input {
      background-color: var(--greyscale-porcelain);
      background-image: none;
      border-radius: var(--border-radius-small);
      color: var(--greyscale-blackish);
      cursor: text;
      font-size: var(--font-size-regular);
      height: 40px;
      line-height: 1.5;
      width: 100%;

      &::placeholder {
        color: var(--greyscale-shade);
      }

      &:disabled {
        background-color: var(--greyscale-porcelain);
        color: var(--greyscale-concrete) !important;
        cursor: default;

        &::placeholder {
          color: var(--greyscale-concrete) !important;
        }
      }
    }

    .ant-calendar-picker-icon {
      color: var(--primary-oqio-blue);
      font-size: var(--font-size-medium);
      height: 14px;
      line-height: 14px;
      margin-top: -7px;
      position: absolute;
      right: 12px;
      top: 50%;
      transition: all 0.3s;
      user-select: none;
      width: 14px;
      z-index: 1;
    }

    .ant-calendar-picker-clear {
      background-color: var(--greyscale-porcelain);
      color: var(--primary-oqio-blue);
      cursor: pointer;
      font-size: var(--font-size-regular);
      opacity: 0;
      pointer-events: none;
      z-index: 2;
    }
  }

  &.ant-picker-disabled {
    background-color: var(--greyscale-porcelain);
    border: 1px solid var(--greyscale-dark-white);
    color: var(--greyscale-concrete) !important;
    cursor: default;

    &::placeholder {
      color: var(--greyscale-concrete);
    }
  }
`;

const calendarIcon = <IconCalendar />;

const DatePicker = (props) => (
  <StyledDatePicker
    placeholder="TT.MM.JJJJ"
    format="DD.MM.YYYY"
    suffixIcon={calendarIcon}
    {...props}
    allowClear={{ clearIcon: <IconClose /> }}
  />
);

const DatePickerRange = (props) => (
  <StyledDatePicker.RangePicker
    format="DD.MM.YYYY"
    ranges={[]}
    separator="."
    {...props}
    allowClear={{ clearIcon: <IconClose /> }}
  />
);

export { DatePickerRange, DatePicker };
