import React from 'react';

const IconCircledMinus = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 10h8" stroke="#111" strokeWidth="1.5" />
      <circle cx="10" cy="10" r="8" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconCircledMinus;
