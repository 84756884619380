const actions = {
  GET_CONFIG: 'GET_CONFIG',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_ERROR: 'GET_CONFIG_ERROR',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_CONFIG_SUCCESS: 'UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_ERROR: 'UPDATE_CONFIG_ERROR',
  getConfig: () => ({
    type: actions.GET_CONFIG
  }),
  getConfigSuccess: (payload) => ({
    type: actions.GET_CONFIG_SUCCESS,
    payload
  }),
  getConfigError: (error) => ({
    type: actions.GET_CONFIG_ERROR,
    error
  }),
  updateConfig: (payload) => ({
    type: actions.UPDATE_CONFIG,
    payload
  }),
  updateConfigSuccess: (payload) => ({
    type: actions.UPDATE_CONFIG_SUCCESS,
    payload
  }),
  updateConfigError: (error) => ({
    type: actions.UPDATE_CONFIG_ERROR,
    error
  })
};

export default actions;
