import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  uploadsTempContainer: [],
  uploadFileLoading: false,
  uploadFileError: null,
  fileUploadForMessageAttachmentsLoading: false,
  fileUploadForMessageAttachmentsError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.UPLOAD_FILE:
      return state.set('uploadFileLoading', true).set('uploadFileError', false);
    case actions.UPLOAD_FILE_SUCCESS:
      return state.set('uploadFileLoading', false).set('uploadFileError', false);
    case actions.UPLOAD_FILE_ERROR:
      return state.set('uploadFileLoading', false).set('uploadFileError', true);
    case actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS:
      return state
        .set('fileUploadForMessageAttachmentsError', false)
        .set('fileUploadForMessageAttachmentsLoading', true);
    case actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_SUCCESS:
      return state
        .set('fileUploadForMessageAttachmentsError', false)
        .set('fileUploadForMessageAttachmentsLoading', false);
    case actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_ERROR:
      return state
        .set('fileUploadForMessageAttachmentsError', true)
        .set('fileUploadForMessageAttachmentsLoading', false);
    case actions.SET_UPLOADS_TEMP_CONTAINER:
      return state.set('uploadsTempContainer', action.uploads);
    case actions.CLEAR_UPLOADS_TEMP_CONTAINER:
      return state.set('uploadsTempContainer', []);
    default:
      return state;
  }
};

export default reducer;
