import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import queryString from 'query-string';
import FileSaver from 'file-saver';
import { onLoadAll } from '../policies/apiCalls';
import policiesToRiskActions from '../policiesToRisks/actions';
import worklistRisksActions from '../worklistRisks/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import newEntityNotification from '../../helpers/notifications/newEntityNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { getFileNameFromApiResponseHeader } from '../../helpers/utility';
import actions from './actions';
import {
  onCreateRisks,
  onUpdateRisks,
  onGetRisks,
  onGetRisk,
  onExportRisks,
  onGetRisksHousingStocks,
  onGetRisksCompanyCodes,
  onGetRisksEconomicUnits,
  onDeleteRisks
} from './apiCalls';

export function* createRisksSaga({ risks, linkedPolicies, callback, navigate }) {
  try {
    const response = yield call(onCreateRisks, risks);
    if (response.data) {
      if (linkedPolicies && linkedPolicies.length > 0) {
        const { id } = response.data[0];
        const riskToPolicies = linkedPolicies.map((policy) => {
          return { risk_id: id, policy_id: policy.id };
        });
        yield put(policiesToRiskActions.createPoliciesToRisks(riskToPolicies, false));
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
      newEntityNotification({
        entityType: 'risk',
        entityId: response.data[0].id,
        navigate
      });
      yield put(actions.createRisksSuccess(response.data));
    } else {
      yield put(actions.createRisksError());
    }
  } catch (error) {
    yield put(actions.createRisksError());
  }
}

export function* updateRisksSaga({ risks, callback }) {
  try {
    const response = yield call(onUpdateRisks, risks);
    if (response.data) {
      yield put(actions.updateRisksSuccess(response.data));

      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateRisksError());
    }
  } catch (error) {
    yield put(actions.updateRisksError());
  }
}

export function* deleteRisksSaga({ payload, callback }) {
  try {
    const response = yield call(onDeleteRisks, payload);
    if (response.status === 200) {
      yield put(actions.deleteRisksSuccess(response.data));

      if (callback && typeof callback === 'function') {
        callback();
      }
      const { risk_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: risk_delete_success });
    } else {
      yield put(actions.deleteRisksError());
    }
  } catch (error) {
    yield put(actions.deleteRisksError());
  }
}

export function* getRisksSaga({ params }) {
  try {
    const response = yield call(onGetRisks, params);
    if (response.data) {
      const risksSuccessData = {
        perPage: response.headers.perpage,
        total: response.headers.total
      };
      yield all([put(actions.getRisksSuccess(response.data, risksSuccessData))]);
    } else {
      yield put(actions.getRisksError());
    }
  } catch (error) {
    yield put(actions.getRisksError());
  }
}

export function* getAllRisksSaga() {
  try {
    const filters = yield select(getActiveFilters);
    const params = queryString.parse(filters);
    params.paging = false;
    const response = yield call(onGetRisks, params);
    if (response.data) {
      yield put(actions.getAllRisksSuccess(response.data));
    } else {
      yield put(actions.getAllRisksError());
    }
  } catch (error) {
    yield put(actions.getAllRisksError());
  }
}

export function* getRiskSaga({ riskId }) {
  try {
    const response = yield call(onGetRisk, riskId);
    if (response.data) {
      yield all([
        put(actions.getRiskSuccess(response.data)),
        put(worklistRisksActions.getWorklistRiskMessages(riskId))
      ]);
    } else {
      yield put(actions.getRiskError());
    }
  } catch (error) {
    yield put(actions.getRiskError());
  }
}

export function* exportRisksSaga({ params }) {
  try {
    const getRisksResponse = yield call(onGetRisks, { ...params, paging: false });
    const risksIds = getRisksResponse.data.map((risk) => risk.id);
    const response = yield call(onExportRisks, risksIds);

    if (response.status === 200) {
      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      // only IE does support this method
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        FileSaver.saveAs(blob);
      }

      yield put(actions.exportRisksSuccess());
    } else {
      yield put(actions.exportRisksError());
    }
  } catch {
    yield put(actions.exportRisksError());
  }
}

export function* getRisksCompanyCodesSaga() {
  try {
    const response = yield call(onGetRisksCompanyCodes);
    if (response.data) {
      yield put(actions.getRisksCompanyCodesSuccess(response.data));
    } else {
      yield put(actions.getRisksCompanyCodesError());
    }
  } catch (error) {
    yield put(actions.getRisksCompanyCodesError());
  }
}

export function* getRisksEconomicUnitsSaga() {
  try {
    const response = yield call(onGetRisksEconomicUnits);
    if (response.data) {
      yield put(actions.getRisksEconomicUnitsSuccess(response.data));
    } else {
      yield put(actions.getRisksEconomicUnitsError());
    }
  } catch (error) {
    yield put(actions.getRisksEconomicUnitsError());
  }
}

export function* getRisksHousingStocksSaga() {
  try {
    const response = yield call(onGetRisksHousingStocks);
    if (response.data) {
      yield put(actions.getRisksHousingStocksSuccess(response.data));
    } else {
      yield put(actions.getRisksHousingStocksError());
    }
  } catch (error) {
    yield put(actions.getRisksHousingStocksError());
  }
}

export function* getRiskConnectedPoliciesSaga({ riskId }) {
  try {
    const response = yield call(onLoadAll, { risk_id: riskId, paging: false });
    if (response.data) {
      yield put(actions.getConnectedPoliciesSuccess(response.data));
    } else {
      yield put(actions.getConnectedPoliciesError());
    }
  } catch (error) {
    yield put(actions.getConnectedPoliciesError());
  }
}

export const getRisk = (state) => state.Risks.get('risk');
export const getActiveFilters = (state) => state.Filters.get('risksActiveFilters');

/**
 * Re-fetches the risk after an update on its fields
 */
export function* reloadRiskSaga() {
  const risk = yield select(getRisk);
  const { id } = risk;
  yield put(actions.getRisk(id));
}

/**
 * re-fetches the risks after creating a new one
 * the update must respect the current filters
 */
export function* reloadAllRisksSaga() {
  const filters = yield select(getActiveFilters);
  const params = queryString.parse(filters);
  yield all([
    put(actions.getRisks(params)),
    // The filter options should be fetched on every risks reload,
    // as creating or editing risks might affect the filter options.
    put(actions.getRisksHousingStocks()),
    put(actions.getRisksCompanyCodes()),
    put(actions.getRisksEconomicUnits())
  ]);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_RISKS, createRisksSaga),
    takeEvery(actions.CREATE_RISKS_SUCCESS, reloadAllRisksSaga),
    takeEvery(actions.UPDATE_RISKS, updateRisksSaga),
    takeEvery(actions.UPDATE_RISKS_SUCCESS, reloadRiskSaga),
    takeEvery(actions.DELETE_RISKS, deleteRisksSaga),
    takeEvery(actions.GET_RISKS, getRisksSaga),
    takeEvery(actions.GET_RISK, getRiskSaga),
    takeEvery(actions.GET_ALL_RISKS, getAllRisksSaga),
    takeEvery(actions.EXPORT_RISKS, exportRisksSaga),
    takeEvery(actions.GET_RISKS_HOUSING_STOCKS, getRisksHousingStocksSaga),
    takeEvery(actions.GET_RISKS_COMPANY_CODES, getRisksCompanyCodesSaga),
    takeEvery(actions.GET_RISKS_ECONOMIC_UNITS, getRisksEconomicUnitsSaga),
    takeEvery(actions.GET_CONNECTED_POLICIES, getRiskConnectedPoliciesSaga)
  ]);
}
