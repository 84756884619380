import React from 'react';

const IconOrganization = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 3.208S6.856 3 6.5 3A2.5 2.5 0 0 0 4 5.5C4 6.71 5 8 6 7.95 3.876 8.02 2 9.594 2 12v1h2"
      stroke="#111"
      strokeWidth="1.5"
    />
    <circle cx="10" cy="7" r="3" stroke="#111" strokeWidth="1.5" />
    <path
      d="M5 16v-.857c0-.96.228-1.83.628-2.572C6.482 10.991 8.12 10 10 10s3.518.99 4.372 2.571c.4.741.628 1.612.628 2.572V16H5zM12.5 3.208A2.5 2.5 0 0 1 16 5.5c0 1.21-1 2.5-2 2.45 2.124.07 4 1.644 4 4.05v1h-2"
      stroke="#111"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconOrganization;
