import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  kpis: {},
  kpisLoading: false,
  kpisError: false,
  profitabilityKpis: {},
  profitabilityKpisLoading: false,
  profitabilityKpisError: false,
  premiums: [],
  getPremiumsLoading: false,
  getPremiumsError: false,
  setPremiumsLoading: false,
  setPremiumsError: false,
  totalSettlements: 0,
  showPremiumsModal: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_KPIS:
      return state.set('kpisLoading', true);
    case actions.LOAD_KPIS_SUCCESS:
      return state
        .set('kpis', action.kpis)
        .set('totalSettlements', parseInt(action.kpis.settlements_total, 10))
        .set('kpisLoading', false);
    case actions.LOAD_KPIS_ERROR:
      return state.set('kpisError', true).set('kpisLoading', false);
    case actions.LOAD_PROFITABILITY_KPIS:
      return state.set('profitabilityKpisLoading', true);
    case actions.LOAD_PROFITABILITY_KPIS_SUCCESS:
      return state.set('profitabilityKpis', action.kpis).set('profitabilityKpisLoading', false);
    case actions.LOAD_PROFITABILITY_KPIS_ERROR:
      return state.set('profitabilityKpisError', true).set('profitabilityKpisLoading', false);
    case actions.SET_PREMIUM_KPIS:
      return state.set('setPremiumsLoading', true);
    case actions.SET_PREMIUM_KPIS_SUCCESS:
      return state.set('setPremiumsLoading', false);
    case actions.SET_PREMIUM_KPIS_ERROR:
      return state.set('setPremiumsError', true).set('setPremiumsLoading', false);
    case actions.CLEAR_SET_PREMIUM_KPIS_ERROR:
      return state.set('setPremiumsError', false);
    case actions.GET_PREMIUMS:
      return state.set('getPremiumsLoading', true);
    case actions.GET_PREMIUMS_SUCCESS:
      return state.set('getPremiumsLoading', false).set('premiums', action.premiums).set('getPremiumsError', false);
    case actions.GET_PREMIUMS_ERROR:
      return state.set('getPremiumsError', true).set('getPremiumsLoading', false);
    case actions.SET_SHOW_PREMIUMS_MODAL:
      return state.set('showPremiumsModal', true);
    case actions.SET_HIDE_PREMIUMS_MODAL:
      return state.set('showPremiumsModal', false);
    default:
      return state;
  }
};

export default reducer;
