import React from 'react';
import { notification } from 'antd';
import { IconCircledCheck, IconClose, IconInfo, IconWarning } from '../../components/ui/Icons';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';

const getTypeIcon = (type) => {
  switch (type) {
    case 'success':
      return <IconCircledCheck />;
    case 'error':
      return <IconWarning />;
    case 'info':
      return <IconInfo />;
    default:
      return <IconInfo />;
  }
};

const getKey = (message) => {
  switch (message) {
    case NOTIFICATION_MESSAGES.password_forgot_success:
      return 'notification-forgot-password';
    default:
      return undefined;
  }
};

/* Style of the notifications is in global.css */

const renderNotification = ({ type, message, duration, ...props }) =>
  notification[type]({
    key: getKey(message),
    message,
    duration: duration === undefined ? 5 : duration,
    icon: getTypeIcon(type),
    closeIcon: <IconClose />,
    ...props
  });

export default renderNotification;
