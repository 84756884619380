import React from 'react';

const IconUpload = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 8v10M13.5 10.5 10 7l-3.5 3.5" stroke="#111" strokeWidth="1.5" />
    <path
      d="M13 13h1.304C16.337 13 18 11.477 18 9.617c0-1.861-1.663-3.384-3.696-3.384C14.194 3.797 12.661 1.9 10 2c-2.66.102-4.415 2.135-4.304 4.572v.676H5.14c-1.663 0-3.03 1.184-3.141 2.707V13h5"
      stroke="#111"
      strokeWidth="1.5"
    />
  </svg>
);
export default IconUpload;
