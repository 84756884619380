import { Skeleton } from 'antd';
import styled from 'styled-components';
import { windowMaxWidth } from '../Layout/breakpoints';

const ExtendedSkeleton = styled(Skeleton)`
  &.details {
    .ant-skeleton-title {
      height: 20px;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-button {
      &.ant-skeleton-button-lg {
        margin-right: 6px;
        width: 120px;
      }
    }

    &.history {
      .ant-skeleton-title {
        margin-bottom: 0;
        margin-top: 5px;
      }

      .ant-skeleton-paragraph {
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }

    &.settlements {
      .ant-skeleton-title {
        margin-bottom: 5px;
        margin-top: 5px;
      }

      .ant-skeleton-paragraph {
        margin-bottom: 5px;
        margin-top: 5px;
      }

      &.top {
        .ant-skeleton-paragraph {
          margin-bottom: 4px;
          margin-top: 10px;
        }
      }

      &.bottom {
        .ant-skeleton-paragraph {
          margin-bottom: 10px;
          margin-top: 4px;
        }
      }
    }
  }

  &.details-header-title {
    h3 {
      height: var(--font-line-height-regular);
      margin-top: 0;
    }

    ul {
      margin: 0 !important;
    }
  }

  &.worklist-claim-messages-list-title {
    h3 {
      height: var(--font-line-height-regular);
      margin-top: 0;
    }
  }

  &.worklist-claim-message-header-title {
    h3 {
      height: var(--font-line-height-large);
      margin-top: 0;
    }
  }

  &.details-tabs-menu-item {
    .ant-skeleton-content {
      align-items: center;
      display: flex;

      h3 {
        height: var(--font-line-height-regular);
        margin: 0;
      }
    }
  }

  &.worklist-claim-messages-list-text {
    ul {
      margin: 0;
    }
  }
`;

const ExtendedSkeletonButton = styled(Skeleton.Button)`
  &.details-header-actions-cta {
    margin-right: 5px;
    width: 100px !important;
  }

  &.details-header-actions-small {
    min-width: 40px !important;
    width: 40px !important;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  @media ${windowMaxWidth.splitScreen} {
    &.details-header-actions-cta {
      display: none;
    }

    &.details-header-actions-small {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
`;

const ExtendedSkeletonAvatar = styled(Skeleton.Avatar)`
  &.details-header-title {
    margin-right: 5px;
  }
`;

ExtendedSkeleton.Button = ExtendedSkeletonButton;
ExtendedSkeleton.Avatar = ExtendedSkeletonAvatar;

export default ExtendedSkeleton;
