import styled from 'styled-components';

const ResetPasswordTextWrapper = styled.div`
  margin-bottom: 20px;
  padding: 0 40px;
`;

const ResetPasswordStyleWrapper = styled.div`
  padding: 40px 0 20px;

  .reset-password-input {
    margin-bottom: 24px;
    padding: 0 40px;

    .ant-form-item-label {
      color: red;
      font-size: var(--font-size-small);
      line-height: var(--font-line-height-medium);
      margin: 0 2px 0 10px;
      padding: 0;

      label {
        margin: 0;
      }
    }
  }

  .reset-password-button-wrapper {
    display: flex;
    margin-top: 24px;
    padding: 0 40px;

    button {
      width: 100%;
    }
  }
`;

export { ResetPasswordStyleWrapper, ResetPasswordTextWrapper };
