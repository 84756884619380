import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  fetchDocumentLoading: false,
  fetchDocumentError: false,
  data: null
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.FETCH_DOCUMENT:
      return state.set('fetchDocumentLoading', true).set('fetchDocumentError', false);
    case actions.FETCH_DOCUMENT_SUCCESS:
      return state.set('fetchDocumentLoading', false).set('fetchDocumentError', false).set('data', action.data);
    case actions.FETCH_DOCUMENT_ERROR:
      return state.set('fetchDocumentLoading', false).set('fetchDocumentError', action.error);
    default:
      return state;
  }
};

export default reducer;
