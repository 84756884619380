import React from 'react';

const IconPolicy = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Ebene 2">
        <g id="Ebene 1">
          <path
            id="Vector"
            d="M19.499 39.499C30.268 39.499 38.998 30.769 38.998 20C38.998 9.23098 30.268 0.500977 19.499 0.500977C8.73 0.500977 0 9.23098 0 20C0 30.769 8.73 39.499 19.499 39.499Z"
            fill="#7A91F5"
          />
          <path
            id="Vector_2"
            d="M23.8445 16.3149C23.7289 16.3237 23.6211 16.3765 23.5432 16.4625L17.9048 22.5299L15.4807 19.7027C15.4411 19.6561 15.3927 19.6179 15.3382 19.5902C15.2838 19.5624 15.2244 19.5457 15.1634 19.5411C15.0413 19.5318 14.9204 19.5704 14.8263 19.6488C14.7329 19.7285 14.6748 19.8418 14.6646 19.9641C14.6594 20.0248 14.6665 20.0859 14.6853 20.1438C14.7042 20.2017 14.7344 20.2553 14.7744 20.3012L17.5377 23.5235C17.5805 23.573 17.6331 23.6131 17.6921 23.6413C17.7512 23.6695 17.8155 23.685 17.8809 23.6871C17.946 23.6879 18.0106 23.6749 18.0704 23.6492C18.1302 23.6234 18.1839 23.5853 18.2281 23.5374L24.2136 17.093C24.2774 17.0253 24.3193 16.94 24.3339 16.8482C24.3485 16.7564 24.3351 16.6623 24.2954 16.5782C24.256 16.4942 24.1919 16.4242 24.1117 16.3775C24.0315 16.3308 23.939 16.3097 23.8465 16.3169L23.8445 16.3149ZM19.499 10.8281C21.5979 11.6641 24.0361 12.508 27.785 12.6896V18.0766C27.785 21.7677 26.6956 24.2378 25.1155 25.9576C23.5672 27.6455 21.5241 28.6072 19.499 29.1878C17.4838 28.6072 15.4328 27.6455 13.8845 25.9576C12.3023 24.2378 11.215 21.7677 11.215 18.0766V12.6896C14.9639 12.508 17.4001 11.6621 19.499 10.8281ZM19.4431 9.87837C19.4035 9.88248 19.3646 9.89188 19.3274 9.90631C17.1187 10.7922 14.7385 11.678 10.7481 11.8057C10.6876 11.8065 10.628 11.8192 10.5725 11.8431C10.5169 11.867 10.4667 11.9017 10.4246 11.9451C10.3825 11.9884 10.3494 12.0397 10.3272 12.0959C10.305 12.1521 10.2941 12.2122 10.2952 12.2726C10.2952 13.0388 10.2952 17.3683 10.2952 18.0766C10.2952 21.9533 11.4703 24.6867 13.2082 26.5781C14.946 28.4696 17.2185 29.513 19.3773 30.1176C19.4576 30.1396 19.5424 30.1396 19.6227 30.1176C21.7815 29.519 24.056 28.4696 25.7918 26.5781C27.5276 24.6867 28.7048 21.9533 28.7048 18.0766C28.7048 17.3683 28.7048 13.0368 28.7048 12.2726C28.7049 12.1531 28.6585 12.0382 28.5755 11.9522C28.4925 11.8662 28.3793 11.8159 28.2599 11.8117C24.2575 11.686 21.8753 10.7982 19.6806 9.91429C19.6058 9.88217 19.524 9.8698 19.4431 9.87837Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconPolicy;
