import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadAll = async (params) =>
  axios
    .get(apiUrls.organisationsBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadOne = async (organizationId) =>
  axios
    .get(`${apiUrls.organisationsBaseUrl}/${organizationId}`)
    .then((res) => res)
    .catch((error) => error);

const onCreateOrganization = async (params) =>
  axios
    .post(apiUrls.organisationsBaseUrl, params)
    .then((res) => res)
    .catch((error) => error);

const onUpdateOrganization = async (claimData) =>
  axios
    .put(`${apiUrls.organisationsBaseUrl}/${claimData.id}`, claimData)
    .then((res) => res)
    .catch((error) => error);

// ------------------ Api Calls for External Contacts ------------------
const onLoadExternalContacts = async (params) =>
  axios
    .get(apiUrls.externalContacts, { params })
    .then((res) => res)
    .catch((error) => error);

const onCreateExternalContacts = async (payload) =>
  axios
    .post(apiUrls.externalContacts, payload)
    .then((res) => res)
    .catch((error) => error);

const onUpdateExternalContacts = async (payload) =>
  axios
    .put(apiUrls.externalContacts, payload)
    .then((res) => res)
    .catch((error) => error);

const onDeleteExternalContacts = async (payload) =>
  axios
    .post(`${apiUrls.externalContacts}/delete-many`, payload)
    .then((res) => res)
    .catch((error) => error);

// ------------------ Api Calls for Message Templates ------------------
const onGetMessageTemplates = async (params) =>
  axios
    .get(apiUrls.messageTemplatesUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onGetPredefinedMessageTemplates = async () =>
  axios
    .get(apiUrls.messageTemplatesPredefinedUrl)
    .then((res) => res)
    .catch((error) => error);

const onCreateMessageTemplate = async (payload) =>
  axios
    .post(apiUrls.messageTemplatesUrl, [payload])
    .then((res) => res)
    .catch((error) => error);

const onDeleteMessageTemplate = async (id) =>
  axios
    .post(`${apiUrls.messageTemplatesUrl}/delete-many`, [id])
    .then((res) => res)
    .catch((error) => error);

const onUpdateMessageTemplate = async (payload) =>
  axios
    .put(apiUrls.messageTemplatesUrl, [payload])
    .then((res) => res)
    .catch((error) => error);
export {
  onLoadAll,
  onLoadOne,
  onCreateOrganization,
  onUpdateOrganization,
  onLoadExternalContacts,
  onUpdateExternalContacts,
  onCreateExternalContacts,
  onDeleteExternalContacts,
  onGetMessageTemplates,
  onCreateMessageTemplate,
  onUpdateMessageTemplate,
  onDeleteMessageTemplate,
  onGetPredefinedMessageTemplates
};
