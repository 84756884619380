const actions = {
  SET_CLAIMS_FILTERS: 'SET_FILTERS',
  SET_RISKS_FILTERS: 'SET_RISKS_FILTERS',
  SET_RISKS_SORTING_PARAMETER: 'SET_RISKS_SORTING_PARAMETER',
  SET_POLICIES_FILTERS: 'SET_POLICIES_FILTERS',
  SET_REPORTING_FILTERS: 'SET_REPORTING_FILTERS',
  SHOW_FILTERS_DRAWER: 'SHOW_FILTERS_DRAWER',
  HIDE_FILTERS_DRAWER: 'HIDE_FILTERS_DRAWER',

  setClaimsFilters: (filters) => ({
    type: actions.SET_CLAIMS_FILTERS,
    filters
  }),
  setRisksFilters: (filters) => ({
    type: actions.SET_RISKS_FILTERS,
    filters
  }),
  setRisksSortingParameter: (sortingParameter) => ({
    type: actions.SET_RISKS_SORTING_PARAMETER,
    sortingParameter
  }),
  setPoliciesFilters: (filters) => ({
    type: actions.SET_POLICIES_FILTERS,
    filters
  }),
  setReportingFilters: (filters) => ({
    type: actions.SET_REPORTING_FILTERS,
    filters
  }),
  showFiltersDrawer: () => ({
    type: actions.SHOW_FILTERS_DRAWER
  }),
  hideFiltersDrawer: () => ({
    type: actions.HIDE_FILTERS_DRAWER
  })
};

export default actions;
