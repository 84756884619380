import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Drawer, Button } from '../ui';
import { IconClose, IconMenu } from '../ui/Icons';
import { windowMaxWidth, deviceSize } from '../ui/Layout/breakpoints';
import HeaderMenuMobileMenuItems from './HeaderMenuMobileMenuItems';

const HeaderMenuButtonWrapper = styled.div`
  align-items: center;
  display: none;
  height: 100%;

  svg path {
    stroke: #111 !important;
  }

  .mobile-open-navigation-button {
    padding-right: 10px !important;
    width: auto;
  }

  @media ${windowMaxWidth.splitScreen} {
    display: flex;
  }
`;

const MenuHeaderWrapper = styled.div`
  align-items: center;
  border-bottom: solid 1px var(--greyscale-dark-white);
  display: flex;
  gap: 10px;
  height: 60px;
  padding: 10px 30px;

  .mobile-close-navigation-button {
    width: auto;
  }

  svg path {
    stroke: #111 !important;
  }
`;

const MenuHeader = styled.div`
  font-size: var(--font-size-heading-3);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-medium);
`;

const HeaderMenuDrawer = ({ path, userRights }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      const splitScreenWidth = parseInt(deviceSize.splitScreen.replace('px', ''), 10);
      const newWndowWidth = window.innerWidth;
      if (newWndowWidth > splitScreenWidth) {
        setIsDrawerOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <HeaderMenuButtonWrapper>
      <Button className="mobile-open-navigation-button" onClick={handleDrawerToggle} type="link" icon={<IconMenu />} />
      <Drawer
        autoFocus={false}
        title="Filter"
        placement="left"
        forceRender
        open={isDrawerOpen}
        maskClosable
        width="100%"
        rootStyle={{
          position: 'fixed'
        }}
        onClose={handleDrawerToggle}
        className="header-menu-drawer"
      >
        <MenuHeaderWrapper>
          <Button
            className="mobile-close-navigation-button"
            onClick={handleDrawerToggle}
            type="link"
            icon={<IconClose />}
          />
          <MenuHeader>Menü</MenuHeader>
        </MenuHeaderWrapper>
        <HeaderMenuMobileMenuItems path={path} userRights={userRights} handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
    </HeaderMenuButtonWrapper>
  );
};

export default HeaderMenuDrawer;
