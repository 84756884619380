const YEAR_PATTERN = new RegExp(/^(1000|1\d{3}|200\d|20[1-4]\d|2500)$/); // 1000-2500

// default rules for form items
export const DEFAULT_RULES = {
  INPUT_REQUIRED: [
    { required: true, message: '↑ Pflichtfeld' },
    { whitespace: true, message: '↑ Darf kein Leerzeichen sein' }
  ],
  INPUT_REQUIRED_WHITESPACE_ALLOWED: [{ required: true, message: '↑ Pflichtfeld' }],
  EMAIL: [
    { required: true, message: '↑ Pflichtfeld' },
    { whitespace: true, message: '↑ Darf kein Leerzeichen sein' },
    { type: 'email', message: '↑ Die Eingabe ist keine gültige E-Mail' }
  ],
  YEAR: [{ pattern: YEAR_PATTERN, message: '' }],
  SELECT_REQUIRED: [{ required: true, message: '↑ Pflichtfeld' }],
  INPUT: [{ whitespace: true, message: '↑ Darf kein Leerzeichen sein' }]
};
