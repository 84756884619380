import { Map } from 'immutable';
import actions from './actions';

const INITIAL_STATE = new Map({
  updateClaimAuthorizedOrganizationsLoading: false,
  updateClaimAuthorizedOrganizationsError: false
});

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS:
      return state
        .set('updateClaimAuthorizedOrganizationsLoading', true)
        .set('updateClaimAuthorizedOrganizationsError', false);
    case actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_SUCCESS:
      return state
        .set('updateClaimAuthorizedOrganizationsLoading', false)
        .set('updateClaimAuthorizedOrganizationsError', false);
    case actions.UPDATE_CLAIM_AUTHORIZED_ORGANIZATIONS_ERROR:
      return state
        .set('updateClaimAuthorizedOrganizationsLoading', false)
        .set('updateClaimAuthorizedOrganizationsError', true);
    default:
      return state;
  }
};

export default reducer;
