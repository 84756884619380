export const THEME_CONFIG = {
  hashed: false,
  cssVar: { key: 'app', prefix: 'oqio' },
  token: {
    fontFamily: 'Work Sans, sans-serif',
    fontSizeHeading1: 32,
    fontSizeHeading2: 26,
    fontSizeHeading3: 20,
    fontSizeHeading4: 16,
    fontSizeHeading5: 12,
    colorPrimary: '#627fff',
    colorInfo: '#627fff',
    colorSuccess: '#00ce82',
    colorWarning: '#ffdc00',
    colorError: '#ff5300',
    colorTextBase: '#111111',
    colorTextPlaceholder: '#808080'
  },
  components: {
    Button: {
      colorPrimary: '#627fff',
      colorPrimaryBorder: '#627fff',
      contentFontSize: 16,
      contentLineHeight: '20px',
      colorPrimaryHover: '#8097ff',
      paddingBlock: 10,
      paddingInline: 20,
      controlHeight: 40,
      fontSize: 16,
      primaryShadow: 'none',
      onlyIconSize: 20,
      marginXS: 6,
      colorError: '#ff5300',
      colorErrorHover: '#ff7b3b',
      colorErrorBorderHover: '#ff7b3b'
    },
    wave: { disabled: true },
    List: {
      fontFamily: 'Work Sans, sans-serif'
    },
    Radio: {
      fontFamily: 'Work Sans, sans-serif',
      fontSize: 16,
      radioSize: 20,
      dotSize: 10,
      colorPrimary: '#627fff'
    },
    Form: {
      labelFontSize: 12,
      labelHeight: 16,
      labelColor: '#808080',
      verticalLabelPadding: '0 0 0 15px',
      colorError: '#ff5300',
      labelRequiredMarkColor: '#808080',
      colorTextDescription: '#808080'
    },
    Input: {
      controlHeight: 40,
      lineHeight: '20px',
      fontSize: 16,
      paddingBlock: 9,
      paddingInline: 15,
      colorBgContainer: '#f7f7f7',
      colorBorder: '#b3b3b3',
      colorError: '#ff5300',
      hoverBorderColor: '#627fff',
      activeBorderColor: '#627fff',
      activeShadow: 'none',
      errorActiveShadow: 'none',
      colorErrorBorderHover: '#627fff'
    },
    InputNumber: {
      controlHeight: 40,
      lineHeight: '20px',
      fontSize: 16,
      paddingBlock: 9,
      paddingInline: 15,
      colorBgContainer: '#f7f7f7',
      colorBorder: '#b3b3b3',
      colorError: '#ff5300',
      addonBg: '#f7f7f7',
      hoverBorderColor: '#627fff',
      activeBorderColor: '#627fff',
      activeShadow: 'none',
      colorErrorBorderHover: '#627fff'
    },
    Select: {
      controlHeight: 40
    },
    DatePicker: {
      colorTextPlaceholder: '#808080'
    },
    Table: {
      headerBg: '#ffffff',
      headerSortHoverBg: '#ffffff',
      headerSortActiveBg: '#ffffff',
      bodySortBg: '#ffffff',
      fontSize: 16,
      rowHoverBg: '#f7f7f7',
      stickyScrollBarBg: 'rgba(136, 136, 136, 0.3) transparent'
    },
    Checkbox: {
      colorBgContainer: '#f7f7f7',
      colorBorder: '#b3b3b3',
      colorPrimary: '#627fff',
      fontSize: 16,
      colorPrimaryHover: '#8097ff',
      lineHeight: '20px',
      colorTextDisabled: '#b3b3b3'
    },
    Tabs: {
      itemColor: '#808080',
      itemHoverColor: '#111111',
      itemSelectedColor: '#111111',
      inkBarColor: '#111111',
      horizontalItemPadding: '20px 10px 10px',
      fontSize: 16,
      lineHeight: '20px',
      horizontalItemGutter: 10
    },
    Drawer: {
      boxShadowDrawerUp: 'none',
      boxShadowDrawerDown: 'none',
      boxShadowDrawerLeft: 'none',
      boxShadowDrawerRight: 'none'
    }
  }
};
