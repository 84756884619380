import React from 'react';
import styled from 'styled-components';

const WorklistCheckmarkWrapper = styled.div`
  cursor: pointer;

  .checkmark {
    fill: ${(props) => (props.checked ? '#627fff;' : '#DFDFDF')};
    transition: 0.2s ease-in;
  }

  .alarm {
    fill: #dfdfdf;
    transition: 0.2s ease-in;
  }

  &:hover {
    .checkmark {
      fill: ${(props) => (props.checked ? '#DFDFDF' : '#627fff;')};
      transition: 0.2s ease-in;
    }

    .alarm {
      fill: #627fff;
      transition: 0.2s ease-in;
    }
  }
`;

const WorklistCheckmark = ({ children, checked, onClick }) => (
  <WorklistCheckmarkWrapper checked={checked} onClick={onClick}>
    {children}
  </WorklistCheckmarkWrapper>
);

export default WorklistCheckmark;
