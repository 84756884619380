import styled from 'styled-components';
import { windowMaxWidth } from '../breakpoints';
import { Button } from '../../Button/Button';

const FileListWrapper = styled.ul`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  li:last-child {
    margin-bottom: 0;
  }
`;

const ThumbnailWrapper = styled.div`
  border-radius: var(--border-radius-small);
  height: 50px;
  margin-right: 15px;
  width: 50px;

  > div {
    height: inherit;
    width: inherit;
  }

  @media ${windowMaxWidth.mobile} {
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ListItemWrapper = styled.li`
  align-items: flex-start;
  background-color: var(--greyscale-porcelain);
  border-radius: var(--border-radius-regular);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &.attachments-modal-list-item {
    background-color: ${(props) => (props.withBackground ? 'var(--greyscale-porcelain)' : 'var(--greyscale-white)')};
    cursor: pointer;

    &.uploaded-file-item {
      cursor: default;
    }
  }

  @media ${windowMaxWidth.mobile} {
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const ListItemInfoWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
`;

const FileInfoWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
`;

const FileSize = styled.span`
  color: var(--greyscale-shade);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-medium);
  margin-right: 5px;
`;

const FileUploadDate = styled.span`
  display: flex;

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

const FileUploadDateMobile = styled.span`
  display: none;

  @media ${windowMaxWidth.mobile} {
    display: flex;
  }
`;

const FileSizeWrapper = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-medium);
`;

const ButtonsWrapper = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  gap: 20px;
  height: 50px;
  justify-content: flex-end;
  line-height: var(--font-line-height-regular);
  padding-right: 10px;

  .btn-inline {
    .ant-btn-icon {
      margin-inline-end: 6px !important;
    }

    span:not(.ant-btn-icon) {
      display: flex;
    }
  }

  &.attachments-modal-list-item {
    flex: unset;
    margin-left: 20px;
  }

  @media ${windowMaxWidth.splitScreen} {
    flex: auto;
    gap: 20px;

    .btn-inline {
      display: flex;

      .ant-btn-icon {
        margin-inline-end: 0 !important;
      }

      span:not(.ant-btn-icon) {
        display: none;
      }
    }
  }

  @media ${windowMaxWidth.mobile} {
    height: 85px;
  }
`;

const IconEyeViewWrapper = styled.div`
  display: flex;

  svg {
    path:last-child {
      fill: var(--primary-oqio-blue);
    }
  }
`;

const ViewFileButtonWrapper = styled.div`
  display: flex;

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

const FileTypeIconButton = styled(Button)`
  background-color: initial;
  border: none;
  height: 50px;
  padding: 0;
  width: 50px;

  svg {
    margin: 0;
  }
`;

const FileNameWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  max-height: 20px;
`;

const FileName = styled.div`
  max-width: 28ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${windowMaxWidth.laptopM} {
    max-width: 18ch;
  }

  @media ${windowMaxWidth.mobile} {
    max-width: 10ch;
  }
`;

const FileExtension = styled.span``;

// an overlay for the file item that will make clear that the item cannot be selected
const FileItemOverlay = styled.div`
  background-color: var(--greyscale-concrete);
  border-radius: var(--border-radius-regular);
  inset: 0;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
`;

export const FileItemLayout = {
  FileListWrapper,
  ThumbnailWrapper,
  ListItemWrapper,
  ListItemInfoWrapper,
  FileInfoWrapper,
  FileSize,
  FileUploadDate,
  FileUploadDateMobile,
  FileSizeWrapper,
  ButtonsWrapper,
  IconEyeViewWrapper,
  ViewFileButtonWrapper,
  FileTypeIconButton,
  FileNameWrapper,
  FileName,
  FileExtension,
  FileItemOverlay
};
