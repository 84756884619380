import React from 'react';
import styled from 'styled-components';
import IconSuccess from './IconSuccess';

const LoadingIndicatorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SpinnerWrapper = styled.div`
  height: 142px;
  position: relative;
  width: 142px;
`;

const SpinnerLoadingCircle = styled.div`
  animation: rotate 3s linear infinite;
  border: 25px solid;
  border-color: var(--primary-oqio-blue) var(--primary-oqio-blue) var(--primary-oqio-blue) var(--greyscale-porcelain);
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerSuccessCircle = styled.div`
  align-items: center;
  border: 25px solid var(--primary-oqio-blue);
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const SpinnerCheckmark = styled.span`
  color: var(--primary-oqio-blue);
  font-size: 40px;
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-large);
`;

const LoadingIndicatorMessage = styled.div`
  margin: 20px;
  text-align: center;
`;

/**
 * This is a loading indicator component that shows a loading spinner and a message.
 * It should be used in components where large display area is available.
 * @param {boolean} isLoading - shows if the loading or the success state should be displayed
 * @param {JSX.Element} loadingStateMessage - the message that is displayed when the loading state is active
 * @param {JSX.Element} successMessage - the message that is displayed when the success state is active
 */
export const LoadingIndicator = ({ isLoading = true, loadingStateMessage, successMessage }) => {
  return (
    <LoadingIndicatorWrapper>
      <SpinnerWrapper>
        {isLoading ? (
          <SpinnerLoadingCircle />
        ) : (
          <SpinnerSuccessCircle>
            <SpinnerCheckmark>
              <IconSuccess />
            </SpinnerCheckmark>
          </SpinnerSuccessCircle>
        )}
      </SpinnerWrapper>
      <LoadingIndicatorMessage>{isLoading ? loadingStateMessage : successMessage}</LoadingIndicatorMessage>
    </LoadingIndicatorWrapper>
  );
};
