const actions = {
  SET_SIDEBAR_LOCATION: 'SET_SIDEBAR_LOCATION',
  SET_SIDEBAR_DEFAULT_LOCATION: 'SET_SIDEBAR_DEFAULT_LOCATION',
  setSidebarLocation: (value) => ({
    type: actions.SET_SIDEBAR_LOCATION,
    value
  }),
  setSidebarDefaultLocation: (value) => ({
    type: actions.SET_SIDEBAR_DEFAULT_LOCATION,
    value
  })
};
export default actions;
