import React from 'react';

const IconPassword = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 8.5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-3z"
        stroke="#111"
        strokeWidth="1.5"
      />
      <path
        d="M5.5 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM14.5 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM10 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
        fill="#111"
      />
    </svg>
  );
};

export default IconPassword;
