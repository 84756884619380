import React from 'react';

const IconClose = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m3 3 14 14M17 3 3 17" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconClose;
