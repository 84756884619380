import React from 'react';

const IconCheck = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m18.247 3.67-10.994 12-5.006-5.466" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconCheck;
