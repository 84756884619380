import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  settlements: [],
  settlementsLoading: false,
  settlementsError: false,
  settlementCreateLoading: false,
  settlementCreateError: false,
  settlementUpdateLoading: false,
  settlementUpdateError: false,
  cutbackReasons: [],
  cutbackReasonsLoading: false,
  cutbackReasonsError: false,
  pdfSettlementSummaryLoading: false,
  pdfSettlementSummaryError: false,
  pdfSettlementSummary: null,
  exportSettlementsLoading: false,
  exportSettlementsError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_ALL_SETTLEMENTS:
      return state.set('settlementsLoading', true).set('settlementsError', false);
    case actions.GET_ALL_SETTLEMENTS_SUCCESS:
      return state
        .set('settlements', action.settlements)
        .set('settlementsLoading', false)
        .set('settlementUpdateError', false);
    case actions.GET_ALL_SETTLEMENTS_ERROR:
      return state.set('settlementsLoading', false).set('settlementsError', true);
    case actions.GET_SETTLEMENTS_BY_CLAIM_ID:
      return state.set('settlementsLoading', true).set('settlementsError', false);
    case actions.GET_SETTLEMENTS_BY_CLAIM_ID_SUCCESS:
      return state
        .set('settlements', action.settlements)
        .set('settlementsLoading', false)
        .set('settlementsError', false);
    case actions.GET_SETTLEMENTS_BY_CLAIM_ID_ERROR:
      return state.set('settlementsLoading', false).set('settlementsError', true);
    case actions.CREATE_SETTLEMENT:
      return state.set('settlementCreateLoading', true).set('settlementCreateError', false);
    case actions.CREATE_SETTLEMENT_RESULT:
      return state.set('settlementCreateLoading', false).set('settlementCreateError', false);
    case actions.CREATE_SETTLEMENT_ERROR_RESULT:
      return state.set('settlementCreateLoading', false).set('settlementCreateError', true);
    case actions.UPDATE_SETTLEMENT:
      return state.set('settlementUpdateLoading', true).set('settlementUpdateError', false);
    case actions.UPDATE_SETTLEMENT_RESULT:
      return state.set('settlementUpdateLoading', false).set('settlementUpdateError', false);
    case actions.UPDATE_SETTLEMENT_ERROR_RESULT:
      return state.set('settlementUpdateLoading', false).set('settlementUpdateError', true);
    case actions.CLEAR_SETTLEMENT_ERRORS:
      return state.set('settlementCreateError', false).set('settlementUpdateError', false);
    case actions.LOAD_CUTBACK_REASONS:
      return state.set('cutbackReasonsLoading', true).set('cutbackReasonsError', false);
    case actions.LOAD_CUTBACK_REASONS_SUCCESS:
      return state
        .set('cutbackReasonsLoading', false)
        .set('cutbackReasonsError', false)
        .set('cutbackReasons', action.cutbackReasons);
    case actions.LOAD_CUTBACK_REASONS_ERROR:
      return state.set('cutbackReasonsLoading', false).set('cutbackReasonsError', true);
    case actions.CREATE_CUTBACK_REASON:
      return state.set('createCutbackReasonLoading', true).set('createCutbackReasonError', false);
    case actions.CREATE_CUTBACK_REASON_SUCCESS:
      return state.set('createCutbackReasonLoading', false).set('createCutbackReasonError', false);
    case actions.CREATE_CUTBACK_REASON_ERROR:
      return state.set('createCutbackReasonLoading', false).set('createCutbackReasonError', true);
    case actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY:
      return state.set('pdfSettlementSummaryLoading', true);
    case actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY_SUCCESS:
      return state.set('pdfSettlementSummaryLoading', false).set('pdfSettlementSummary', action.pdfSettlementSummary);
    case actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY_ERROR:
      return state.set('pdfSettlementSummaryError', true).set('pdfSettlementSummaryLoading', false);
    case actions.RESET_PDF_SETTLEMENT_SUMMARY:
      return state.set('pdfSettlementSummary', null);
    case actions.EXPORT_SETTLEMENTS:
      return state.set('exportSettlementsLoading', true);
    case actions.EXPORT_SETTLEMENTS_SUCCESS:
      return state.set('exportSettlementsLoading', false);
    case actions.EXPORT_SETTLEMENTS_ERROR:
      return state.set('exportSettlementsLoading', false).set('exportSettlementsError', true);
    case actions.CLEAR_SETTLEMENTS:
      return state.set('settlements', []);
    default:
      return state;
  }
};

export default reducer;
