import * as Sentry from '@sentry/react';
import { SENTRY_KEY, NODE_ENV } from './base';

// Define the mapping between environments
const environment = {
  production: 'production',
  localhost: 'development',
  staging: 'staging'
};

// Determine the Sentry environment based on NODE_ENV
const sentryEnvironment = environment[NODE_ENV] || environment.localhost;

// Configure Sentry
const sentryConfig = {
  dsn: SENTRY_KEY,
  environment: sentryEnvironment,
  enabled: NODE_ENV !== 'localhost', // disable sentry on localhost
  maxBreadcrumbs: 100, // 100 is the default
  normalizeDepth: 3, // 3 is the default
  beforeSend(event) {
    // This function is called before an event is sent to Sentry
    const shouldNotSendEvent = shouldNotSendEventCheck(event);
    if (shouldNotSendEvent) {
      return null;
    }
    return event;
  }
};

// Initialize Sentry with the provided configuration
const initializeSentry = () => {
  Sentry.init(sentryConfig);
};

// Capture validation events in Sentry
const sentryCaptureValidationException = (event) => {
  Sentry.withScope((scope) => {
    Sentry.setTag('level.type', 'validation');
    scope.setLevel('info');
    Sentry.captureException(event);
  });
};

// Check if the event should be not send by iterating over reporting functions
const shouldNotSendEventCheck = (event) => {
  return EVENT_REPORTING_FUNCTIONS.some((func) => {
    return func(event);
  });
};

// Check if at least one filename in the frames array includes 'bing'
const handleBingMapsEvents = (event) => {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (frames) {
    return frames.some((frame) => {
      const filename = frame?.filename;
      return filename && filename.includes('bing');
    });
  }

  return false;
};

// check if the login failed because of invalid email, or missing email or password
// this is not about a wrong email or password, but about the user not entering all fields or invalid email
const handleLoginValidation = (event) => {
  const consoleEvents = event?.breadcrumbs?.filter((item) => item.category === 'console');

  if (!consoleEvents) {
    return false;
  }

  const INVALID_LOGIN_MESSAGES = [
    'E-Mail ist nicht gültig',
    'Bitte geben Sie Passwort an',
    'Bitte geben Sie E-Mail an'
  ];

  const hasInvalidLogin = consoleEvents.some((item) => {
    return INVALID_LOGIN_MESSAGES.some((message) => item.message.includes(message));
  });

  return hasInvalidLogin;
};

// check if the event is a ResizeObserver event
// we do not use ResizeObserver, so we can ignore these events
const handleResizeObserverEvents = (event) => {
  const exceptionArray = event?.exception?.values;

  return exceptionArray.some(
    (item) => typeof item.value === 'string' && item.value.toLowerCase().includes('resizeobserver')
  );
};

// Place more functions here to check for other events, they must return true if the event should not be sent
// Place the function name in the EVENT_REPORTING_FUNCTIONS array
// ...

// Array containing functions that check potential events
const EVENT_REPORTING_FUNCTIONS = [handleBingMapsEvents, handleLoginValidation, handleResizeObserverEvents];

export { initializeSentry, sentryCaptureValidationException };
