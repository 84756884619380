import axios from '../../config/axios';
import apiUrls from '../apiUrls';

// this is a function that calls the api to get the customer portal availability, it returns a boolean
const onGetCustomerPortalAvailability = async () =>
  axios
    .get(`${apiUrls.stripeBaseUrl}/customer-portal-availability`)
    .then((res) => res)
    .catch((error) => error);

// this is a function that calls the api to create the customer portal url, it returns a string
const postCustomerPortalSession = async () =>
  axios
    .post(`${apiUrls.stripeBaseUrl}/customer-portal-session`)
    .then((res) => res)
    .catch((error) => error);

export { onGetCustomerPortalAvailability, postCustomerPortalSession };
