const actions = {
  LOAD_AUTHORIZED_ORGANISATIONS: 'LOAD_AUTHORIZED_ORGANISATIONS',
  LOAD_AUTHORIZED_ORGANISATIONS_SUCCESS: 'LOAD_AUTHORIZED_ORGANISATIONS_SUCCESS',
  LOAD_AUTHORIZED_ORGANISATIONS_ERROR: 'LOAD_AUTHORIZED_ORGANISATIONS_ERROR',
  BULK_ADD_AUTHORIZED_ORGANISATIONS: 'BULK_ADD_AUTHORIZED_ORGANISATIONS',
  BULK_ADD_AUTHORIZED_ORGANISATIONS_SUCCESS: 'BULK_ADD_AUTHORIZED_ORGANISATIONS_SUCCESS',
  BULK_ADD_AUTHORIZED_ORGANISATIONS_ERROR: 'BULK_ADD_AUTHORIZED_ORGANISATIONS_ERROR',
  BULK_REMOVE_AUTHORIZED_ORGANISATIONS: 'BULK_REMOVE_AUTHORIZED_ORGANISATIONS',
  BULK_REMOVE_AUTHORIZED_ORGANISATIONS_SUCCESS: 'BULK_REMOVE_AUTHORIZED_ORGANISATIONS_SUCCESS',
  BULK_REMOVE_AUTHORIZED_ORGANISATIONS_ERROR: 'BULK_REMOVE_AUTHORIZED_ORGANISATIONS_ERROR',
  ADD_AUTHORIZED_ORGANISATION: 'ADD_AUTHORIZED_ORGANISATION',
  ADD_AUTHORIZED_ORGANISATION_SUCCESS: 'ADD_AUTHORIZED_ORGANISATION_SUCCESS',
  ADD_AUTHORIZED_ORGANISATION_ERROR: 'ADD_AUTHORIZED_ORGANISATION_ERROR',
  ADD_AUTHORIZED_ORGANISATIONS: 'ADD_AUTHORIZED_ORGANISATIONS',
  ADD_AUTHORIZED_ORGANISATIONS_SUCCESS: 'ADD_AUTHORIZED_ORGANISATIONS_SUCCESS',
  ADD_AUTHORIZED_ORGANISATIONS_ERROR: 'ADD_AUTHORIZED_ORGANISATIONS_ERROR',
  DELETE_AUTHORIZED_ORGANISATION: 'DELETE_AUTHORIZED_ORGANISATION',
  DELETE_AUTHORIZED_ORGANISATION_SUCCESS: 'DELETE_AUTHORIZED_ORGANISATION_SUCCESS',
  DELETE_AUTHORIZED_ORGANISATION_ERROR: 'DELETE_AUTHORIZED_ORGANISATION_ERROR',
  DELETE_AUTHORIZED_ORGANISATIONS: 'DELETE_AUTHORIZED_ORGANISATIONS',
  DELETE_AUTHORIZED_ORGANISATIONS_SUCCESS: 'DELETE_AUTHORIZED_ORGANISATIONS_SUCCESS',
  DELETE_AUTHORIZED_ORGANISATIONS_ERROR: 'DELETE_AUTHORIZED_ORGANISATIONS_ERROR',
  CLEAR_SMART_ACTIONS_ERRORS: 'CLEAR_SMART_ACTIONS_ERRORS',
  UPDATE_AUTHORIZED_ORGANISATION: 'UPDATE_AUTHORIZED_ORGANISATION',
  UPDATE_AUTHORIZED_ORGANISATION_SUCCESS: 'UPDATE_AUTHORIZED_ORGANISATION_SUCCESS',
  UPDATE_AUTHORIZED_ORGANISATION_ERROR: 'UPDATE_AUTHORIZED_ORGANISATION_ERROR',
  clearSmartActionsErrors: () => ({
    type: actions.CLEAR_SMART_ACTIONS_ERRORS
  }),
  bulkRemoveAuthorizedOrganisations: (organisations, policies) => ({
    type: actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS,
    organisations,
    policies
  }),
  bulkRemoveAuthorizedOrganisationsSuccess: (payload) => ({
    type: actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS_SUCCESS,
    payload
  }),
  bulkRemoveAuthorizedOrganisationsError: (error) => ({
    type: actions.BULK_REMOVE_AUTHORIZED_ORGANISATIONS_ERROR,
    error
  }),
  bulkAddAuthorizedOrganisations: (organisations, policies) => ({
    type: actions.BULK_ADD_AUTHORIZED_ORGANISATIONS,
    organisations,
    policies
  }),
  bulkAddAuthorizedOrganisationsSuccess: (payload) => ({
    type: actions.BULK_ADD_AUTHORIZED_ORGANISATIONS_SUCCESS,
    payload
  }),
  bulkAddAuthorizedOrganisationsError: (error) => ({
    type: actions.BULK_ADD_AUTHORIZED_ORGANISATIONS_ERROR,
    error
  }),
  loadAuthorizedOrganisations: (params) => ({
    type: actions.LOAD_AUTHORIZED_ORGANISATIONS,
    params
  }),
  loadAuthorizedOrganisationsSuccess: (payload) => ({
    type: actions.LOAD_AUTHORIZED_ORGANISATIONS_SUCCESS,
    payload
  }),
  loadAuthorizedOrganisationsError: (error) => ({
    type: actions.LOAD_AUTHORIZED_ORGANISATIONS_ERROR,
    error
  }),
  addAuthorizedOrganisations: (organizationsToDelete, policyId, params) => ({
    type: actions.ADD_AUTHORIZED_ORGANISATIONS,
    organizationsToDelete,
    policyId,
    params
  }),
  addAuthorizedOrganisation: (authorizedOrganisation) => ({
    type: actions.ADD_AUTHORIZED_ORGANISATION,
    authorizedOrganisation
  }),
  addAuthorizedOrganisationSuccess: () => ({
    type: actions.ADD_AUTHORIZED_ORGANISATION_SUCCESS
  }),
  addAuthorizedOrganisationError: (error) => ({
    type: actions.ADD_AUTHORIZED_ORGANISATION_ERROR,
    error
  }),
  deleteAuthorizedOrganisations: (authorizationIds, policyId, params) => ({
    type: actions.DELETE_AUTHORIZED_ORGANISATIONS,
    authorizationIds,
    policyId,
    params
  }),
  deleteAuthorizedOrganisation: (authorizationIds, policyId, params) => ({
    type: actions.DELETE_AUTHORIZED_ORGANISATION,
    authorizationIds,
    policyId,
    params
  }),
  deleteAuthorizedOrganisationSuccess: () => ({
    type: actions.DELETE_AUTHORIZED_ORGANISATION_SUCCESS
  }),
  deleteAuthorizedOrganisationError: (error) => ({
    type: actions.DELETE_AUTHORIZED_ORGANISATION_ERROR,
    error
  }),
  updateAuthorizedOrganisation: (params) => ({
    type: actions.UPDATE_AUTHORIZED_ORGANISATION,
    params
  }),
  updateAuthorizedOrganisationSuccess: () => ({
    type: actions.UPDATE_AUTHORIZED_ORGANISATION_SUCCESS
  }),
  updateAuthorizedOrganisationError: () => ({
    type: actions.UPDATE_AUTHORIZED_ORGANISATION_ERROR
  })
};
export default actions;
