import React from 'react';

const IconMessageFilter = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-4-2.25v-1.5H5v1.5h10zm-1.5 2v1.5h-7v-1.5h7zm-1 5v-1.5h-5v1.5h5z"
      fill="#111"
    />
  </svg>
);

export default IconMessageFilter;
