import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Modal, Button } from '../../components/ui';
import authAction from '../../redux/auth/actions';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import renderNotification from '../../helpers/notifications/renderNotification';
import { DEFAULT_RULES } from '../../consts/formRules';
import { ForgotPasswordStyleWrapper, ForgotPasswordButtonsWrapper } from './ForgotPassword.style';

/**
 * Functional component that renders a modal for the forgot password functionality.
 * Includes a form for the user to input their email to receive a password reset link.
 * @property {Boolean} forgotPasswordVisible - boolean to show/hide the forgot password modal.
 * @property {Function} cancelForgotPassword - function to cancel and close the forgot password modal.
 * @property {Boolean} forgotPasswordLoading - boolean indicating if the forgot password process is loading.
 * @property {Boolean} forgotPasswordError - boolean indicating if there was an error during the forgot password process.
 * @property {Function} forgotPassword - function to trigger the forgot password process.
 */
const ForgotPassword = ({
  forgotPasswordVisible,
  cancelForgotPassword,
  forgotPasswordLoading,
  forgotPasswordError,
  forgotPassword
}) => {
  const [form] = Form.useForm();
  const { validateFields } = form;
  const [passwordRequestSent, setPasswordRequestSent] = React.useState(false);

  const { input_is_not_valid_email } = NOTIFICATION_MESSAGES;

  // Handle form submission and trigger forgot password process
  const handleSubmit = () => {
    validateFields()
      .then((values) => {
        forgotPassword(values.email, cancelForgotPassword);
        setPasswordRequestSent(true);
      })
      .catch((error) => {
        const { email } = error.values;
        if (email) {
          return renderNotification({ type: 'error', message: input_is_not_valid_email });
        }
      });
  };

  // Handle closing the forgot password modal
  const handleCloseForm = () => {
    cancelForgotPassword();
  };

  const isFieldsDisabled = forgotPasswordLoading || (passwordRequestSent && !forgotPasswordError);

  const footer = (
    <ForgotPasswordButtonsWrapper>
      <Button type="secondary" onClick={handleCloseForm}>
        Abbrechen
      </Button>
      <Button type="primary" onClick={handleSubmit} disabled={isFieldsDisabled}>
        Passwort zurücksetzen
      </Button>
    </ForgotPasswordButtonsWrapper>
  );

  return (
    <>
      <Modal
        title="Passwort vergessen?"
        open={forgotPasswordVisible}
        className="modal-no-padding"
        onCancel={handleCloseForm}
        width={520}
        footer={footer}
        forceRender
        maskClosable
      >
        <ForgotPasswordStyleWrapper>
          <Form form={form} className="email-input-field" requiredMark="optional">
            <Form.Item label="E-Mail" name="email" validateTrigger="onSubmit" rules={DEFAULT_RULES.EMAIL}>
              <Input autoComplete="email" size="large" disabled={isFieldsDisabled} />
            </Form.Item>
          </Form>
        </ForgotPasswordStyleWrapper>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  forgotPasswordLoading: state.Auth.get('forgotPasswordLoading'),
  forgotPasswordError: state.Auth.get('forgotPasswordError')
});

const { forgotPassword } = authAction;

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
