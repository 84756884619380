import React from 'react';

const IconArrowLeft = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m8 4-6 6 6 6M2 10h16" stroke="#111" strokeWidth="1.5" />
    </svg>
  );
};
export default IconArrowLeft;
