import styled from 'styled-components';

const SearchWrapper = styled.div`
  padding: 20px 40px;
`;

const SearchIconWrapper = styled.div`
  height: 20px;
  position: absolute;
  right: -4px;
  top: -4px;
`;

const TableWrapper = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const SearchSelectModalLayout = {
  SearchWrapper,
  SearchIconWrapper,
  TableWrapper
};

export default SearchSelectModalLayout;
