import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  organizations: [],
  organizationsLoading: false,
  organizationsError: false,
  organizationOne: {},
  organizationOneLoading: false,
  organizationOneError: false,
  createOrganizationLoading: false,
  createOrganizationError: false,
  updateOrganizationLoading: false,
  updateOrganizationError: false,
  selectedOrganization: null,
  externalContacts: [],
  externalContactsLoading: false,
  externalContactsError: false,
  createExternalContactsLoading: false,
  createExternalContactsError: false,
  updateExternalContactsLoading: false,
  updateExternalContactsError: false,
  deleteExternalContactsLoading: false,
  deleteExternalContactsError: false,
  messageTemplates: [],
  messageTemplatesLoading: false,
  messageTemplatesError: false,
  predefinedMessageTemplates: [],
  predefinedMessageTemplatesLoading: false,
  predefinedMessageTemplatesError: false,
  createMessageTemplateLoading: false,
  createMessageTemplateError: false,
  updateMessageTemplateLoading: false,
  updateMessageTemplateError: false,
  deleteMessageTemplateLoading: false,
  deleteMessageTemplateError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_ORGANIZATIONS:
      return state.set('organizationsLoading', true);
    case actions.LOAD_ORGANIZATIONS_SUCCESS:
      return state.set('organizations', action.organizations).set('organizationsLoading', false);
    case actions.LOAD_ORGANIZATIONS_ERROR:
      return state.set('organizationsError', action.organizations).set('organizationsLoading', false);
    case actions.LOAD_ORGANIZATION_ONE:
      return state.set('organizationOneLoading', true);
    case actions.LOAD_ORGANIZATION_ONE_SUCCESS:
      return state.set('organizationOne', action.organizationOne).set('organizationOneLoading', false);
    case actions.LOAD_ORGANIZATION_ONE_ERROR:
      return state.set('organizationOneError', action.organizationOne).set('organizationOneLoading', false);
    case actions.CREATE_ORGANIZATION:
      return state.set('createOrganizationLoading', true).set('createOrganizationError', false);
    case actions.CREATE_ORGANIZATION_SUCCESS:
      return state.set('createOrganizationLoading', false).set('createOrganizationError', false);
    case actions.CREATE_ORGANIZATION_ERROR:
      return state.set('createOrganizationLoading', false).set('createOrganizationError', true);
    case actions.UPDATE_ORGANIZATION:
      return state.set('updateOrganizationLoading', true).set('updateOrganizationError', false);
    case actions.UPDATE_ORGANIZATION_SUCCESS:
      return state.set('updateOrganizationLoading', false).set('updateOrganizationError', false);
    case actions.UPDATE_ORGANIZATION_ERROR:
      return state.set('updateOrganizationLoading', false).set('updateOrganizationError', true);
    case actions.CLEAR_ORGANIZATION_ERRORS:
      return state.set('createOrganizationError', false).set('updateOrganizationError', false);
    case actions.GET_EXTERNAL_CONTACTS:
      return state.set('externalContactsLoading', true).set('externalContactsError', false);
    case actions.GET_EXTERNAL_CONTACTS_SUCCESS:
      return state
        .set('externalContactsLoading', false)
        .set('externalContactsError', false)
        .set('externalContacts', action.externalContacts);
    case actions.GET_EXTERNAL_CONTACTS_ERROR:
      return state.set('externalContactsLoading', false).set('externalContactsError', true);
    case actions.CREATE_EXTERNAL_CONTACTS:
      return state.set('createExternalContactsLoading', true).set('createExternalContactsError', false);
    case actions.CREATE_EXTERNAL_CONTACTS_SUCCESS:
      return state.set('createExternalContactsLoading', false).set('createExternalContactsError', false);
    case actions.CREATE_EXTERNAL_CONTACTS_ERROR:
      return state.set('createExternalContactsLoading', false).set('createExternalContactsError', true);
    case actions.UPDATE_EXTERNAL_CONTACTS:
      return state.set('updateExternalContactsLoading', true).set('updateExternalContactsError', false);
    case actions.UPDATE_EXTERNAL_CONTACTS_SUCCESS:
      return state.set('updateExternalContactsLoading', false).set('updateExternalContactsError', false);
    case actions.UPDATE_EXTERNAL_CONTACTS_ERROR:
      return state.set('updateExternalContactsLoading', false).set('updateExternalContactsError', true);
    case actions.DELETE_EXTERNAL_CONTACTS:
      return state.set('deleteExternalContactsLoading', true).set('deleteExternalContactsError', false);
    case actions.DELETE_EXTERNAL_CONTACTS_SUCCESS:
      return state.set('deleteExternalContactsLoading', false).set('deleteExternalContactsError', false);
    case actions.DELETE_EXTERNAL_CONTACTS_ERROR:
      return state.set('deleteExternalContactsLoading', false).set('deleteExternalContactsError', true);
    case actions.GET_MESSAGE_TEMPLATES:
      return state.set('messageTemplatesLoading', true).set('messageTemplatesError', false);
    case actions.GET_MESSAGE_TEMPLATES_SUCCESS:
      return state.set('messageTemplatesLoading', false).set('messageTemplates', action.templates);
    case actions.GET_MESSAGE_TEMPLATES_ERROR:
      return state.set('messageTemplatesLoading', false).set('messageTemplatesError', true);
    case actions.GET_PREDEFINED_MESSAGE_TEMPLATES:
      return state.set('predefinedMessageTemplatesLoading', true).set('predefinedMessageTemplatesError', false);
    case actions.GET_PREDEFINED_MESSAGE_TEMPLATES_SUCCESS:
      return state.set('predefinedMessageTemplatesLoading', false).set('predefinedMessageTemplates', action.templates);
    case actions.GET_PREDEFINED_MESSAGE_TEMPLATES_ERROR:
      return state.set('predefinedMessageTemplatesLoading', false).set('predefinedMessageTemplatesError', true);
    case actions.CREATE_MESSAGE_TEMPLATE:
      return state.set('createMessageTemplateLoading', true).set('createMessageTemplateError', false);
    case actions.CREATE_MESSAGE_TEMPLATE_SUCCESS:
      return state.set('createMessageTemplateLoading', false);
    case actions.CREATE_MESSAGE_TEMPLATE_ERROR:
      return state.set('createMessageTemplateLoading', false).set('createMessageTemplateError', true);
    case actions.UPDATE_MESSAGE_TEMPLATE:
      return state.set('updateMessageTemplateLoading', true).set('updateMessageTemplateError', false);
    case actions.UPDATE_MESSAGE_TEMPLATE_SUCCESS:
      return state.set('updateMessageTemplateLoading', false);
    case actions.UPDATE_MESSAGE_TEMPLATE_ERROR:
      return state.set('updateMessageTemplateLoading', false).set('updateMessageTemplateError', true);
    case actions.DELETE_MESSAGE_TEMPLATE:
      return state.set('deleteMessageTemplateLoading', true).set('deleteMessageTemplateError', false);
    case actions.DELETE_MESSAGE_TEMPLATE_SUCCESS:
      return state.set('deleteMessageTemplateLoading', false);
    case actions.DELETE_MESSAGE_TEMPLATE_ERROR:
      return state.set('deleteMessageTemplateLoading', false).set('deleteMessageTemplateError', true);
    case actions.SET_SELECTED_ORGANIZATION:
      return state.set('selectedOrganization', action.organization);
    default:
      return state;
  }
};

export default reducer;
