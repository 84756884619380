const actions = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',
  UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS: 'UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS',
  UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_SUCCESS: 'UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_SUCCESS',
  UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_ERROR: 'UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_ERROR',
  SET_UPLOADS_TEMP_CONTAINER: 'SET_UPLOADS_TEMP_CONTAINER',
  CLEAR_UPLOADS_TEMP_CONTAINER: 'CLEAR_UPLOADS_TEMP_CONTAINER',
  uploadFile: (file, file_container_id = null, callback) => ({
    type: actions.UPLOAD_FILE,
    file,
    file_container_id,
    callback
  }),
  uploadFileSuccess: (response) => ({
    type: actions.UPLOAD_FILE_SUCCESS,
    response
  }),
  uploadFileError: (error) => ({
    type: actions.UPLOAD_FILE_ERROR,
    error
  }),
  /**
   * This action is used fo updating the array of attachments of a new message
   * @param {Object} file - the file that has to be uploaded to the backend
   * @param {String} attachmentId - the id of the attachment, used for mapping the blob received from the backend to the right attachemnt
   * @param {String} attachmentType - the type of the attachment, used for mapping the blob received from the backend to the right attachemnt
   */
  uploadFileRequestForMessageAttachments: (file, attachmentId, attachmentType) => ({
    type: actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS,
    file,
    attachmentId,
    attachmentType
  }),
  uploadFileRequestForMessageAttachmentsSuccess: () => ({
    type: actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_SUCCESS
  }),
  uploadFileRequestForMessageAttachmentsError: () => ({
    type: actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS_ERROR
  }),
  setUploadsTempContainer: (uploads) => ({
    type: actions.SET_UPLOADS_TEMP_CONTAINER,
    uploads
  }),
  clearUploadsTempContainer: () => ({
    type: actions.CLEAR_UPLOADS_TEMP_CONTAINER
  })
};

export default actions;
