import { useState } from 'react';
import queryString from 'query-string';
import { store } from '../redux/store';
import axios from '../config/axios';

const getUrl = (searchValue, params, pathname) => {
  let query = '';
  if (params) {
    const filterParams = queryString.stringify(params);
    query = `?${filterParams}&search=${searchValue}`;
  } else {
    query = `?search=${searchValue}`;
  }
  return `api/v1${pathname}${query}`;
};

export function useLatestApiCallOnSearch() {
  const [cancelationToken, setCancelationToken] = useState(null);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const handleSearchChange = (searchValue, params, pathname, successAction, errorAction) => {
    try {
      if (cancelationToken && cancelationToken.cancel) {
        cancelationToken.cancel();
      }
      // if the search value is empty or just whitespace, we don't make the api call
      if (!searchValue || searchValue?.trim().length === 0) {
        setIsSearchLoading(false);
        return;
      }
      setIsSearchLoading(true);
      const url = getUrl(searchValue, params, pathname);
      makeApiCall(url, successAction, errorAction);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const makeApiCall = async (url, successAction, errorAction) => {
    const source = axios?.CancelToken?.source();
    setCancelationToken(source);
    try {
      const response = await axios.get(url, { cancelToken: source.token });
      const responseData = response.data || [];
      if (response.status) {
        if (response.status === 200) {
          store.dispatch(successAction(responseData));
        } else {
          store.dispatch(errorAction());
        }
        setIsSearchLoading(false);
      }
    } catch (error) {
      store.dispatch(errorAction());
      setIsSearchLoading(false);
    }
  };

  return [isSearchLoading, handleSearchChange];
}
