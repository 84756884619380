import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import appReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    setImmediate(() => {
      throw error;
    });
  }
});

const middlewares = [sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: () => {
    // return empty state
    const emptyState = {};
    return emptyState;
  }
});

const rootReducer = (state, action) => appReducer(state, action);

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer));

sagaMiddleware.run(rootSaga);

export { store };
