import React from 'react';

const EyecandyEmptySearch = () => {
  return (
    <svg width="70" height="60" viewBox="0 0 70 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.41 34.47c.29.3.76.31 1.06.03 1.17-1.11 3.27-1.79 5.48-1.79 2.21 0 4.32.69 5.48 1.79a.75.75 0 0 0 1.06-.02c.29-.3.27-.78-.03-1.06-1.46-1.38-3.89-2.21-6.52-2.21-2.63 0-5.06.83-6.52 2.21-.3.29-.31.76-.03 1.06l.02-.01zM21.95 27.7h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-4c-.41 0-.75.34-.75.75s.34.75.75.75zM37.95 27.7h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-4c-.41 0-.75.34-.75.75s.34.75.75.75z"
        fill="#B3B3B3"
      />
      <path
        d="M65.55 52.39 49.88 37.96c3.12-3.99 4.83-8.93 4.83-14.02C54.7 11.41 44.49 1.2 31.95 1.2c-5.99 0-11.76 2.39-16 6.58-.96-1.79-1.82-3.16-1.86-3.23-.27-.44-1-.44-1.27 0-.47.76-4.61 7.5-4.61 10.4 0 1.53.55 2.99 1.54 4.07-.36 1.61-.54 3.27-.54 4.93 0 12.54 10.21 22.75 22.75 22.75 5.06 0 10-1.7 13.98-4.8l14.44 15.69c.51.56 1.22.87 1.97.89h.05c.74 0 1.43-.29 1.95-.82l1.29-1.3c.53-.54.81-1.25.79-2-.02-.75-.33-1.45-.89-1.96l.01-.01zM9.7 14.95c0-1.79 2.31-6.1 3.75-8.54.44.75 1.05 1.82 1.64 2.97 0 0 .01.02.02.03.82 1.57 1.4 2.9 1.75 3.94v.02c.28.84.34 1.33.34 1.59 0 1.98-1.24 3.73-2.94 4.15h-.03c-1.11.27-2.28-.05-3.15-.86l-.02-.02c-.87-.81-1.36-2-1.36-3.27v-.01zm8.71-1.68C21.7 9.09 26.62 6.7 31.95 6.7c9.51 0 17.25 7.74 17.25 17.25S41.46 41.2 31.95 41.2 14.7 33.46 14.7 23.95c0-1.19.12-2.37.36-3.53 2.13-.76 3.64-2.98 3.64-5.47 0-.46-.1-1.02-.29-1.68zM45.54 40.3a21.27 21.27 0 0 1-13.58 4.9c-11.72 0-21.25-9.53-21.25-21.25 0-1.31.12-2.61.35-3.88.75.42 1.57.6 2.43.63-.19 1.07-.28 2.16-.28 3.25 0 10.34 8.41 18.75 18.75 18.75s18.75-8.41 18.75-18.75S42.29 5.2 31.95 5.2c-5.49 0-10.57 2.33-14.13 6.43-.3-.73-.68-1.55-1.14-2.45C20.67 5.05 26.2 2.7 31.95 2.7c11.72 0 21.25 9.53 21.25 21.25a21.269 21.269 0 0 1-7.66 16.34v.01zm19.04 15-1.29 1.3c-.24.25-.58.36-.91.37-.34 0-.66-.15-.9-.4L47.09 40.94c.64-.57 1.25-1.18 1.82-1.82L64.53 53.5c.25.23.39.55.4.89 0 .34-.12.67-.36.91h.01z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default EyecandyEmptySearch;
