import Calendar from 'react-calendar';
import styled from 'styled-components';

const CalendarStyled = styled(Calendar)`
  &.worklist-calendar {
    background-color: var(--greyscale-white);
    border: 1px solid var(--greyscale-concrete);
    border-radius: var(--border-radius-regular);
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 100%;
    min-height: 310px;
    padding: 0;

    &:hover {
      border-color: var(--primary-oqio-blue);
    }

    &:focus {
      border: 1px solid var(--primary-oqio-blue);
      box-shadow: var(--box-shadow-oqio-blue);
    }

    .react-calendar {
      &__navigation {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 15px 20px 20px;
      }

      &__navigation__label {
        background-color: var(--greyscale-white);
        border: none;
        display: flex;
        font-size: var(--font-size-heading-3);
        font-weight: var(--font-weight-medium);
        height: 26px;
        line-height: var(--font-line-height-medium);
        order: -1;
      }

      &__navigation__arrow {
        align-items: center;
        background-color: var(--greyscale-white);
        border: 0;
        display: flex;
        height: 26px;
        justify-content: center;
        padding: 0;
        width: 20px;

        svg {
          height: 26px;
          width: 20px;

          path {
            stroke: var(--primary-oqio-blue);
          }
        }

        &:disabled {
          svg {
            path {
              stroke: var(--greyscale-concrete);
            }
          }
        }
      }

      &__viewContainer {
        flex: 1 0 100%;
        display: flex;
      }

      &__month-view {
        display: flex;
        flex-direction: column;
        flex: 1 0 100%;

        > div {
          display: flex;
          flex: 1 0 100%;
          height: 100%;

          > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }

      &__month-view__weekdays {
        display: flex;
        height: 16px;
        margin-bottom: 10px;
      }

      &__month-view__days {
        display: grid !important;
        grid-template-columns: repeat(7, auto);
        justify-content: space-around;
        margin-bottom: 15px;
        row-gap: 5px;
        height: 100%;
      }

      &__month-view__weekdays__weekday {
        align-items: center;
        color: var(--greyscale-concrete);
        display: flex;
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-semi-bold);
        justify-content: center;
        line-height: var(--font-line-height-small);
        text-transform: uppercase;

        abbr {
          cursor: default;
          text-decoration: none;
        }
      }

      &__tile {
        align-items: center;
        background-color: var(--greyscale-white);
        border: none;
        border-radius: var(--border-radius-circle);
        color: var(--greyscale-blackish);
        display: flex;
        height: 30px;
        justify-content: center;
        margin: auto;
        padding: 0;
        transition: all 0.1s ease-in-out;
        width: 30px;

        &[disabled] {
          color: var(--greyscale-concrete);
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &:hover:not(.react-calendar__tile--active):not([disabled]) {
          background-color: var(--greyscale-dark-white);
          color: var(--greyscale-blackish);
          transition: all 0.1s ease-in-out;
        }

        abbr {
          align-items: center;
          display: flex;
          font-size: var(--font-size-regular);
          height: 20px;
          justify-content: center;
          line-height: var(--font-line-height-regular);
          width: 20px;
        }
      }

      &__month-view__days__day--neighboringMonth {
        color: var(--greyscale-concrete);
      }

      &__tile--now {
        background-color: var(--greyscale-dark-white);
        color: var(--greyscale-blackish) !important;
      }

      &__tile--active {
        background-color: var(--primary-oqio-blue);
        border-radius: var(--border-radius-circle);
        color: var(--greyscale-white) !important;
        font-weight: var(--font-weight-medium);
      }
    }
  }
`;

export default CalendarStyled;
