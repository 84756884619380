import React from 'react';
import styled from 'styled-components';
import * as AntDesign from 'antd';

const OVERLAY_INNER_STYLE = {
  borderRadius: '6px'
};

const PopUpContainer = styled.div`
  .ant-popover-inner-content {
    padding: 0;
  }
`;

const AntPopover = AntDesign.Popover;

const Popover = ({ arrow, children, content, placement, overlayInnerStyle, trigger }) => {
  return (
    <PopUpContainer>
      <AntPopover
        content={content}
        destroyPopupOnHide
        placement={placement || ''}
        overlayInnerStyle={overlayInnerStyle || OVERLAY_INNER_STYLE}
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={trigger || 'click'}
        arrow={arrow}
      >
        {children}
      </AntPopover>
    </PopUpContainer>
  );
};

export default Popover;
