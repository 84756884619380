import React from 'react';
import styled from 'styled-components';
import AntDragger from 'antd/es/upload/Dragger';

const DropzoneStyles = styled(AntDragger)`
  &.ant-upload {
    &.ant-upload-drag {
      background: var(--greyscale-porcelain);
      border: dashed 2px var(--primary-oqio-blue);
      height: 165px;
      width: 530px;

      :hover {
        border: dashed 2px var(--primary-oqio-blue-hover);
      }
    }
  }
`;

const Dropzone = ({ draggerConfig, children }) => <DropzoneStyles {...draggerConfig}>{children}</DropzoneStyles>;

export default Dropzone;
