import styled from 'styled-components';
import { windowMaxWidth } from '../../components/ui/Layout/breakpoints';

const SimpleLayoutStyleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-regular);
  justify-content: flex-start;
  min-height: 100vh;

  @supports (min-height: 100lvh;) {
    min-height: 100lvh;
  }

  position: relative;
  width: 100%;

  .logo {
    display: flex;
    margin: 30px 0;
    width: 500px;
  }

  .terms-menu {
    position: absolute;
    bottom: 20px;
    text-align: center;
    z-index: 5;

    @media ${windowMaxWidth.mobile} {
      display: flex;
      gap: 5px;
      flex-direction: column;
    }
  }

  .oqio-link {
    color: var(--greyscale-white);
    cursor: default;
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);

    &:focus {
      outline: none;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    @media ${windowMaxWidth.mobile} {
      font-size: var(--font-size-small);

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .valid-link {
    cursor: pointer;

    &:hover {
      color: var(--greyscale-white);
    }
  }
`;

export default SimpleLayoutStyleWrapper;
