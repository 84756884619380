import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import {
  onGetWorklistClaims,
  onGetWorklistClaimMessages,
  onCreateWorklistClaimMessage,
  onGetActiveMessagesCount,
  onGetClaimActiveMessagesCount,
  onUpdateWorklistClaimMessage,
  onGetMessageRecipientOrganizations
} from './apiCalls';

export function* getWorklistClaimsSaga({ params }) {
  try {
    const response = yield call(onGetWorklistClaims, params);
    if (response.status === 200) {
      yield put(actions.getWorklistClaimsSuccess(response.data));
    } else {
      yield put(actions.getWorklistClaimsError());
    }
  } catch (error) {
    yield put(actions.getWorklistClaimsError());
  }
}

export function* getWorklistClaimMessagesSaga({ claimId, params }) {
  try {
    let _params = params;
    if (!_params || !_params.filter) {
      const worklistClaimMessagesFilter = yield select(getWorklistClaimMessagesFilter);
      _params = { filter: worklistClaimMessagesFilter };
    }
    const response = yield call(onGetWorklistClaimMessages, claimId, _params);

    if (response.status === 200) {
      yield put(actions.getWorklistClaimMessagesSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getWorklistClaimMessagesError());
  }
}

export function* getWorklistMessagesOnFilterChangeSaga({ filter }) {
  try {
    const claim = yield select(getCurrentClaim);
    const claimId = claim.id;
    const _params = { filter };
    yield all([
      put(actions.getWorklistClaimMessages(claimId, _params)),
      put(actions.getClaimActiveMessagesCount(claimId))
    ]);
  } catch (error) {
    yield put(actions.getWorklistClaimMessagesError());
  }
}

export function* getWorklistClaimMessagesSuccessSaga({ messages }) {
  const activeMessages = messages.filter((message) => message.is_active);
  yield put(actions.setWorklistHeaderActiveMessagesCount(activeMessages.length));
}

export function* getMessageRecipientOrganizationsSaga({ claimId }) {
  try {
    const response = yield call(onGetMessageRecipientOrganizations, claimId);
    if (response.status === 200) {
      yield put(actions.getMessageRecipientOrganizationsSuccess(response.data));
    } else {
      yield put(actions.getMessageRecipientOrganizationsError());
    }
  } catch (error) {
    yield put(actions.getMessageRecipientOrganizationsError());
  }
}

export function* getActiveMessagesCountSaga({ params }) {
  try {
    const response = yield call(onGetActiveMessagesCount, params);
    if (response.data) {
      yield put(actions.getActiveMessagesCountSuccess(response.data.count));
    } else {
      yield put(actions.getActiveMessagesCountError(response.data));
    }
  } catch (error) {
    yield put(actions.getActiveMessagesCountError(error));
  }
}

export function* getClaimActiveMessagesCountSaga({ claimId }) {
  try {
    const response = yield call(onGetClaimActiveMessagesCount, claimId);
    if (response.data) {
      yield put(actions.getClaimActiveMessagesCountSuccess(response.data.count));
    } else {
      yield put(actions.getClaimActiveMessagesCountError(response.data));
    }
  } catch (error) {
    yield put(actions.getClaimActiveMessagesCountError(error));
  }
}

export function* createWorklistClaimMessageSaga({ claimId, messageData, callback }) {
  try {
    const response = yield call(onCreateWorklistClaimMessage, claimId, messageData);
    if (response.status === 200) {
      yield put(actions.createWorklistClaimMessageSuccess());
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.createWorklistClaimMessageError());
    }
  } catch (error) {
    yield put(actions.createWorklistClaimMessageError());
  }
}

export function* updateWorklistClaimMessageSaga({ claimId, message }) {
  try {
    const response = yield call(onUpdateWorklistClaimMessage, claimId, message);
    if (response.status === 200) {
      yield put(actions.updateWorklistClaimMessageSuccess());
    } else {
      yield put(actions.updateWorklistClaimMessageError());
    }
  } catch (error) {
    yield put(actions.updateWorklistClaimMessageError());
  }
}

const getCurrentClaim = (state) => state.Claims.get('claim');
const getWorklistClaimMessagesFilter = (state) => state.WorklistClaims.get('worklistClaimMessagesFilter');

function* reloadWorklistClaimMessages() {
  const claim = yield select(getCurrentClaim);
  const worklistClaimMessagesFilter = yield select(getWorklistClaimMessagesFilter);
  yield all([
    put(actions.getWorklistClaimMessages(claim?.id, { filter: worklistClaimMessagesFilter })),
    put(actions.getClaimActiveMessagesCount(claim?.id))
  ]);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT, getActiveMessagesCountSaga),
    takeEvery(actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT, getClaimActiveMessagesCountSaga),
    takeEvery(actions.GET_WORKLIST_CLAIMS, getWorklistClaimsSaga),
    takeEvery(actions.GET_WORKLIST_CLAIM_MESSAGES, getWorklistClaimMessagesSaga),
    takeEvery(actions.GET_WORKLIST_CLAIM_MESSAGES_SUCCESS, getWorklistClaimMessagesSuccessSaga),
    takeEvery(actions.GET_MESSAGE_RECIPIENT_ORGANIZATIONS, getMessageRecipientOrganizationsSaga),
    takeEvery(actions.CREATE_WORKLIST_CLAIM_MESSAGE, createWorklistClaimMessageSaga),
    takeEvery(actions.CREATE_WORKLIST_CLAIM_MESSAGE_SUCCESS, reloadWorklistClaimMessages),
    takeEvery(actions.UPDATE_WORKLIST_CLAIM_MESSAGE, updateWorklistClaimMessageSaga),
    takeEvery(actions.UPDATE_WORKLIST_CLAIM_MESSAGE_SUCCESS, reloadWorklistClaimMessages),
    takeEvery(actions.SET_WORKLIST_CLAIM_MESSAGES_FILTER, getWorklistMessagesOnFilterChangeSaga)
  ]);
}
