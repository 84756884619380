import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  importFile: null,
  importJsonData: null,
  getImportTemplateLoading: false,
  getImportTemplateError: false,
  checkImportFileLoading: false,
  checkImportFileError: false,
  executeImportLoading: false,
  executeImportError: false,
  importTemplateHeaders: null,
  importTemplateHeadersLoading: false,
  importTemplateHeadersError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.UPLOAD_IMPORT_FILE:
      return state.set('importFile', action.payload);
    case actions.CLEAR_IMPORT_FILE:
      return state.set('importFile', null);
    case actions.GET_IMPORT_TEMPLATE:
      return state.set('getImportTemplateLoading', true).set('getImportTemplateError', false);
    case actions.GET_IMPORT_TEMPLATE_SUCCESS:
      return state.set('getImportTemplateLoading', false).set('getImportTemplateError', false);
    case actions.GET_IMPORT_TEMPLATE_ERROR:
      return state.set('getImportTemplateLoading', false).set('getImportTemplateError', true);
    case actions.EXECUTE_IMPORT:
      return state.set('executeImportLoading', true).set('executeImportError', false);
    case actions.EXECUTE_IMPORT_SUCCESS:
      return state.set('executeImportLoading', false).set('executeImportError', false);
    case actions.EXECUTE_IMPORT_ERROR:
      return state.set('executeImportLoading', false).set('executeImportError', true);
    case actions.CHECK_IMPORT_FILE:
      return state.set('importJsonData', null).set('checkImportFileLoading', true).set('checkImportFileError', false);
    case actions.CHECK_IMPORT_FILE_SUCCESS:
      return state
        .set('importJsonData', action.payload)
        .set('checkImportFileLoading', false)
        .set('checkImportFileError', false);
    case actions.CHECK_IMPORT_FILE_ERROR:
      return state.set('checkImportFileLoading', false).set('checkImportFileError', true);
    case actions.GET_IMPORT_TEMPLATE_HEADERS:
      return state.set('importTemplateHeadersLoading', true).set('importTemplateHeadersError', false);
    case actions.GET_IMPORT_TEMPLATE_HEADERS_SUCCESS:
      return state
        .set('importTemplateHeadersLoading', false)
        .set('importTemplateHeadersError', false)
        .set('importTemplateHeaders', action.payload);
    case actions.GET_IMPORT_TEMPLATE_HEADERS_ERROR:
      return state.set('importTemplateHeadersLoading', false).set('importTemplateHeadersError', true);
    default:
      return state;
  }
};

export default reducer;
