import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  allPolicies: [],
  allPoliciesLoading: false,
  allPoliciesError: false,
  policy: null,
  policyLoading: false,
  policyOrganizationsWithAccess: [],
  policyOrganizationsWithAccessLoading: false,
  policyOrganizationsWithAccessError: false,
  policyAuth: [],
  policyAuthLoading: false,
  policyAuthError: false,
  createPolicyLoading: false,
  createPolicyError: false,
  deletePoliciesLoading: false,
  deletePoliciesError: false,
  policies: [],
  policiesLoading: false,
  policiesError: false,
  policiesPerPage: 25,
  totalPolicies: 0,
  updatePolicyLoading: false,
  updatePolicyError: false,
  customFields: [],
  customValuesLoading: false,
  customValuesError: false,
  downloadImportTemplateLoading: false,
  downloadImportTemplateError: false,
  importPoliciesFileLoading: false,
  importPoliciesFileError: false,
  importPoliciesFileData: {
    import_only_policies: [],
    database_only_policies: [],
    matched_changed_policies: [],
    matched_unchanged_policies: []
  },
  importPoliciesLoading: false,
  importPoliciesError: false,
  importCreatePoliciesLoading: false,
  importCreatePoliciesError: false,
  importUpdatePoliciesLoading: false,
  importUpdatePoliciesError: false,
  exportPoliciesLoading: false,
  exportPoliciesError: false,
  updatePolicyInsuredRisksLoading: false,
  updatePolicyInsuredRisksErrors: false,
  connectedRisks: [],
  getConnectedRisksLoading: false,
  getConnectedRisksError: false,
  lastVisitedPolicyId: null,
  policiesInsuredRiskTypes: [],
  policiesInsuredRiskTypesLoading: false,
  policiesInsuredRiskTypesError: false,
  policiesInsuredRisks: [],
  policiesInsuredRisksLoading: false,
  policiesInsuredRisksError: false,
  policiesFiltersOrganizationsWithAccess: [],
  policiesFiltersOrganizationsWithAccessLoading: false,
  policiesFiltersOrganizationsWithAccessError: false,
  insuranceOrganizationLogos: {}
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CREATE_POLICY:
      return state.set('createPolicyLoading', true).set('createPolicyError', false);
    case actions.CREATE_POLICY_SUCCESS:
      return state.set('createPolicyLoading', false).set('createPolicyError', false);
    case actions.CREATE_POLICY_ERROR:
      return state.set('createPolicyLoading', false).set('createPolicyError', true);
    case actions.DELETE_POLICIES:
      return state.set('deletePoliciesLoading', true).set('deletePoliciesError', false);
    case actions.DELETE_POLICIES_SUCCESS:
      return state.set('deletePoliciesLoading', false).set('deletePoliciesError', false);
    case actions.DELETE_POLICIES_ERROR:
      return state.set('deletePoliciesLoading', false).set('deletePoliciesError', true);
    case actions.LOAD_POLICIES_WITHOUT_PAGINATION:
      return state.set('policyLoading', true).set('policyError', false).set('allPoliciesLoading', true);
    case actions.LOAD_POLICIES_WITHOUT_PAGINATION_SUCCESS:
      return state
        .set('allPolicies', action.allPolicies)
        .set('allPoliciesLoading', false)
        .set('allPoliciesError', false);
    case actions.LOAD_POLICIES_WITHOUT_PAGINATION_ERROR:
      return state.set('allPoliciesLoading', false).set('allPoliciesError', true);
    case actions.LOAD_POLICY:
      return state.set('policyLoading', true).set('policyError', false);
    case actions.LOAD_POLICY_SUCCESS:
      return state.set('policy', action.policy).set('policyLoading', false).set('policyError', false);
    case actions.LOAD_POLICY_ERROR:
      return state.set('policyLoading', false).set('policyError', true);
    case actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS:
      return state.set('policyOrganizationsWithAccessLoading', true).set('policyOrganizationsWithAccessError', false);
    case actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS_SUCCESS:
      return state
        .set('policyOrganizationsWithAccess', action.organizations)
        .set('policyOrganizationsWithAccessLoading', false)
        .set('policyOrganizationsWithAccessError', false);
    case actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS_ERROR:
      return state.set('policyOrganizationsWithAccessLoading', false).set('policyOrganizationsWithAccessError', true);
    case actions.LOAD_POLICIES:
      return state.set('policiesLoading', true).set('policiesError', false);
    case actions.LOAD_POLICIES_SUCCESS:
      return state
        .set('policies', action.policies)
        .set('policiesPerPage', parseInt(action.meta.perPage, 10))
        .set('totalPolicies', parseInt(action.meta.total, 10))
        .set('policiesLoading', false)
        .set('policiesError', false);
    case actions.LOAD_POLICIES_ERROR:
      return state.set('policiesLoading', false).set('policiesError', true);
    case actions.LOAD_POLICY_AUTH:
      return state.set('policyAuthLoading', true).set('policyAuthError', false);
    case actions.LOAD_POLICY_AUTH_SUCCESS:
      return state.set('policyAuth', action.auth).set('policyAuthLoading', false).set('policyAuthError', false);
    case actions.LOAD_POLICY_AUTH_ERROR:
      return state.set('policyAuthLoading', false).set('policyAuthError', true);
    case actions.UPDATE_POLICY:
      return state.set('updatePolicyLoading', true).set('updatePolicyError', false);
    case actions.UPDATE_POLICY_SUCCESS:
      return state.set('updatePolicyLoading', false).set('updatePolicyError', false);
    case actions.UPDATE_POLICY_ERROR:
      return state.set('updatePolicyLoading', false).set('updatePolicyError', true);
    case actions.GET_CUSTOM_VALUES:
      return state.set('customValuesLoading', true).set('customValuesError', false);
    case actions.GET_CUSTOM_VALUES_SUCCESS:
      return state
        .set('customFields', action.customValues)
        .set('customValuesLoading', false)
        .set('customValuesError', false);
    case actions.GET_CUSTOM_VALUES_ERROR:
      return state.set('customValuesLoading', false).set('customValuesError', true);
    case actions.CLEAR_POLICY_ERRORS:
      return state.set('createPolicyError', false).set('updatePolicyError', false);
    case actions.DOWNLOAD_IMPORT_TEMPLATE:
      return state.set('downloadImportTemplateLoading', true);
    case actions.DOWNLOAD_IMPORT_TEMPLATE_SUCCESS:
      return state.set('downloadImportTemplateLoading', false);
    case actions.DOWNLOAD_IMPORT_TEMPLATE_ERROR:
      return state.set('downloadImportTemplateLoading', false).set('downloadImportTemplateError', true);
    case actions.IMPORT_POLICIES_FILE:
      return state.set('importPoliciesFileLoading', true).set('importPoliciesFileError', false);
    case actions.IMPORT_POLICIES_FILE_SUCCESS:
      return state.set('importPoliciesFileLoading', false).set('importPoliciesFileData', action.data);
    case actions.IMPORT_POLICIES_FILE_ERROR:
      return state.set('importPoliciesFileLoading', false).set('importPoliciesFileError', true);
    case actions.IMPORT_POLICIES:
      return state
        .set('importPoliciesLoading', true)
        .set('importCreatePoliciesLoading', true)
        .set('importUpdatePoliciesLoading', true);
    case actions.IMPORT_POLICIES_SUCCESS:
      return state.set('importPoliciesLoading', false).set('importPoliciesError', false);
    case actions.IMPORT_CREATE_POLICIES_SUCCESS:
      return state.set('importCreatePoliciesLoading', false).set('importCreatePoliciesError', false);
    case actions.IMPORT_UPDATE_POLICIES_SUCCESS:
      return state.set('importUpdatePoliciesLoading', false).set('importUpdatePoliciesError', false);
    case actions.IMPORT_CREATE_POLICIES_ERROR:
      return state.set('importCreatePoliciesLoading', false).set('importCreatePoliciesError', true);
    case actions.IMPORT_UPDATE_POLICIES_ERROR:
      return state.set('importUpdatePoliciesLoading', false).set('importUpdatePoliciesError', true);
    case actions.EXPORT_POLICIES:
      return state.set('exportPoliciesLoading', true);
    case actions.EXPORT_POLICIES_SUCCESS:
      return state.set('exportPoliciesLoading', false);
    case actions.EXPORT_POLICIES_ERROR:
      return state.set('exportPoliciesLoading', false).set('exportPoliciesError', true);
    case actions.IMPORT_POLICIES_ERROR:
      return state.set('importPoliciesLoading', false).set('importPoliciesError', true);
    case actions.CLEAR_IMPORT_POLICIES_ERRORS:
      return state
        .set('importPoliciesError', false)
        .set('importCreatePoliciesError', false)
        .set('importUpdatePoliciesError', false);
    case actions.CLEAR_IMPORT_POLICIES_FILE_ERRORS:
      return state.set('importPoliciesFileError', false);
    case actions.BULK_UNASSIGN_INSURED_RISKS:
      return state.set('updatePolicyInsuredRisksLoading', true);
    case actions.BULK_UNASSIGN_INSURED_RISKS_SUCCESS:
      return state.set('updatePolicyInsuredRisksLoading', false);
    case actions.BULK_UNASSIGN_INSURED_RISKS_ERROR:
      return state.set('updatePolicyInsuredRisksLoading', false).set('updatePolicyInsuredRisksError', true);
    case actions.BULK_ASSIGN_INSURED_RISKS:
      return state.set('updatePolicyInsuredRisksLoading', true);
    case actions.BULK_ASSIGN_INSURED_RISKS_SUCCESS:
      return state.set('updatePolicyInsuredRisksLoading', false);
    case actions.BULK_ASSIGN_INSURED_RISKS_ERROR:
      return state.set('updatePolicyInsuredRisksLoading', false).set('updatePolicyInsuredRisksError', true);
    case actions.RESET_POLICIES:
      return state.set('policies', []);
    case actions.RESET_ALL_POLICIES:
      return state.set('allPolicies', []);
    case actions.CLEAR_SINGLE_POLICY:
      return state.set('policy', null);
    case actions.GET_CONNECTED_RISKS:
      return state.set('getConnectedRisksLoading', true).set('getConnectedRisksError', false);
    case actions.GET_CONNECTED_BUILLDINGS_SUCCESS:
      return state.set('getConnectedRisksLoading', false).set('connectedRisks', action.payload);
    case actions.GET_CONNECTED_BUILLDINGS_ERROR:
      return state.set('getConnectedRisksLoading', false).set('getConnectedRisksError', true);
    case actions.GET_POLICIES_INSURED_RISK_TYPES:
      return state.set('policiesInsuredRiskTypesLoading', true).set('policiesInsuredRiskTypesError', false);
    case actions.GET_POLICIES_INSURED_RISK_TYPES_SUCCESS:
      return state.set('policiesInsuredRiskTypesLoading', false).set('policiesInsuredRiskTypes', action.payload);
    case actions.GET_POLICIES_INSURED_RISK_TYPES_ERROR:
      return state.set('policiesInsuredRiskTypesLoading', false).set('policiesInsuredRiskTypesError', true);
    case actions.GET_POLICIES_INSURED_RISKS:
      return state.set('policiesInsuredRisksLoading', true).set('policiesInsuredRisksError', false);
    case actions.GET_POLICIES_INSURED_RISKS_SUCCESS:
      return state.set('policiesInsuredRisksLoading', false).set('policiesInsuredRisks', action.payload);
    case actions.GET_POLICIES_INSURED_RISKS_ERROR:
      return state.set('policiesInsuredRisksLoading', false).set('policiesInsuredRisksError', true);
    case actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS:
      return state
        .set('policiesFiltersOrganizationsWithAccessLoading', true)
        .set('policiesFiltersOrganizationsWithAccessError', false);
    case actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS:
      return state
        .set('policiesFiltersOrganizationsWithAccessLoading', false)
        .set('policiesFiltersOrganizationsWithAccess', action.payload);
    case actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR:
      return state
        .set('policiesFiltersOrganizationsWithAccessLoading', false)
        .set('policiesFiltersOrganizationsWithAccessError', true);
    case actions.SPREAD_SINGLE_POLICY_INTO_POLICIES:
      return state.set('policies', [state.get('policy')]);
    case actions.SET_LAST_VISITED_POLICY_ID:
      return state.set('lastVisitedPolicyId', action.policyId);
    case actions.RESET_LAST_VISITED_POLICY_ID:
      return state.set('lastVisitedPolicyId', null);
    case actions.SET_INSURANCE_ORGANIZATION_LOGOS:
      return state.set('insuranceOrganizationLogos', action.organizationLogos);
    default:
      return state;
  }
};

export default reducer;
