import React from 'react';

const IconLink = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.018 10.982a2.977 2.977 0 0 1 0-4.21l2.666-2.667a2.977 2.977 0 0 1 4.21 4.21L14.07 10.14"
      stroke="#111"
      strokeWidth="1.5"
    />
    <path
      d="M10.982 9.018a2.977 2.977 0 0 1 0 4.21l-2.666 2.667a2.977 2.977 0 0 1-4.21-4.21L5.93 9.86"
      stroke="#111"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconLink;
