import { Map } from 'immutable';
import actions from './actions';

const INITIAL_STATE = new Map({
  config: null,
  configLoading: false,
  configError: false
});

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.GET_CONFIG:
      return state.set('configLoading', true).set('configError', false);
    case actions.GET_CONFIG_SUCCESS:
      return state.set('config', action.payload).set('configLoading', false).set('configError', false);
    case actions.GET_CONFIG_ERROR:
      return state.set('configLoading', false).set('configError', true);
    case actions.UPDATE_CONFIG:
      return state.set('configLoading', true).set('configError', false);
    case actions.UPDATE_CONFIG_SUCCESS:
      return state.set('config', action.payload).set('configLoading', false).set('configError', false);
    case actions.UPDATE_CONFIG_ERROR:
      return state.set('configLoading', false).set('configError', true);
    default:
      return state;
  }
};

export default authReducer;
