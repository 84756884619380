import React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { useFetchUserIntercomHmac } from '../../hooks';
import { findMetaDataDescriptionByProperty } from '../../helpers/utility';

const INTERCOM_APP_ID = 'qixggyg1';

/**
 * Functional component that sets up the Intercom provider.
 * Includes user information and Intercom-specific settings for initializing the chat widget.
 * @property {Object} user - the current logged-in user information.
 * @property {React.ReactNode} children - the child components to be wrapped by the Intercom provider.
 */
const IntercomContainer = ({ children, user }) => {
  const { email, first_name, last_name, id, created_at, organization_id } = user;
  const name = `${first_name} ${last_name}`;
  const createdAt = new Date(created_at).getTime();

  const userOrganizationInfo = findMetaDataDescriptionByProperty(user, 'organization_name', '', 'description');
  const company = userOrganizationInfo ? { name: userOrganizationInfo?.description, companyId: organization_id } : null;

  const [userHmac, fetchUserIntercomHmac] = useFetchUserIntercomHmac();
  const userHash = userHmac || '';
  if (!userHmac) {
    fetchUserIntercomHmac(id);
  }

  const bootProps = {
    appId: INTERCOM_APP_ID,
    name,
    email,
    userId: id,
    userHash,
    createdAt,
    company,
    alignment: 'left',
    verticalPadding: 20,
    horizontalPadding: 20,
    hideDefaultLauncher: false
  };

  return (
    <>
      {userHmac && (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot autoBootProps={bootProps}>
          {children}
        </IntercomProvider>
      )}
    </>
  );
};

export default IntercomContainer;
